import { firebase } from './index';

export default () => (
  firebase.auth.currentUser.getIdToken(true).then(tokenId => ({
    tokenId,
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  }))
);
