/* eslint no-console: ["error", { allow: ["log"] }] */
import * as Promise from 'bluebird';
import axios from 'axios';
import { firebase } from 'lib/Firebase';
import moment from 'moment';
import { SET_ALL_INFLUENCERS_IN_BRAND } from 'reducers/ui/applicationsAction';
import { POST_STATUS } from 'constants/posts';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import {
  DATE_TIMEZONE_FORMAT,
  MESSAGE_TYPE,
  SUPPORT_EMAIL,
  MASS_FINAL_REJECT,
} from 'constants/common';
import { BRAND_PRODUCT_STATUS } from 'constants/brands';
import { updateBrandDetails, receiveBrand } from 'actions/brands';
import { updateInfluencer } from 'actions/influencers';
import { removeInfluencerInList } from 'actions/ui/influencerList';
import { updateCampaignAppliedUID } from 'actions/campaigns';
import { fetchCampaignNavLinkStatusCounts } from 'actions/ui/campaignDashboard';
import Routes from 'api/routes';
import { sendNewMessage } from './messages';

export const setAllInfluencersInBrand = influencerUIDs => ({
  type: SET_ALL_INFLUENCERS_IN_BRAND,
  influencerUIDs,
});

export const checkLastReloadDate = (brandUID, callback) => (dispatch) => {
  firebase.brand(brandUID).once('value', (snapshot) => {
    const brand = snapshot.val();

    if (!brand) {
      // TODO:
      //  Its better to throw an error(?)
      //  when no data retrieve from firebase
      callback(false, null, {
        type: 'error',
        title: 'Somethings Wrong!',
        content: `Didn't receive brand data! Contact ${SUPPORT_EMAIL} for support.`,
      });

      return;
    }

    dispatch(receiveBrand({ [brandUID]: brand }));

    const lastReloadDate = brand.details.lastReloadDate
      ? moment(new Date(brand.details.lastReloadDate), DATE_TIMEZONE_FORMAT)
      : moment();

    if (lastReloadDate < moment()) {
      callback(true, brand);
    } else {
      callback(false, null, {
        type: 'info',
        title: 'You\'re Early...',
        content: `Your subscription doesn't begin in until ${lastReloadDate.format('MMMM DD, YYYY')}. Contact ${SUPPORT_EMAIL} with questions.`,
      });
    }
  });
};

export const congratulationMsg = (campaignName, brandName) => (
  `Congratulations! You have been chosen to receive ${campaignName} for ${brandName}`
  + ' We\'re excited to work with you and can\'t wait to see what you create. Please remember to'
  + ' follow the project guidelines and reach out with any questions.'
);

const sendApproveMessage = (
  brandUID,
  brandDetails,
  influencerUID,
  campaignUID,
  campaign,
  influencer,
) => (dispatch) => {
  const text = congratulationMsg(campaign.name, brandDetails.name);

  const sender = {
    uid: brandUID,
    name: brandDetails.name,
    type: MESSAGE_TYPE.brand,
  };

  const recipient = [{
    uid: influencerUID,
    name: influencer.username,
    type: MESSAGE_TYPE.influencer,
  }];

  const partnership = {
    uid: campaignUID,
    name: campaign.name,
    type: MESSAGE_TYPE.product,
  };

  dispatch(sendNewMessage(
    text,
    sender,
    recipient,
    partnership,
    true,
  ));
};

export const approveInfluencers = ({
  influencerUIDs,
  influencers,
  campaignUID,
  campaign,
  brandUID,
}, callback = null) => (dispatch) => {
  firebase.auth.currentUser.getIdToken(true).then((idToken) => {
    const data = {
      influencerUIDs: influencerUIDs.join(','),
      brandUID,
      campaignUID,
    };

    const headers = {
      Authorization: `Bearer ${idToken}`,
    };

    axios.post(Routes.spendCredits, data, { headers }).then((response) => {
      const responseData = response.data;
      const {
        brandDetails,
        appliedUIDs,
      } = responseData.data;

      dispatch(updateBrandDetails(brandUID, { ...brandDetails }));

      Object.keys(appliedUIDs).forEach((influencerUID) => {
        const influencer = influencers[influencerUID].details;

        dispatch(sendApproveMessage(
          brandUID,
          brandDetails,
          influencerUID,
          campaignUID,
          campaign,
          influencer,
        ));

        // TODO:
        //  do push notification here.

        dispatch(updateCampaignAppliedUID(campaignUID, influencerUID, appliedUIDs[influencerUID]));
        dispatch(updateInfluencer(influencerUID, {
          appliedUIDStatus: appliedUIDs[influencerUID].status,
        }));
        dispatch(removeInfluencerInList(influencerUID));
        dispatch(fetchCampaignNavLinkStatusCounts(campaignUID));
      });

      callback(true);
    }).catch((error) => {
      callback(false, { error });
    });
  }).catch((error) => {
    callback(false, { error });
  });
};

export const normalRejectInfluencers = ({
  rejectReason,
  influencers,
  influencerUIDs,
  currentCampaignUID,
  campaignUID,
  campaign,
  brandUID,
  brandDetails,
  massFinalReject,
}, callback = null) => (dispatch) => {
  // use in updating badge number in
  // application list & influencers
  const presentCampaignUID = currentCampaignUID || campaignUID;
  const handleCallback = (isSuccess, data) => {
    if (callback) {
      callback(isSuccess, data);
    }
  };

  Promise.map(influencerUIDs, (influencerUID) => {
    const influencerCampaignRef = firebase.influencer(influencerUID).child(`partnerships/products/${campaignUID}`);

    return influencerCampaignRef.update({ status: POST_STATUS.rejected }).then(() => {
      const appliedUID = {
        status: CAMPAIGN_APPLIED_UID_STATUS.rejected,
        timestamp: moment().format(DATE_TIMEZONE_FORMAT),
      };

      firebase.activeProduct(campaignUID).child(`appliedUID/${influencerUID}`).update(appliedUID).then(() => {
        dispatch(updateCampaignAppliedUID(campaignUID, influencerUID, appliedUID));
        dispatch(updateInfluencer(influencerUID, { appliedUIDStatus: appliedUID.status }));
      });

      // blocked to this brand
      if (massFinalReject === MASS_FINAL_REJECT.yes) {
        firebase.influencer(influencerUID).child('blocked').update({
          [brandUID]: brandUID,
        });
      }

      const text = `Hello, thanks so much for applying to the ${campaign.name} partnership. ${rejectReason}`;

      const sender = {
        uid: brandUID,
        name: brandDetails.name,
        type: MESSAGE_TYPE.brand,
      };

      const influencer = influencers[influencerUID].details;

      const recipient = [{
        uid: influencerUID,
        name: influencer.username,
        type: MESSAGE_TYPE.influencer,
      }];

      const partnership = {
        uid: campaignUID,
        name: campaign.name,
        type: MESSAGE_TYPE.product,
      };

      dispatch(sendNewMessage(
        text,
        sender,
        recipient,
        partnership,
        true,
      ));

      // TODO:
      //   do push notification here
      dispatch(removeInfluencerInList(influencerUID));
      dispatch(fetchCampaignNavLinkStatusCounts(presentCampaignUID)); // Update navlink counts
    });
  }).then(() => {
    handleCallback(true);
  }).catch((e) => {
    console.log('Error on rejectInfluencer Promises: ', e);
    callback(false);
  });
};

export const rejectToAllCampaign = ({
  rejectReason,
  influencerUIDs,
  influencers,
  brandUID,
  campaignUID: currentCampaignUID,
  massFinalReject,
}, callback = null) => (dispatch) => {
  firebase.brand(brandUID).once('value', (snapshot) => {
    const brand = snapshot.val();
    const campaignUIDs = Object.keys(brand.products).filter(campaignUID => (
      brand.products[campaignUID] === BRAND_PRODUCT_STATUS.active
    ));

    Promise.map(campaignUIDs, campaignUID => (
      firebase.activeProduct(campaignUID).once('value', (campaignSnapshot) => {
        const campaign = campaignSnapshot.val();

        if (campaign && campaign.appliedUID) {
          const existUIDs = Object.keys(campaign.appliedUID).filter(influencerUID => (
            campaign.appliedUID[influencerUID].status === CAMPAIGN_APPLIED_UID_STATUS.submitted
              && influencerUIDs.includes(influencerUID)
          ));

          dispatch(normalRejectInfluencers({
            rejectReason,
            influencers,
            influencerUIDs: existUIDs,
            currentCampaignUID,
            campaignUID,
            campaign,
            brandUID,
            brandDetails: brand.details,
            massFinalReject,
          }));
        }
      })
    )).then(() => {
      callback(true);
    }).catch((error) => {
      callback(false, { error });
    });
  }).catch((error) => {
    callback(false, { error });
  });
};

export const rejectInfluencers = (args, callback = null) => (dispatch) => {
  if (args.massFinalReject === MASS_FINAL_REJECT.yes) {
    dispatch(rejectToAllCampaign(args, callback));
  } else {
    dispatch(normalRejectInfluencers(args, callback));
  }
};

export const getAllInfluencersInBrand = brandUID => (dispatch) => {
  firebase.brand(brandUID).child('products').once('value').then((snapshot) => {
    let campaignUIDs = [];
    const brandCampaignUIDs = snapshot.val();

    Object.keys(brandCampaignUIDs).forEach((campaignUID) => {
      const status = brandCampaignUIDs[campaignUID];

      if (status === BRAND_PRODUCT_STATUS.active) {
        campaignUIDs = [...campaignUIDs, campaignUID];
      }
    });

    let influencerUIDs = [];

    Promise.map(campaignUIDs, campaignUID => (
      firebase.activeProduct(campaignUID).child('appliedUID').once('value', (influencersSnapshot) => {
        const influencers = influencersSnapshot.val();

        if (!influencers) {
          return;
        }

        Object.keys(influencers).forEach((influencerUID) => {
          const influencer = influencers[influencerUID];

          if (influencer.status === CAMPAIGN_APPLIED_UID_STATUS.approved) {
            influencerUIDs = [...influencerUIDs, influencerUID];
          }
        });
      })
    )).catch((e) => {
      console.log('Error on getting activeProduct: ', e);
    }).finally(() => {
      dispatch(setAllInfluencersInBrand(influencerUIDs));
    });
  });
};

export const getInfluencerByCreditsType = (influencerUIDs, influencers) => {
  const influencerType = {
    standard: 0,
    vip: 0,
  };

  influencerUIDs.forEach((influencerUID) => {
    const influencer = influencers[influencerUID].details;
    if (influencer.isVIP) {
      influencerType.vip += 1;
    } else {
      influencerType.standard += 1;
    }
  });

  return influencerType;
};
