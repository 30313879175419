import React from 'react';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from 'lib/Firebase';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import 'styles/base/antd-custom.less';
import * as routes from 'constants/routes';
import CampaignContent from './TabContent/CampaignContent';
import Chat from './Messages/Chat';
import Messages from './Messages';
import CampaignHistory from './CampaignHistory';
import ProductForm from './ProductForm';

const { Content } = Layout;

const Dashboard = (props) => {
  const { match } = props;

  return (
    <div>
      <Content>
        <Switch>
          <Route path="/campaigns/new" exact component={ProductForm} />
          <Route path={routes.CAMPAIGN_HISTORY_PATH} exact component={CampaignHistory} />
          <Route
            path="/campaigns/:campaignId"
            render={() => (
              <CampaignContent key={match.params.campaignId || 'empty'} {...props} />
            )}
          />
          <Route path="/campaigns/:campaignId/messages" component={Messages} />
          <Route path="/messages/:messageId" component={Chat} />
        </Switch>
      </Content>
    </div>
  );
};

export const mapStateToProps = state => ({
  campaigns: state.entities.campaigns,
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps),
)(Dashboard);
