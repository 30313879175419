export const SET_CAMPAIGN_POSTS = 'SET_CAMPAIGN_POSTS';

export const RESET_CAMPAIGN_POSTS = 'RESET_CAMPAIGN_POSTS';

const initialState = [
  // postIds
];

const campaignPosts = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGN_POSTS:
      return [
        ...new Set(state.concat(action.payload)),
      ];

    case RESET_CAMPAIGN_POSTS:
      return [];

    default:
      return state;
  }
};

export default campaignPosts;
