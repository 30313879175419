import React from 'react';
import { ReactComponent as IconSignout } from 'images/icon-sign-out.svg';

import { withFirebase } from 'lib/Firebase';

const SignOutButton = ({ firebase }) => (
  <a href="/logout" onClick={firebase.doSignOut}>
    <IconSignout />
    <span className="white d-block ht-5 bsize-4"> 
      Sign Out 
    </span>
  </a>
);

export default withFirebase(SignOutButton);
