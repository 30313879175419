import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';
import { IMPRESSIONS } from 'constants/posts';
import { totalPostSummary } from 'selectors/postsAnalytics';

class CampaignSummary extends Component {
  render() {
    const { summary } = this.props;

    return (
      <div style={{borderBottom: '1px solid #e8e8e8', paddingBottom: '30px'}}>
        <Row gutter={8} style={{ marginTop: '30px' }}>
          <Col span={5}>
            <h2 className="stats-heading">{summary.posts.toLocaleString()}/{summary.totalPost.toLocaleString()}</h2>
            <UpperCaseLabel>Live Posts</UpperCaseLabel>
          </Col>
          <Col span={5}>
            <h2 className="stats-heading">{summary.likes.toLocaleString()}</h2>
            <UpperCaseLabel>Likes</UpperCaseLabel>
          </Col>
          <Col span={5}>
            <h2 className="stats-heading">{summary.comments.toLocaleString()}</h2>
            <UpperCaseLabel>Comments</UpperCaseLabel>
          </Col>
          <Col span={5}>
            <h2 className="stats-heading">{(summary.impressions).toLocaleString()}</h2>
            <UpperCaseLabel>Impression</UpperCaseLabel>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  summary: totalPostSummary(state.entities.posts.byId, state.ui.campaignPosts)
})

export default connect(mapStateToProps)(CampaignSummary);
