import React from 'react';
import { Empty, Collapse, Icon, Steps } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const Panel = Collapse.Panel;
const Step = Steps.Step;

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

const ShippingHistory = ({ events }) => {
  const eventList = events.map((event, id) => (
    <Step key={id} title={event.description} description={moment(event.occurred_at).format("h:mm A ddd, MMM D YYYY")} style={{ width: '100%' }}/>
  ));

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
      style={{ marginTop: '22px' }}
    >
      <Panel header="History" style={customPanelStyle}>
        {
          events.length > 0 ? (
            <Steps direction="vertical" status="success" size="small" current={9}>
              { eventList }
            </Steps>
          ) : (
            <div>
              <Empty description={<span style={{ color: '#ddd' }}>No History yet.</span>} style={{marginTop: '20px'}}/>
            </div>
          )
        }
      </Panel>
    </Collapse>
  );
}

export default ShippingHistory;
