import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Form,
  Tag,
  Input,
  Tooltip,
  Icon,
  Switch,
  Radio,
  Select,
  message,
  InputNumber,
  Spin,
} from 'antd';
import FileUploader from 'react-firebase-file-uploader';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from 'lib/Firebase';
import { AuthUserContext } from 'lib/Session';
import { STATES } from 'dictionaries/states';
import { connect } from 'react-redux';
import { createCampaign, editCampaign } from 'actions/campaigns';
import * as routes from 'constants/routes';

import Logo from 'components/Common/Logo';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';

const { TextArea } = Input;
const RadioGroup = Radio.Group;
const Option = Select.Option;

const INITIAL_STATE = {
  brandUID: '',
  category: '',
  createdAt: '',
  guidelines: '',
  description: '',
  image: '',
  isFree: false,
  isListed: true,
  name: '',
  numberOfPosts: 0,
  isOnLikeToKnowIt: false,
  url: '',
  value: '',
  states: {},
  hashtags: {},
  brandtags: {},
  isVIP: false,
  error: null,
  isUploading: false,
};

class ProductFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.stateOptionEls = [];
    this.categories = [];
  }

  componentDidMount() {
    this.checkIfVIP();
    this.prefillStates();
    this.loadCategories();
    this.setState(() => ({ ...this.props.campaign }));

    // FIELDS WITH ANTD VALIDATION
    this.props.form.setFieldsValue({
      description: this.props.campaign.description,
      url: this.props.campaign.url,
      states: Object.values(this.props.campaign.states || {}),
      numberOfPosts: this.props.campaign.numberOfPosts,
      name: this.props.campaign.name,
    });
  }

  checkIfVIP = () => {
    const self = this;
    this.props.firebase
      .brandInfo(this.props.authUser.uid)
      .on('value', (snapshot) => {
        self.setState({
          showVIPOption: snapshot.val().credits > 0,
          credits: snapshot.val().credits,
        });
      });
  };

  onNumberOfPostsChange = (event) => {
    this.setState({ numberOfPosts: event.target.value });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onValueChange = (value) => {
    this.setState({ value });
  };

  onAntdChange = (event) => {
    this.props.form.setFieldsValue({ [event.target.name]: event.target.value });
  };

  onStatesChange = (value) => {
    const states = value.reduce((o, key) => ({ ...o, [key]: STATES[key] }), {});
    this.props.form.setFieldsValue({ states });
  };

  onCategoryChange = (value) => {
    this.setState({ category: value });
  };

  onIsOnLikeToKnowItChange = (value) => {
    this.setState({ isOnLikeToKnowIt: value });
    this.props.firebase
      .brandInfo(this.props.authUser.uid)
      .update({
        isOnLikeToKnowIt: value,
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  onVIPSwitch = (value) => {
    if (this.state.showVIPOption && this.state.credits > 0) {
      this.setState({ isVIP: value, showVIPOkMessage: value });
    } else if (this.state.showVIPOption && this.state.credits === 0) {
      this.setState({ isVIP: false, showVIPNotOkMessage: value });
    } else {
      this.setState({ isVIP: false });
    }
  };

  // Image Handler

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = progress => this.setState({ progress });

  handleUploadError = (error) => {
    this.setState({ isUploading: false });
  };

  handleUploadSuccess = (filename) => {
    this.setState({ progress: 100, isUploading: false });
    this.props.firebase.storage
      .ref('images')
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        this.setState({ image: url });
      });
  };

  // Brand Tags Handlers
  saveBrandTagInputRef = input => (this.input = input);

  handleBrandTagClose = (removedTag) => {
    const brandtags = Object.values(this.state.brandtags).filter(
      tag => tag !== removedTag,
    );
    this.setState({ brandtags });
  };

  showBrandTagInput = () => {
    this.setState({ brandTagInputVisible: true }, () => this.input.focus());
  };

  handleBrandTagInputChange = (e) => {
    this.setState({ brandTagInputValue: e.target.value });
  };

  handleBrandTagInputConfirm = () => {
    const state = this.state;
    const brandTagInputValue = state.brandTagInputValue;
    let brandtags = Object.values(state.brandtags);
    if (brandTagInputValue && brandtags.indexOf(brandTagInputValue) === -1) {
      brandtags = [...brandtags, brandTagInputValue];
    }
    this.setState({
      brandtags,
      brandTagInputVisible: false,
      brandTagInputValue: '',
    });
  };

  // Hash Tags Handlers
  savehashtagInputRef = input => (this.input = input);

  handlehashtagClose = (removedTag) => {
    const hashtags = Object.values(this.state.hashtags).filter(
      tag => tag !== removedTag,
    );
    this.setState({ hashtags });
  };

  showhashtagInput = () => {
    this.setState({ hashtagInputVisible: true }, () => this.input.focus());
  };

  handlehashtagInputChange = (e) => {
    this.setState({ hashtagInputValue: e.target.value });
  };

  handlehashtagInputConfirm = () => {
    const state = this.state;
    const hashtagInputValue = state.hashtagInputValue;
    let hashtags = Object.values(state.hashtags);
    if (
      hashtagInputValue
      && hashtags.indexOf(hashtagInputValue) === -1
      && Object.keys(hashtags).length < 5
    ) {
      hashtags = [...hashtags, hashtagInputValue];
    }
    this.setState({
      hashtags,
      hashtagInputVisible: false,
      hashtagInputValue: '',
    });
  };

  onSubmit = (event) => {
    const {
      category,
      createdAt,
      guidelines,
      image,
      isFree,
      isListed,
      isOnLikeToKnowIt,
      isVIP,
      hashtags,
      brandtags,
    } = this.state;

    // FIELDS WITH ANTD VALIDATION
    const {
      url,
      numberOfPosts,
      description,
      name,
      value,
      states,
    } = this.props.form.getFieldsValue();

    const self = this;

    const payload = {
      brandUID: self.props.authUser.uid,
      description,
      category,
      createdAt: new Date(),
      guidelines,
      image,
      isFree,
      isListed: isListed === '' ? true : isListed,
      isOnLikeToKnowIt,
      isVIP,
      name,
      states,
      hashtags,
      brandtags,
      numberOfPosts,
      url,
      value,
    };
    if (!Object.keys(brandtags).length) {
      message.error('Please provide at least one brand tag');
    } else if (!Object.keys(hashtags).length) {
      message.error('Please provide at least one hashtag');
    } else {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          if (this.state.uid) {
            this.props.editCampaign(payload, this.state.uid);
          } else {
            this.props.createCampaign(payload);
          }
        }
      });
    }

    event.preventDefault();
  };

  prefillStates = () => {
    const states = Object.keys(STATES);
    for (let i = 0; i < states.length; i++) {
      this.stateOptionEls.push(
        <Option key={i.toString(36) + i} value={states[i]}>
          {states[i]}
        </Option>,
      );
    }
  };

  loadCategories = () => {
    this.props.firebase
      .adminCategories()
      .once('value')
      .then((snapshot) => {
        const keys = Object.keys(snapshot.val());

        for (let i = 0; i < keys.length; i++) {
          this.categories.push(
            <Option key={i.toString(36) + i} value={snapshot.val()[keys[i]]}>
              {snapshot.val()[keys[i]]}
            </Option>,
          );
        }
      });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.newCampaign !== this.props.newCampaign) {
      this.props.history.push(
        routes.toCampaignSummaryPath(Object.keys(nextProps.newCampaign)),
      );
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const {
      category,
      createdAt,
      guidelines,
      image,
      isFree,
      isListed,
      isOnLikeToKnowIt,
      isVIP,
      showVIPOption,
      value,
      brandtags,
      hashtags,
      brandTagInputVisible,
      brandTagInputValue,
      hashtagInputVisible,
      hashtagInputValue,
      showVIPNotOkMessage,
      showVIPOkMessage,
      error,
    } = this.state;

    // FIELDS WITH ANTD VALIDATION
    const {
      url,
      numberOfPosts,
      description,
      states,
      name,
    } = this.props.form.getFieldsValue();

    const uploadButton = (
      <FileUploader
        hidden
        accept="image/*"
        storageRef={this.props.firebase.storage.ref('images')}
        onUploadStart={this.handleUploadStart}
        onUploadError={this.handleUploadError}
        onUploadSuccess={this.handleUploadSuccess}
        onProgress={this.handleProgress}
      />
    );

    return (
      <div style={{ background: '#000' }}>
        <div className="action-link clickable" align="center">
          <div className="back">
            <a
              href="#"
              onClick={() => {
                if (this.state.uid) {
                  this.props.history.push(routes.toCampaignSummaryPath(this.state.uid));
                } else {
                  this.props.history.push("/");
                }
              }}
            >
              <IconBack />
              <span className="white d-block ht-5 bsize-4">Back</span>
            </a>
          </div>
        </div>
        <Row type="flex" justify="space-around">
          <div className="min-layout-content small">
            <div className="mt-2 mb-8">
              <Logo />
            </div>
            <div className="pt-8 pb-8">
              <h1 className="white" align="center">
                {this.state.uid
                  ? 'Edit Your Campaign'
                  : 'Create a New Campaign'}
              </h1>
            </div>
            <Form
              layout="vertical"
              onSubmit={this.onSubmit}
              className="product-form pb-8"
            >
              <Form.Item label="Name your campaign">
                {getFieldDecorator('name', {
                  initialValue: name,
                  rules: [
                    {
                      required: true,
                      message: 'Add a campaign name.',
                    },
                  ],
                  onChange: this.onAntdChange,
                })(
                  <Input
                    type="text"
                    size="large"
                    className="trend-input dark large type-light"
                    placeholder="Add a campaign name"
                  />,
                )}
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <div align="center" className="product-uploader">
                  <p className="white">{ this.state.isUploading }</p>
                  <Spin spinning={this.state.isUploading} style={{ marginTop: '140px' }}/>
                  {this.state.image && !this.state.isUploading && (
                    <div>
                      <img
                        src={this.state.image}
                        className="product-image"
                        alt={this.state.image}
                      />

                      <label className="ant-btn trend-btn edit-btn">
                        <span>Change Photo</span>
                        { uploadButton }
                      </label>
                    </div>
                  )}
                  { !this.state.image && !this.state.isUploading && (
                    <label align="center" className="product-label">
                    {!this.state.image && <Icon type="plus" className="icon" />}
                    <span className="ht-3 mt-3 d-block">
                      Add a product image
                    </span>
                    <span style={{ color: '#747474', fontSize: '10px' }}>
                      Minimum 640x360. White background is ideal.
                    </span>
                    { uploadButton }
                  </label>
                  )}
                </div>
              </Form.Item>
              <Row gutter={30}>
                <Col span={12}>
                  <Form.Item label="Product Website">
                    {getFieldDecorator('url', {
                      initialValue: url,
                      rules: [
                        { required: true, message: 'Please enter website' },
                      ],
                      onChange: this.onAntdChange,
                    })(
                      <Input
                        type="text"
                        size="large"
                        className="trend-input"
                        placeholder="www.website.com"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    <div className="ant-form-item-label">
                      <label>Product Category</label>
                      <span
                        style={{
                          display: 'block',
                          fontFamily: 'truenoregular',
                          color: '#747474',
                          fontSize: '8px',
                        }}
                      >
                        Select one
                      </span>
                    </div>
                    {getFieldDecorator('category', {
                      initialValue: category,
                      rules: [
                        { required: true, message: 'Please choose a category.' },
                      ],
                      onChange: this.onCategoryChange,
                    })(
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        className="trend-select-dark"
                      >
                        {this.categories}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Product value in dollars">
                    <div>
                      {getFieldDecorator('value', {
                        initialValue: value,
                        rules: [
                          {
                            required: true,
                            message: 'Please provide a numeric value.',
                          },
                        ],
                        onChange: this.onValueChange,
                      })(
                        <InputNumber
                          className="trend-input"
                          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />,
                      )}
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="ant-form-item-label">
                      <label>Posts Per Influencer</label>
                      <span
                        style={{
                          display: 'block',
                          fontFamily: 'truenoregular',
                          color: '#747474',
                          fontSize: '8px',
                        }}
                      >
                        {'Product Value < $50 = 1 post'}
                      </span>
                    </div>
                    <div>
                      {getFieldDecorator('numberOfPosts', {
                        initialValue: numberOfPosts,
                        rules: [
                          {
                            required: true,
                            message: 'Please choose number of posts.',
                          },
                        ],
                        onChange: this.onAntdChange,
                      })(
                        <RadioGroup>
                          <Radio value={1} style={{ color: '#747474' }}>
                            1
                          </Radio>
                          <Radio value={2} style={{ color: '#747474' }}>
                            2
                          </Radio>
                        </RadioGroup>,
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Enter Target States" className="mb-0">
                {getFieldDecorator('states', {
                  initialValue: Object.values(states || {}),
                  onChange: this.onStatesChange,
                })(
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="All"
                    className="trend-select-dark"
                  >
                    {this.stateOptionEls}
                  </Select>,
                )}
              </Form.Item>
              <div className="mt-8 pt-8" />
              <Form.Item className="mb-2">
                <label
                  className="size-18px text-gray type-sbold"
                >
                  Brand Tags and Hash Tags
                </label>
                <p
                  className="ht-1 mt-3 size-14px text-gray"
                  style={{
                    lineHeight: '1.71',
                  }}
                >
                  Make Influencers include your brands Instagram handle with
                  their posts. This way audience members will have a clear path
                  to visit your page. add required hashtags to drive people help
                  your posts be discovered by people searching for specific
                  themes (ex. #healthysnack, #feelinggood, #newyearnewyou)
                </p>
              </Form.Item>
              <Form.Item>
                <div className="ant-form-item-label">
                  <label>Required Instagram Handles</label>
                  <span
                    className="d-block type-regular text-gray-darkest size-8px"
                  >
                    Press enter after each handle
                  </span>
                </div>
                <div>
                  {brandTagInputVisible && (
                    <Input
                      ref={this.saveBrandTagInputRef}
                      type="text"
                      className="trend-tag-plain"
                      value={brandTagInputValue}
                      onChange={this.handleBrandTagInputChange}
                      onBlur={this.handleBrandTagInputConfirm}
                      onPressEnter={this.handleBrandTagInputConfirm}
                    />
                  )}
                  {!brandTagInputVisible && (
                    <Tag
                      onClick={this.showBrandTagInput}
                      className="trend-tag-plain"
                    >
                      @YourHandle
                    </Tag>
                  )}
                  <div style={{ marginTop: '15px' }}>
                    {Object.entries(brandtags).map(([index, tag]) => {
                      const isLongTag = tag.length > 20;
                      const tagElem = (
                        <Tag
                          key={tag}
                          className="trend-tag"
                          closable
                          afterClose={() => this.handleBrandTagClose(tag)}
                        >
                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                  </div>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="ant-form-item-label">
                  <label>Required Hashtags (Max 5)</label>
                  <span
                    className="d-block type-regular text-gray-darkest size-8px"
                  >
                    Press enter after each tag
                  </span>
                </div>
                <div>
                  {hashtagInputVisible && (
                    <Input
                      ref={this.savehashtagInputRef}
                      type="text"
                      className="trend-tag-plain"
                      value={hashtagInputValue}
                      onChange={this.handlehashtagInputChange}
                      onBlur={this.handlehashtagInputConfirm}
                      onPressEnter={this.handlehashtagInputConfirm}
                    />
                  )}
                  {!hashtagInputVisible && (
                    <Tag
                      onClick={this.showhashtagInput}
                      className="trend-tag-plain"
                    >
                      #yourhashtag
                    </Tag>
                  )}
                  <div style={{ marginTop: '15px' }}>
                    {Object.entries(hashtags).map(([index, tag]) => {
                      const isLongTag = tag.length > 20;
                      const tagElem = (
                        <Tag
                          key={tag}
                          className="trend-tag"
                          closable
                          afterClose={() => this.handlehashtagClose(tag)}
                        >
                          {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                  </div>
                </div>
              </Form.Item>
              <Form.Item>
                <label
                  className="d-block type-sbold text-gray size-18px"
                >
                  Description
                </label>
                {getFieldDecorator('description', {
                  initialValue: description,
                  rules: [
                    { required: true, message: 'Please enter description' },
                  ],
                  onChange: this.onAntdChange,
                })(
                  <TextArea
                    rows="4"
                    name="description"
                    className="mt-2 trend-textarea"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <label
                  className="d-block type-sbold text-gray size-18px"
                >
                  Show listing to VIP influencers?
                </label>
                <p
                  className="mt-2 ht-1 size-14px text-gray"
                  style={{
                    lineHeight: '1.71',
                  }}
                >
                  VIP Influencers are our best of the best influencers. They
                  have a combination of amazing content creation skills, a very
                  engaged audience, and a sizeable following. You can always add
                  more VIP influencer credits to your plan from the dashboard.
                </p>
                <div
                  className="ht-1 size-12px text-gray"
                  style={{
                    lineHeight: '1.71',
                  }}
                >
                  {showVIPOption !== null ? (
                    showVIPOption === true ? (
                      <Switch onChange={this.onVIPSwitch} />
                    ) : (
                      'You do not have VIP credits. Please contact Trend to upgrade your subscription.'
                    )
                  ) : null}
                </div>
                <div
                  className="ht-1 size-12px text-gray"
                  style={{
                    lineHeight: '1.71'
                  }}
                >
                  {showVIPNotOkMessage === true
                    ? 'You do not have VIP credits. Please contact Trend to upgrade your subscription.'
                    : null}
                  {showVIPOkMessage === true
                    ? "If set to yes, this listing will be sent to our top influencers. When they apply, their profile will have a 'VIP' next to their name so you can identify them. You're allowed a a maximum number of VIP credits every month depending on your package."
                    : null}
                </div>
              </Form.Item>

              <Form.Item>
                <div className="ant-form-item-label">
                  <label>Is this product on "Like to Know It"?</label>
                </div>
                <div>
                  <Switch
                    defaultChecked={isOnLikeToKnowIt}
                    onChange={this.onIsOnLikeToKnowItChange}
                    checked={isOnLikeToKnowIt}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <label
                  className="d-block type-sbold text-gray size-18px"
                >
                  General Creative Guidelines
                </label>
                <p
                  className="ht-1 mt-3 mb-6 size-14px text-gray"
                  style={{
                    lineHeight: '1.71'
                  }}
                >
                  Give them a general understanding of what you’re looking for
                  and have faith in their creative minds to come up with content
                  that connects with their audience. After all, there followers
                  follow them for a reason!
                </p>
                <div>
                  {getFieldDecorator('guidelines', {
                    initialValue: guidelines,
                    rules: [
                      { required: true, message: 'Please provide guidelines.' },
                    ],
                    onChange: this.onChange,
                  })(
                    <TextArea
                      rows="6"
                      name="guidelines"
                      className="trend-textarea dark"
                      placeholder="Start Typing..."
                    />,
                  )}
                </div>
              </Form.Item>
              {error && <p>{error.message}</p>}
              <div align="center">
                <Button
                  size="large"
                  type="submit"
                  className="trend-btn product-btn"
                  onClick={this.onSubmit}
                >
                  {this.state.uid ? 'Update' : 'Save & Create Listing'}
                </Button>
              </div>
            </Form>
          </div>
        </Row>
      </div>
    );
  }

  static propTypes = {
    campaign: PropTypes.object,
  };

  static defaultProps = {
    campaign: {},
  };
}

const ProductFormPage = props => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div style={{ padding: '0', background: '#000' }}>
        <ProductForm authUser={authUser} {...props} campaign={props.campaign} />
      </div>
    )}
  </AuthUserContext.Consumer>
);

export const mapStateToProps = state => ({
  newCampaign: state.entities.campaigns.newCampaign,
  error: state.entities.campaigns.error,
});

export const mapDispatchToProps = dispatch => ({
  createCampaign: payload => dispatch(createCampaign(payload)),
  editCampaign: (payload, id) => dispatch(editCampaign(payload, id)),
});

const ProductForm = compose(
  withRouter,
  withFirebase,
  Form.create({ name: 'register' }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ProductFormBase);

export default ProductFormPage;
