import React, { Component } from 'react';
import {
  Row,
  Col,
  Spin,
  Layout
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PostCard from './PostCard';
import CustomEmpty from 'components/Common/CustomEmpty'
import { filterFavoritePosts } from 'selectors/postsAnalytics';
import MinLayout from 'components/Common/MinLayout';

const { Content } = Layout;

class GridLayout extends Component {
  render() {
    const {
      posts,
      influencers
    } = this.props;

    const grids = this.props.postIds.map((postUID) => {
      const { influencerUID, details: post } = posts[postUID];
      const influencer = influencers[influencerUID].details;

      return (
        <Col span={8} key={postUID} style={{ marginBottom: '10px' }}>
          <div>
            <PostCard
              onClickFavorite={this.props.onClickFavorite}
              influencerUID={influencerUID}
              postUID={postUID}
              post={post}
              influencer={influencer || {}}
            />
          </div>
        </Col>
      );
    });

    return (
      <Content style={{ background: '#fff', minHeight: 600 }}>
        <Row type="flex" justify="space-around">
          <MinLayout>
            {
              this.props.isFetching
                ? <div style={{ textAlign: 'center' }}><Spin style={{ marginTop: '10px' }} /></div>
                : <Row gutter={16}>{grids}</Row>
            }
            {
              !this.props.isFetching && grids.length === 0 &&
                <CustomEmpty style={{ marginTop: '10px', textAlign: 'center' }} description='No post yet.' />
            }
          </MinLayout>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  posts: state.entities.posts.byId,
  postIds: filterFavoritePosts(
    state.ui.modeView.isFavorite,
    state.entities.posts.byId,
    state.ui.postsAnalytics
  ),
  influencers: state.entities.influencers.byId,
  isFetching: state.entities.posts.isFetching
});

export default compose(
  withRouter,
  connect(mapStateToProps)
)(GridLayout);
