/* eslint no-console: ["error", { allow: ["log"] }] */
/* eslint newline-per-chained-call: ["error", { "ignoreChainWithDepth": 6 }] */
import { firebase } from 'lib/Firebase';
import {
  fetchRequest,
  receiveRequest,
  updatePost,
} from 'actions/posts';
import {
  POST_STATUS,
  POST_DETAIL_STATUS,
} from 'constants/posts';
import { updateInfluencerPost } from 'actions/influencers';
import paypal from 'lib/Paypal';
import {
  PAYMENT_VIP_AMOUNT,
  PAYMENT_STANDARD_AMOUNT,
} from 'constants/common';
import Instagram from 'lib/Instagram';

export default (args, callback) => {
  const {
    influencerUID,
    influencer,
    campaignUID,
    campaign,
    postUID,
    postCode,
    status,
  } = args;

  return (dispatch) => {
    dispatch(fetchRequest());

    const handleCallback = (isSuccess, response) => {
      dispatch(receiveRequest());
      callback(isSuccess, response);
    };

    const influencerCampaignRef = firebase.influencerProduct(influencerUID, campaignUID);
    const postDetailsRef = influencerCampaignRef.child(`posts/${args.postUID}/details`);

    postDetailsRef.update({
      status,
      isUnread: true,
    }).then(() => {
      dispatch(updateInfluencerPost(influencerUID, campaignUID, null, postUID, { status }));

      // terminate right away if status not approved
      // don't need to proceed
      if (status !== POST_DETAIL_STATUS.approved) {
        handleCallback(true);
        return;
      }

      if (postCode) {
        Instagram.getAttributes(postCode, { isIncludeImage: true }, (isSuccess, {
          attributes,
          error,
        }) => {
          if (isSuccess) {
            postDetailsRef.update(attributes).then(() => {
              dispatch(updatePost(postUID, attributes));
            });
          } else {
            console.log('instagram getAttributes: ', error);
          }
        });
      }

      influencerCampaignRef.once('value', (snapshot) => {
        const campaignPosts = snapshot.val();
        let isCompleted = true;

        Object.keys(campaignPosts.posts).forEach((uid) => {
          const post = campaignPosts.posts[uid];

          if (post.details.status !== POST_DETAIL_STATUS.approved) {
            isCompleted = false;
          }
        });

        if (!isCompleted) {
          handleCallback(true);
          return;
        }

        // don't update if status is already completed,
        // to also avoid double charge
        if (!campaign.isFree && campaignPosts.status !== POST_STATUS.completed) {
          const paymentAmount = influencer.isVIP ? PAYMENT_VIP_AMOUNT : PAYMENT_STANDARD_AMOUNT;

          const paypalCallback = (isSuccess, { payoutBatchId, error }) => {
            if (isSuccess) {
              console.log('payoutBatchId: ', payoutBatchId);

              influencerCampaignRef.update({ status: POST_STATUS.completed }).then(() => {
                dispatch(updateInfluencerPost(
                  influencerUID,
                  campaignUID,
                  POST_STATUS.completed,
                ));

                handleCallback(true, { payoutBatchId });
              }).catch((e) => {
                handleCallback(false);
                console.log('Error on updating status completed: ', e);
              });
            } else {
              handleCallback(false);
              console.log(error);
            }
          };

          paypal.pay(
            influencerUID,
            influencer.paypalEmail,
            campaignUID,
            paymentAmount,
            paypalCallback,
          );
        } else {
          handleCallback(true);
        }


      });
    }).catch((e) => {
      handleCallback(false);
      console.log('Error on updating status postDetailStatusChange: ', e);
    });
  };
};
