import React from 'react';
import { Menu, Avatar } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-router-dom'

const CampaignMenu = ({ campaignId, image, title, ...others }) => {
  return (
    <Menu.Item {...others}>
      <Link to={`/campaigns/${campaignId}/summary`}>
        <Avatar src={image} shape='square' size={36} className="trend-campaign-avatar" style={{borderRadius: '0', position: 'relative',
    overflow: 'hidden'}}/>
        <span className={css(styles.font)}>{title}</span>
      </Link>
    </Menu.Item>
  );
};

const styles = StyleSheet.create({
  font: {
    fontSize: '10px',
    marginLeft: '9px',
    lineHeight: '1.4'
  }
});

export default CampaignMenu;
