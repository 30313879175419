import { fetchCampaignActiveOrComplete } from 'api/campaigns';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import { firebase } from 'lib/Firebase';

export const fetchInfluencer = (influencerUID, onComplete) => {
  firebase.influencer(influencerUID).once('value', (snapshot) => {
    const influencer = {};
    if (snapshot.exists()) {
      influencer[influencerUID] = snapshot.val();
    }

    onComplete(influencer);
  });
};

export const fetchCampaignAppliedInfluencerUIDs = (campaignUID, onComplete = null) => {
  fetchCampaignActiveOrComplete(campaignUID, (isSucces, { campaign, error }) => {
    const influencerUIDs = [];
    if (error) { onComplete(error); }

    Object.keys(campaign.appliedUID || {}).forEach((influencerUID) => {
      const influencer = campaign.appliedUID[influencerUID];
      if (influencer.status === CAMPAIGN_APPLIED_UID_STATUS.submitted) {
        influencerUIDs.push(influencerUID);
      }
    });
    onComplete(influencerUIDs);
  });
};

export const fetchCampaignApprovedInfuencerUIDs = (campaignUID, onComplete = null) => {
  fetchCampaignActiveOrComplete(campaignUID, (isSucces, { campaign, error }) => {
    const influencerUIDs = [];
    if (error) { onComplete(error); }

    Object.keys(campaign.appliedUID || {}).forEach((influencerUID) => {
      const approvedInfluencer = campaign.appliedUID[influencerUID];
      if (approvedInfluencer.status === CAMPAIGN_APPLIED_UID_STATUS.approved) {
        influencerUIDs.push(influencerUID);
      }
    });

    onComplete(influencerUIDs);
  });
};
