export const CHANGE_MODE_VIEW = 'CHANGE_MODE_VIEW';

export const FILTER_FAVORITE = 'FILTER_FAVORITE';

const defaultState = {
  view: 'grid',
  isFavorite: false
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case CHANGE_MODE_VIEW:
      return {
        ...state,
        view: action.view
      }

    case FILTER_FAVORITE:
      return {
        ...state,
        isFavorite: action.isFavorite
      };

    default:
      return state;
  }
}
