import axios from 'axios';
import Routes from 'api/routes';
import UserToken from 'lib/Firebase/userToken';

export const addCredits = async (
  brandUID,
  standardCredits,
  vipCredits,
  onComplete = null) => {
  const data = { brandUID, standardCredits, vipCredits };
  const tokenData = await UserToken();

  axios.post(Routes.addCredits, data, { headers: tokenData.headers }).then((response) => {
    onComplete(response);
  }).catch((error) => {
    onComplete(null, error);
  });
};
