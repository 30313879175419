import React, { Component } from 'react';
import {
  Row,
  List,
  Skeleton,
  Avatar,
} from 'antd';
import { fetchCampaignHistories } from 'actions/ui/campaignHistory';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import MinLayout from 'components/Common/MinLayout';

class CampaignHistory extends Component {
  state = {
    brandUID: null
  }

  componentDidMount() {
    if(this.props.currentUser) {
      this.props.fetchCampaignHistories(this.props.currentUser.uid)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentUser && this.props.currentUser !== prevProps.currentUser) {
      this.props.fetchCampaignHistories(this.props.currentUser.uid)
    }
  }

  render () {
    let unlistedCampaigns = {}
    this.props.archivedCampaigns.forEach( (id) => {
      unlistedCampaigns[id] = this.props.campaigns.byId[id]
    } )

    return (
      <Row type="flex" justify="space-around" style={{paddingTop: '38px'}}>
        <MinLayout>
          <h1> Campaign History </h1>
          <List
            dataSource={Object.entries(unlistedCampaigns)}
            header="Removed Listings"
            itemLayout="horizontal"
            bordered
            loading={this.props.isFetching}
            renderItem={ ([key, campaign]) => (
              <List.Item actions={[<Link className='ant-btn trend-btn' to={ routes.toCampaignSummaryPath(key) } >View</Link>]}>
                <Skeleton avatar title={false} loading={this.props.isFetching} active>
                  <List.Item.Meta
                    avatar={<Avatar src={campaign.image} size='large'/>}
                    title={campaign.name}
                    description={campaign.description}
                  />
                </Skeleton>
              </List.Item>
            ) }
          />

        </MinLayout>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.entities.campaigns,
  currentUser: state.entities.session.authUserData,
  archivedCampaigns: state.ui.campaignHistory.archivedCampaigns,
  isFetching: state.ui.campaignHistory.isFetching
})

const mapDispatchToProps = (dispatch) => ({
  fetchCampaignHistories: (brandUID) => dispatch(fetchCampaignHistories(brandUID))
})

export default connect(mapStateToProps, mapDispatchToProps)(CampaignHistory)
