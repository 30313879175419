/* eslint no-console: ["error", { allow: ["log"] }] */
/* eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }] */
import * as Promise from 'bluebird';
import moment from 'moment';
import { firebase } from 'lib/Firebase';
import Instagram from 'lib/Instagram';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import { ADD_POSTS } from 'reducers/entities/posts';
import { updatePost } from 'actions/posts';

const postListener = ({ _, dispatch }) => next => (action) => {
  if (action.type === ADD_POSTS) {
    Promise.map(Object.entries(action.posts), ([postUID, post]) => {
      const {
        details: postDetails = {},
        influencerUID,
        productUID,
      } = post;
      const {
        code: postCode,
      } = postDetails;
      const lastUpdated = moment().subtract(1, 'days').set('hour', 0);

      let { lastUpdated: postLastUpdated } = postDetails;

      if (postLastUpdated) {
        postLastUpdated = moment(new Date(postLastUpdated), DATE_TIMEZONE_FORMAT);
      }

      if (postDetails && !!postCode && (!postLastUpdated || postLastUpdated < lastUpdated)) {
        return Instagram.getAttributes(postCode, {}, (isSuccess, {
          attributes,
          error,
        }) => {
          if (isSuccess) {
            firebase
              .influencer(influencerUID)
              .child(`partnerships/products/${productUID}/posts/${postUID}/details`)
              .update(attributes);

            dispatch(updatePost(postUID, attributes));
          } else {
            console.log('Coundn\'t retrieve data', error);
          }
        });
      }

      return null;
    }).catch((error) => {
      console.log('Did not retrieve data', error);
    });
  }

  return next(action);
};

export default postListener;
