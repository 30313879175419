import React from 'react';
import moment from 'moment';
import {
  Row,
  Col,
  Avatar
} from 'antd';

function ChatItem({ item } = {}) {
  return (
    (item.own) ?
      <Row style={{ backgroundColor: '#ffffff', marginTop: 40, marginBottom: 40}}  type="flex" justify="space-around">
        <Col align="center" span={4}></Col>
        <Col span={14} offset={3} style={{border: '1px solid #eee', padding: 25, borderRadius: 10, backgroundColor: '#f8f9fb'}}>
          <p style={{marginBottom: '0', fontSize: '12px', color: '#000', lineHeight: '2.08'}}>{item.text}</p>
        </Col>
        <Col align="center" span={2}>
          <Avatar src={item.avatar} size={64}>User</Avatar>
          <p style={{marginTop: '10'}}><small>{moment(item.timestamp).fromNow()}</small></p>
        </Col>
      </Row>
    :
      <Row style={{ backgroundColor: '#ffffff', marginTop: 40, marginBottom: 40}}  type="flex" justify="space-around">
        <Col align="center" span={2}>
          <Avatar src={item.avatar} size={64}>Me</Avatar>
          <p style={{marginTop: '10'}}><small>{moment(item.timestamp).fromNow()}</small></p>
        </Col>
        <Col span={14} style={{border: '1px solid #eee', padding: 25, borderRadius: 10}}>
          <p style={{marginBottom: '0', fontSize: '12px', color: '#000', lineHeight: '2.08'}}>{item.text}</p>
        </Col>
        <Col align="center" span={4}></Col>
      </Row>
      
  );
}

export default ChatItem;
