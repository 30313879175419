import React, { Component } from 'react';
import {
  Table,
  Row,
  Button,
  Tag,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { POST_STATUS } from 'constants/posts';
import { showShippingFormModal } from 'actions/ui/shippingFormModal';
import MinLayout from 'components/Common/MinLayout';
import CustomEmpty from 'components/Common/CustomEmpty';
import InfluencerAvatar from 'components/Common/InfluencerAvatar';
import ShippingFormModal from './ShippingFormModal';

const columns = [
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      const color = status !== POST_STATUS.needsShipping ? 'green' : 'orange';

      return (
        <Tag color={color}>
          {status !== POST_STATUS.needsShipping ? 'Shipped' : 'Needs Shipping'}
        </Tag>
      );
    },
  },
  {
    title: 'INFLUENCERS',
    dataIndex: 'influencer',
    key: 'influencer',
    width: '220px',
    render: InfluencerAvatar,
  },
  {
    title: 'ADDRESS',
    dataIndex: 'influencer',
    key: 'address',
    render: influencer => (
      <div style={{ fontSize: '10pt', color: '#999' }}>
        { `${influencer.address}, ${influencer.city}, ${influencer.state}, ${influencer.zip}` }
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    render: ({ showModal, influencerId, productId }) => (
      <div>
        <Button onClick={() => showModal(influencerId, productId)}>Shipping Details</Button>
      </div>
    ),
  },
];

class ProductShipments extends Component {
  render() {
    const {
      match, influencerIds, influencers,
      showShippingFormModal: showModal,
      isFetching, isUpdating,
    } = this.props;
    const campaignUID = match.params.campaignId;
    const list = influencerIds.map((influencerUID) => {
      const influencer = influencers[influencerUID];

      return {
        key: influencerUID,
        influencer: influencer.details,
        status: influencer.partnerships.products[campaignUID].status,
        action: {
          showModal,
          influencerId: influencerUID,
          productId: campaignUID,
        },
      };
    });

    return (
      <Row type="flex" justify="space-around">
        <MinLayout>
          <Table
            locale={{ emptyText: <CustomEmpty description="No shipments to show" /> }}
            className="trend-table"
            loading={isFetching || isUpdating}
            columns={columns}
            dataSource={list}
          />
          <ShippingFormModal />
        </MinLayout>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  influencers: state.entities.influencers.byId,
  influencerIds: state.ui.influencerList,
  isFetching: state.entities.influencers.isFetching,
  isUpdating: state.entities.campaigns.isFetching,
});

const mapDispatchToProps = {
  showShippingFormModal,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ProductShipments);
