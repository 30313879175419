import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Row,
  Layout,
  Col,
  Button,
  Spin,
} from 'antd';

import { withRouter, Link } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import HeaderText from 'components/Common/HeaderText';
import ChatItem from './ChatItem';
import { withAuthorization } from 'lib/Session';
import { DATE_TIMEZONE_FORMAT } from 'constants/common'
import { ReactComponent as IconBackMessage } from 'images/icon-back-arrow.svg';
import MinLayout from 'components/Common/MinLayout';
import { fetchChatMessages, sendChatMessage } from 'actions/chat_messages';
import { fetchMessage, markThreadAsRead } from 'actions/messages';
import { fetchBrand } from 'actions/brands';
import { MESSAGE_TYPE } from 'constants/common'

const { Content, Header, Footer } = Layout;

const INITIAL_STATE = {
  profile: {}
}

class Chat extends Component {
  constructor(props) {
    super(props)
    this.state = {...INITIAL_STATE}
    this.inputField = React.createRef();

    this.props.fetchMessage(this.props.match.params.messageId, this.props.authUser.uid)
    this.props.markThreadAsRead(this.props.match.params.messageId)
    this.props.fetchBrand(this.props.authUser.uid);
    this.props.fetchChatMessages(this.props.match.params.messageId, this.props.authUser.uid)
    this.influencer = {};
  }

  componentDidMount() {
    this.inputField.current.focus();
  }

  onJustSend = () => {
    this.onSend(this.inputField.current.value);
    this.inputField.current.value = ''
  }

  componentWillUnmount() {
    this.props.firebase.message(this.props.match.params.messageId).off()
    this.props.firebase.chatMessages(this.props.match.params.messageId).off()
  }

  onPressSend = event => {
    event.persist();
    if(event.keyCode === 13){
      this.onJustSend();
    }
  }

  onSend = (message) => {
    const params = {
      sender: this.props.authUser.uid,
      text: message,
      timestamp: moment().format(DATE_TIMEZONE_FORMAT)
    }
    this.props.sendChatMessage(this.props.match.params.messageId, this.influencer.influencerUID, params)
  }

  scrollToBottom = () => {
    const self = this;
    setTimeout(() => {
      self.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }, 100)
  }

  render() {
    let dataSource = [];
    let profile = {name: '', instagramID: ''};
    const thread = this.props.chat_messages.all

    if (thread.messages && this.props.influencers.all.length && this.props.brands.all.length){
      let brand = this.props.brands.byId[this.props.brands.all[0]]

      Object.keys(thread.users).forEach((item) => {
        if(thread.users[item].type === MESSAGE_TYPE.influencer) {
          this.influencer = this.props.influencers.byId[item]
          profile = this.influencer.details
        }
      })

      dataSource = Object.entries(this.props.chat_messages.all.messages).map(([id, chatMessage]) => {
        return({
          sender: thread.users[this.props.authUser.uid],
          own: (chatMessage.sender === this.props.authUser.uid),
          text: chatMessage.text,
          timestamp: chatMessage.timestamp,
          avatar: (chatMessage.sender === this.props.authUser.uid) ? brand.details.logo : profile.image
        });
      })

    }
    this.scrollToBottom();

    return(
      <Spin spinning={this.props.chat_messages.isFetching}>
        <Header style={{backgroundColor: '#fff', borderBottom: '1px solid #d6d6d6', padding: '30px 0', height: '90px', position: 'fixed', top: '0', width: 'calc(100% - 210px)', zIndex: 1 }}>
          <Row type="flex" justify="space-around">
            <MinLayout>
              <HeaderText bold={true} style={{marginBottom: 48, paddingBottom: 48}}>
                <Button style={{float: 'left', marginRight: 24, paddingRight: 22, color: '#000', boxShadow: 'none', border: '0', borderRight: '1px solid #d6d6d6', borderRadius: '0', fontSize: '12px', textAlign: 'center'}} onClick={this.props.history.goBack}>
                  <IconBackMessage style={{display: 'inlineBlock'}} />
                  <span style={{display: 'block'}}>Back</span>
                </Button>
                <div style={{float: 'left', marginTop: '6px', lineHeight: '0'}}>
                  <div style={{lineHeight: '0'}}>{profile.name}</div>
                  {profile   ?
                    <a
                      href={`https://www.instagram.com/${profile.instagramID}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{lineHeight: '3.5', cursor: 'pointer', color: '#ffb917', fontStyle: 'italic', fontFamily: 'truenosemibold', fontSize: '12px'}}>
                    See Profile
                    </a>
                   : null }
                </div>
              </HeaderText>
            </MinLayout>
          </Row>
        </Header>
        <Content style={{marginTop: 48, marginBottom: 64, paddingTop: 24}}>
          <Row type="flex" justify="space-around">
            <MinLayout>
              { dataSource.map((row, idx) => <ChatItem key={idx} item={row} />) }
              <div style={{ float:"left", clear: "both" }}
                 ref={(el) => { this.messagesEnd = el; }}>
              </div>
            </MinLayout>
          </Row>
        </Content>
        <Footer style={{ backgroundColor: '#fff', position: 'fixed', bottom: '0', width: 'calc(100% - 210px)', borderTop: '1px solid #e8e8e8', margin: 0, paddingTop: 24, paddingLeft: 24 }}>
          <Row style={{ backgroundColor: '#fff'}}  align="middle" gutter={4} type="flex" justify="space-around">
            <MinLayout>
              <Col span={22} className="trend-message-input">
                <input className="ant-input ant-input-lg" onKeyDown={this.onPressSend} type="text" ref={this.inputField} size='large' placeholder="Type a Message..." style={{border: 0, color: '#000'}}/>
              </Col>
              <Col span={2}>
                <Button onClick={this.onJustSend} className='trend-btn' size='large' style={{color: '#000', paddingLeft: 24, paddingRight: 24}}>Send</Button>
              </Col>
            </MinLayout>
          </Row>
        </Footer>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => ({
  brands: state.entities.brands,
  messages: state.entities.messages,
  influencers: state.entities.influencers,
  chat_messages: state.entities.chat_messages,
  isFetching: state.entities.chat_messages.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBrand: (brandUID) => dispatch(fetchBrand(brandUID)),
  fetchChatMessages: (messageId, brandID) => dispatch(fetchChatMessages(messageId, brandID)),
  fetchMessage: (messageId, brandID) => dispatch(fetchMessage(messageId, brandID)),
  markThreadAsRead: (messageId) => dispatch(markThreadAsRead(messageId)),
  sendChatMessage: (messageId, influencerUID, data) => dispatch(sendChatMessage(messageId, influencerUID, data))
});

const condition = authUser => !!authUser;

export default compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps)
)(Chat);
