import { firebase } from 'lib/Firebase';
import {
  SET_INFLUENCER,
  SET_INFLUENCERS,
  UPDATE_INFLUENCER,
  UPDATE_INFLUENCER_DETAILS,
  UPDATE_INFLUENCER_POST,
  INFLUENCER_FETCH_REQUEST,
  INFLUENCER_RECEIVE_REQUEST
} from 'reducers/entities/influencers'

export const fetchRequest = () => ({ type: INFLUENCER_FETCH_REQUEST });

export const receiveRequest = () => ({ type: INFLUENCER_RECEIVE_REQUEST });

export const setInfluencers = (influencers) => ({
  type: SET_INFLUENCERS,
  influencers
});

export const setInfluencer = (influencer) => ({
  type: SET_INFLUENCER,
  influencer: influencer
})

export const updateInfluencer = (influencerUID, influencer) => ({
  type: UPDATE_INFLUENCER,
  influencerUID,
  influencer
});

export const updateInfluencerDetails = (influencerUID, influencerDetails) => ({
  type: UPDATE_INFLUENCER_DETAILS,
  influencerUID,
  details: influencerDetails
});

export const updateInfluencerPost = (influencerUID, campaignUID, campaignStatus, postUID, postDetails) => ({
  type: UPDATE_INFLUENCER_POST,
  influencerUID,
  campaignUID,
  campaignStatus,
  postUID,
  postDetails
});


export const fetchInfluencer = (influencerUID) => (dispatch) => {
  firebase.influencer(influencerUID).once('value', function(snapshot) {
    const influencer = {};
    // influencer[snapshot.key] = snapshot.val()
    influencer[influencerUID] = { influencerUID, ...snapshot.val() };
    dispatch(setInfluencer(influencer));
  });
};