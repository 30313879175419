/* eslint no-console: ["error", { allow: ["log"] }] */
import { firebase } from 'lib/Firebase';
import * as Promise from 'bluebird';
import {
  SET_INFLUENCER_LIST,
  REMOVE_INFLUENCER_IN_LIST,
} from 'reducers/ui/influencerList';
import {
  fetchRequest,
  receiveRequest,
  setInfluencer,
} from 'actions/influencers';
import { fetchCampaignActiveOrComplete } from 'api/campaigns';

export const setInfluencerList = influencers => ({
  type: SET_INFLUENCER_LIST,
  influencers,
});

export const removeInfluencerInList = influencerUID => ({
  type: REMOVE_INFLUENCER_IN_LIST,
  influencerUID,
});

export const getInfluencerList = (args = {}) => (dispatch) => {
  dispatch(fetchRequest());
  dispatch(setInfluencerList({}));

  fetchCampaignActiveOrComplete(args.campaignId, (isSuccess, { campaign, error }) => {
    if (!isSuccess) {
      dispatch(receiveRequest());
      console.log('Error on fetchingCampaignActiveOrComplete: ', error);
      return;
    }

    if (!campaign.appliedUID) {
      dispatch(receiveRequest());
      return;
    }

    const influencerUIDs = [];

    Object.keys(campaign.appliedUID).forEach((influencerUID) => {
      const influencer = campaign.appliedUID[influencerUID];
      if (args.statuses.includes(influencer.status)) {
        influencerUIDs.push(influencerUID);
      }
    });

    const influencers = {};

    Promise.map(influencerUIDs, (influencerUID) => {
      const influencerRef = firebase.influencers().child(influencerUID);
      return influencerRef.once('value', (influencer) => {
        const appInfluencer = {
          influencerUID,
          appliedUIDStatus: campaign.appliedUID[influencerUID].status,
          ...influencer.val(),
        };

        influencers[influencerUID] = appInfluencer;

        dispatch(setInfluencer({
          [influencerUID]: appInfluencer,
        }));
      });
    }, {
      concurrency: 5,
    }).then(() => {
      dispatch(setInfluencerList(influencers));
    }).catch((e) => {
      console.log('Error on getting influencer: ', e);
    }).finally(() => {
      dispatch(receiveRequest());
    });
  });
};
