import React, { Component } from 'react';
import { connect } from 'react-redux';
import PaymentForm from 'components/Common/PaymentForm';

class Subscription extends Component {
  componentWillUpdate(nextProps) {
    const { authUser } = this.props;
    if (!authUser && nextProps.authUser) {
      if (nextProps.authUser.details.stripeActive) {
        nextProps.history.push('/');
      }
    }
  }

  render() {
    const { authUser } = this.props;
    return (
      <div className="mt-8">
        { !!authUser && <PaymentForm authUser={authUser} /> }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authUser: state.entities.session.authUserData,
});

export default connect(mapStateToProps, null)(Subscription);
