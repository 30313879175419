import React, { Component } from 'react';
import HeaderText from 'components/Common/HeaderText';
import RankCircle from 'components/Common/RankCircle';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';

class PerCategory extends Component {
  render() {
    const list = this.props.list.map((l, ndx) => (
      <tr key={ndx}>
        <td><RankCircle text={(ndx + 1)} /></td>
        <td>
          <span style={{ fontWeight: 'bold' }}>{l.label}{l.value && `: `}</span>
          {l.value && <span>{l.value}</span>}
        </td>
      </tr>
    ));

    return (
      <div>
        <HeaderText bold={true}>{this.props.category}</HeaderText>
        {
          this.props.description && <p>{this.props.description}</p>
        }
        <div>
          {
            list.length > 0 &&
            <table>
              <tbody>
                <tr>
                  <td><UpperCaseLabel>rank</UpperCaseLabel></td>
                  <td><UpperCaseLabel>location</UpperCaseLabel></td>
                </tr>
                {list}
              </tbody>
            </table>
          }
        </div>
      </div>
    );
  }
}

export default PerCategory;
