import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import CategoriesSelect from './filters/CategoriesSelect';

class CampaignFilters extends Component {
  render() {
    return (
      <Row style={{ margin: '10px 0px' }}>
        <Col span={6}>
          <CategoriesSelect />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(CampaignFilters);
