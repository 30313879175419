import React, { Component } from 'react'
import ProductForm from 'components/CampaignDashboard/ProductForm'
import { connect } from 'react-redux'
import { fetchCampaignById } from 'actions/campaigns'
import { Spin } from 'antd'

class CampaignEdit extends Component {

  constructor(props) {
    super(props)

    this.state = {
      campaignId: this.props.match.params.campaignId
    }
    this.props.fetchCampaign(this.state.campaignId)
  }

  render() {
    let campaign = this.props.campaigns.byId[this.state.campaignId]
    if ( campaign ) { campaign.uid = this.state.campaignId }
    return (
      <Spin spinning={!campaign}>
        <div style={{ minHeight: '600px' }}>
          {campaign ? <ProductForm campaign={campaign}/> : ''}
        </div>
     </Spin>
    )
  }

}

export const mapStateToProps = (state) => (
  {
    campaigns: state.entities.campaigns,
  }
)

export const mapDispatchToProps = (dispatch) => (
  {
    fetchCampaign: (campaignId) => { dispatch(fetchCampaignById(campaignId)) }
  }
)

export default connect(mapStateToProps, mapDispatchToProps )(CampaignEdit)
