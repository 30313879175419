/* eslint no-console: ["error", { allow: ["log"] }] */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import cheerio from 'cheerio';

const INSTRAM_URL = 'https://www.instagram.com/p/';

class Instagram {
  getAttributes = (postCode, options = {}, callback = null) => (
    axios.get(`${INSTRAM_URL}${postCode}`).then((response) => {
      const $ = cheerio.load(response.data);

      try {
        let instaData = '';

        $('script').each((_, script) => {
          const { children } = script;
          if (children.length > 0) {
            const { data } = children[0];

            if (data.indexOf('window._sharedData =') >= 0) {
              instaData = data;
            }
          }
        });

        const sharedData = JSON.parse(instaData.replace(/^[_a-zA-Z.\s=]*\{/, '{').replace(/\};/, '}'));

        const {
          display_url: displayUrl,
          display_resources: displayResources,
          edge_media_preview_like: mediaLike,
          edge_media_to_comment: mediaComment,
        } = sharedData.entry_data.PostPage[0].graphql.shortcode_media;

        const attributes = {
          likes: parseInt(mediaLike.count, 10),
          comments: parseInt(mediaComment.count, 10),
          lastUpdated: new Date().getTime(),
        };

        if (options.isIncludeImage) {
          attributes.standardResolutionImage = displayUrl;
          // order by width&height asc
          attributes.lowResolutionImage = displayResources[0].src;
        }

        callback(true, { attributes });
      } catch (error) {
        console.log('Error on parsing _sharedData: ', error);
        callback(false, { error });
      }
    }).catch((error) => {
      console.log('Error on scraping instagram: ', error);
      callback(false, { error });
    })
  )
}

export default Instagram;
