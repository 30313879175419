
export const SET_OWNED_CAMPAIGNS = 'SET_OWNED_CAMPAIGNS';
export const SET_OWNED_ARCHIVED_CAMPAIGNS = 'SET_OWNED_ARCHIVED_CAMPAIGNS';

const initialState = {
  ownedCampaigns: [],
  ownedArchivedCampaigns: [],
};

const sideNav = (state = initialState, action) => {
  switch (action.type) {
    case SET_OWNED_CAMPAIGNS:
      return {
        ...state,
        ownedCampaigns: Object.keys(action.payload),
      };
    case SET_OWNED_ARCHIVED_CAMPAIGNS:
      return {
        ...state,
        ownedArchivedCampaigns: Object.keys(action.payload),
      };
    default:
      return state;
  }
};

export default sideNav;
