import {
  fetchRequest,
  receiveRequest,
} from './common/request';

export const ADD_MESSAGES = 'ADD_MESSAGES';

export const MESSAGE_FETCH_REQUEST = 'MESSAGE_FETCH_REQUEST';

export const MESSAGE_RECEIVE_REQUEST = 'MESSAGE_RECEIVE_REQUEST';

const defaultState = {
  byId: {},
  all: [],
  isFetching: false,
};

const addMessages = (state, messages) => ({
  ...state,
  byId: {
    ...state.byId,
    ...messages,
  },
  all: [
    ...new Set(state.all.concat(Object.keys(messages))),
  ],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_MESSAGES:
      return addMessages(state, action.messages);
    case MESSAGE_FETCH_REQUEST:
      return fetchRequest(state);
    case MESSAGE_RECEIVE_REQUEST:
      return receiveRequest(state);

    default:
      return state;
  }
};
