import React, { Component } from 'react';
import { Layout, Button } from 'antd';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import 'styles/base/antd-custom.less';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import trendLogo from 'images/trend@2x.png';
import * as routes from 'constants/routes';
import { withFirebase } from 'lib/Firebase';

const { Content } = Layout;
const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  type: 'brand',
  plan: '',
  error: null,
};

const flStyle = {
  marginTop: '2em',
  fontSize: '9px',
  letterSpacing: '0.2em',
  textTransform: 'uppercase',
  color: '#fff',
};

const SignUpPage = () => (
  <div className="bg-black">
    <div style={{ textAlign: 'center', padding: '4em 0' }}>
      <img
        alt="Trend"
        src={trendLogo}
        width="162"
        height="31"
        style={{ display: 'inline-block' }}
      />
    </div>
    <h1 style={{ textAlign: 'center', color: '#fff' }}>Sign Up</h1>
    <SignUpForm />
  </div>
);

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email, passwordOne, type, plan,
    } = this.state;
    const initBrandData = { credits: 0, standardCredits: 0, email };

    let userId;
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        userId = authUser.user.uid;
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          email,
          type,
        });
      })
      .then(() => this.props.firebase.brandInfo(userId).set(initBrandData))
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(routes.SUBSCRIPTION_PATH);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      email, passwordOne, passwordTwo, plan, error,
    } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || email === '';

    return (
      <div>
        <Layout>
          <Content style={{ padding: 24, background: '#000' }}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={6}>
                <Form layout="vertical" onSubmit={this.onSubmit}>
                  <Form.Item>
                    <label className="trend-label">Email</label>
                    <Input
                      type="text"
                      size="large"
                      onPressEnter={this.onSubmit}
                      name="email"
                      value={email}
                      onChange={this.onChange}
                      className="trend-input"
                    />
                  </Form.Item>
                  <Form.Item>
                    <label className="trend-label">Password</label>
                    <Input
                      type="password"
                      size="large"
                      onPressEnter={this.onSubmit}
                      name="passwordOne"
                      value={passwordOne}
                      onChange={this.onChange}
                      className="trend-input"
                    />
                  </Form.Item>
                  <Form.Item>
                    <label className="trend-label">Confirm Password</label>
                    <Input
                      type="password"
                      size="large"
                      onPressEnter={this.onSubmit}
                      name="passwordTwo"
                      value={passwordTwo}
                      onChange={this.onChange}
                      className="trend-input"
                    />
                  </Form.Item>
                  <Button
                    block
                    size="large"
                    type="submit"
                    disabled={isInvalid}
                    className="trend-btn"
                    onClick={this.onSubmit}
                  >
                    Register
                  </Button>
                  {error && <p>{error.message}</p>}
                  <p style={flStyle} align="middle">
                    Have an account? &nbsp;
                    <Link to="/login">Login</Link>
                  </p>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    );
  }
}

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm };
