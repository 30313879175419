import { SET_CAMPAIGN_LIST_CATEGORY } from 'reducers/entities/campaigns'

let initialState = {
  category: ''
}

function campaignList(state = initialState, action) {
  switch(action.type) {
    case SET_CAMPAIGN_LIST_CATEGORY:
      return { ...state, category: action.payload }

    default:
      return state;
  }
}

export default campaignList;
