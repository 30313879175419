import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthorization } from 'lib/Session';

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  authUser,
  ...rest
}) => {
  const renderCompoent = (props) => {
    if (Layout) {
      return (
        <Layout authUser={authUser}>
          <Component {...props} />
        </Layout>
      );
    }

    return <Component authUser={authUser} {...props} />;
  };

  return <Route {...rest} render={renderCompoent} />;
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(PrivateRoute);
