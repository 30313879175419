import { SET_CAMPAIGN_RECENT_POST } from 'reducers/ui/campaignSummary';

let initialState = {
  campaignPosts: {
    //[campaignId]: [...postIds]
  },
  postUser: {
    //[postId]: username
  }
}

const recentPosts = (state = initialState, action) => {
  switch(action.type) {
    case SET_CAMPAIGN_RECENT_POST: {
      let { campaignId, postIds, username } = action.payload
      let postUser = {}
      postIds.map((id) => postUser[id] = username )

      return {
        ...state,
        campaignPosts: {
          ...state.campaignPosts,
          [campaignId]: [
            ...new Set(state.campaignPosts[campaignId] ? [...state.campaignPosts[campaignId], ...postIds] : postIds)
          ]
        },
        postUser: {
          ...state.postUser,
          ...postUser
        }
      }
    }

    default:
      return state;
  }
}

export default recentPosts;
