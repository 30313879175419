export const SET_POSTS_ANALYTICS = 'SET_POSTS_ANALYTICS';
export const RESET_POSTS_ANALYTICS = 'RESET_POSTS_ANALYTICS';

const defaultState = [];

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_POSTS_ANALYTICS:
      return [...Object.keys(action.posts)];

    case RESET_POSTS_ANALYTICS:
      return defaultState;

    default:
      return state;
  }
}
