import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { UserToken } from 'lib/Firebase';
import Routes from 'api/routes';

class EmailReportButton extends Component {
  state = {
    tokenId: null,
  }

  async componentDidMount() {
    const { tokenId } = await UserToken();
    this.setState(() => ({ tokenId }));
  }

  render() {
    const { tokenId } = this.state;
    const { match } = this.props;

    return (
      <a
        href={`${Routes.postReportCsv}?campaignUID=${match.params.campaignId}&tokenId=${tokenId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button className="trend-btn secondary" style={{ marginTop: '8px' }}>
          <Icon type="mail" />
          Email CSV Report And Post Links
        </Button>
      </a>
    );
  }
}

export default compose(withRouter)(EmailReportButton);
