import { POST_DETAIL_STATUS } from 'constants/posts';
import _ from 'lodash';

export const filteredByApproved = (ids = [], posts) => (
  _.remove([...ids], (id) => {
    const { details } = posts.byId[id];
    return details.status === POST_DETAIL_STATUS.approved;
  })
);

export const sortPosts = (ids, posts) => (
  ids.sort((a, b) => {
    const postA = new Date(posts[a].details.createdTime);
    const postB = new Date(posts[b].details.createdTime);
    if (postA < postB) {
      return 1;
    }
    if (postA > postB) {
      return -1;
    }
    return 0;
  })
);
