import React from 'react';
import {Avatar, Card, Row, Col } from 'antd';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';

const { Meta } = Card;

const PostItem = ({postImg, infImg, infName, brandName, likes, comments, ...other}) => {
  return (
    <Card
      cover={<img alt="" src={postImg} />}
      style={{ marginBottom: '20px' }}
      className='post-card trend-post-card'
    >
       <Meta
          avatar={<Avatar src={infImg} size='large'/>}
          title={infName}
          description={`for ${brandName}`}
       />

      <div>
        <Row className='mt-3'>
          <Col span={8}>
            <h2 className='m-0'> { likes } </h2>
            <UpperCaseLabel>Likes</UpperCaseLabel>
          </Col>
          <Col span={8}>
            <h2 className='m-0'> { comments } </h2>
            <UpperCaseLabel>Comments</UpperCaseLabel>
          </Col>
          <Col span={8}>
            <h2 className='m-0'> { likes * 13 } </h2>
            <UpperCaseLabel>Impressions</UpperCaseLabel>
          </Col>
        </Row>
      </div>
    </Card>
  )
}

export default PostItem;
