import React from 'react';
import { Avatar } from 'antd';
import { ReactComponent as IconVip } from 'images/icon-vip-profile.svg';

const InfluencerAvatar = influencer => (
  <div>
    <a
      href={`https://www.instagram.com/${influencer.instagramID}/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Avatar src={influencer.image} size={50} style={{ float: 'left' }} />
    </a>
    <div style={{ position: 'relative', float: 'left', marginLeft: '10px' }}>
      <div style={{ paddingTop: '5px', fontFamily: 'truenoextrabold' }}>
        @
        {influencer.username}
      </div>
      <div style={{ fontSize: '11px', color: '#5b6572' }}>
        {influencer.state}
      </div>
      {influencer.isVIP && (
        <IconVip
          style={{
            position: 'absolute',
            bottom: '-15px',
            left: '-25px',
          }}
        />
      )}
    </div>
  </div>
);

export default InfluencerAvatar;
