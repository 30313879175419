import {
  CHANGE_MODE_VIEW,
  FILTER_FAVORITE
} from 'reducers/ui/creativeAssets';

export const changeModeView = (view) => ({
  type: CHANGE_MODE_VIEW,
  view
});

export const filterFavorite = (isFavorite) => ({
  type: FILTER_FAVORITE,
  isFavorite
})
