import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import ProfileImage from 'components/Common/ProfileImage';
import Logo from 'components/Common/Logo';
import { fetchBrand } from 'actions/brands';
import SideMenus from './SideNav/SideMenus';
import SubscriptionType from './SideNav/SubscriptionType';
import ContentCredit from './SideNav/ContentCredit';

const { Sider } = Layout;

class SiderLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brandUID: props.authUser.uid,
    };

    props.fetchBrand(props.authUser.uid);
  }

  render() {
    const { brandUID } = this.state;
    const {
      brands,
      children,
    } = this.props;

    const brand = (brands[brandUID] || {}).details || {};

    return (
      <div>
        <Layout>
          <Sider width={210} style={{ overflow: 'hidden', height: '100vh', position: 'fixed', zIndex:'1', left: '0', minHeight: '100vh' }} breakpoint="lg">
            <Logo />
            <ProfileImage src={brand.logo} />
            <SubscriptionType isVIP={brand.isVIP} />
            <ContentCredit
              credits={(brand.credits || 0)}
              standardCredits={(brand.standardCredits || 0)}
            />
            <SideMenus />
          </Sider>

          <Layout style={{marginLeft: '210px'}}>
            {children}
          </Layout>
        </Layout>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  brands: state.entities.brands.byId,
});

export const mapDispatchToProps = dispatch => ({
  fetchBrand: brandUID => dispatch(fetchBrand(brandUID)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SiderLayout);
