export const SET_CAMPAIGN_SUMMARY = 'SET_CAMPAIGN_SUMMARY'
export const RESET_CAMPAIGN_SUMMARY = 'RESET_CAMPAIGN_SUMMARY'
export const SET_CAMPAIGN_RECENT_POST = 'SET_CAMPAIGN_RECENT_POST'

const initialState = [
  //# postIds
]

const campaignSummary = (state = initialState, action) => {
  switch(action.type) {
    case SET_CAMPAIGN_SUMMARY: {
      let { postIds } = action.payload
      return [...new Set(state.concat(postIds))]
    }

    case RESET_CAMPAIGN_SUMMARY: {
      return []
    }

    default:
      return state;
  }
}

export default campaignSummary;
