import { POST_DETAIL_STATUS } from 'constants/posts';

export const filterPosts = (campaignUID, campaign, influencers, influencerUIDs) => {
  let posts = {
    awaiting: {},
    needsApproval: {},
    approved: {}
  };

  if (!campaign.campaignUID) {
    return posts;
  }

  influencerUIDs.forEach((influencerUID) => {
    const influencerCampaign = influencers[influencerUID].partnerships.products[campaignUID];

    if (!influencerCampaign || !influencerCampaign.posts) {
      return;
    }

    for(let [postUID, post] of Object.entries(influencerCampaign.posts)) {
      post = {
        postUID,
        influencerUID,
        ...post.details
      };

      if (post.status === POST_DETAIL_STATUS.approved) {
        posts.approved[postUID] = post;
      } else if (post.status === POST_DETAIL_STATUS.submitted) {
        posts.needsApproval[postUID] = post;
      } else {
        posts.awaiting[postUID] = post;
      }
    }
  });

  return posts;
}
