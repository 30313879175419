import React, { Component } from 'react';
import { Divider, Row, Button, Col, Form, Card, Input, Switch, Layout, message, Spin } from 'antd';
import { compose } from 'recompose';
import FileUploader from 'react-firebase-file-uploader';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import { AuthUserContext, withAuthorization } from 'lib/Session';

const { TextArea } = Input;

const INITIAL_STATE = {
  name: '',
  storeImage: '',
  description: '',
  isUploading: false,
  progress: 0,
  error: null,
  loaded: false,
};

class StoreEditFormBase extends Component {
  constructor(props){
    super(props)
    this.state = { ...INITIAL_STATE };
  }
  componentDidMount = () => {
    let self = this;
    this.props.firebase.brand(this.props.authUser.uid).on("value", snapshot => {
      self.setState({
        name: snapshot.val().details.name,
        storeImage: snapshot.val().details.storeImage,
        description: snapshot.val().details.description,
        isPushNotificationsEnabled: snapshot.val().details.isPushNotificationsEnabled,
        loaded: true
      });
    })
  }

  handleUploadStart = () => this.setState({isUploading: true, progress: 0});
  handleProgress = (progress) => this.setState({progress});
  handleUploadError = (error) => {
    this.setState({isUploading: false});
  }
  handleUploadSuccess = (filename) => {
    this.setState({progress: 100, isUploading: false});
    this.props.firebase.storage.ref('images').child(filename).getDownloadURL().then(url => this.setState({storeImage: url}));
  };

  onNotificationSwitch = value => {
    this.props.firebase.brandInfo(this.props.authUser.uid)
    .update({
      isPushNotificationsEnabled: value
    })
    .catch(error => {
      this.setState({ error });
    });
  }

  onSuccess = () => {
    message.success('Settings Saved');
  };

  onSubmit = event => {
    const { name, description, storeImage } = this.state;
    this.props.firebase.brandInfo(this.props.authUser.uid)
    .update({
      name: name,
      description: description,
      storeImage: storeImage,
      logo: storeImage,
    })
    .then(() => {
      this.onSuccess();
    })
    .catch(error => {
      this.setState({ error });
    });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render(){
    const { name, description, storeImage, isPushNotificationsEnabled, error, loaded } = this.state;
    const isInvalid = name === '' || description === '' || storeImage === '';

    return (
      <div style={{background: '#000 !important'}}>
        <Row type="flex" justify="space-around">
          <div className="min-layout-content small">
            <Form layout="vertical" onSubmit={this.onSubmit} className="product-form pb-8">
              <Form.Item>
                <div className="p-relative product-uploader" align="center">
                  {this.state.storeImage && <img src={this.state.storeImage} alt="" className="product-image" />}
                  {this.state.isUploading &&
                    <p>Progress: {this.state.progress}</p>
                  }
                  <label className="ant-btn trend-btn edit-btn">
                    {this.state.storeImage ? <span>Change Photo</span> : <span>Add Company Store Image</span>}
                    <FileUploader
                      hidden
                      accept="image/*"
                      storageRef={this.props.firebase.storage.ref('images')}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                      onProgress={this.handleProgress}
                    />
                  </label>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="ant-form-item-label">
                  <label>Notifications</label>
                </div>
                <p>{(isPushNotificationsEnabled != null) ? <Switch defaultChecked={isPushNotificationsEnabled} onChange={this.onNotificationSwitch} /> : null}</p>
              </Form.Item>
              <Form.Item>
                <div className="ant-form-item-label">
                  <label>Company Name</label>
                </div>
                <Input type="text" size="large" name="name" value={name} onChange={this.onChange} style={{marginTop: '5px'}} className="trend-input" />
              </Form.Item>
              <Form.Item>
                <div className="ant-form-item-label">
                  <label>Company Description</label>
                </div>
                <TextArea rows={8} size="large" name="description" value={description} style={{marginTop: '5px'}} onChange={this.onChange} className="trend-textarea"/>
              </Form.Item>
              <div align="center">
                <Button right="true" size="large" type="submit" className="trend-btn product-btn" onClick={this.onSubmit}>Save Updates</Button>
              </div>
              {error && <p>{error.message}</p>}
            </Form>
          </div>
        </Row>
      </div>
    )
  }
}

const EditStore = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <StoreEditPage authUser={authUser}/>
    )}
  </AuthUserContext.Consumer>
);

const StoreEditPage = compose(
  withRouter,
  withFirebase
)(StoreEditFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(EditStore);
