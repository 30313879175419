import { combineReducers } from 'redux';
import users from './users';
import brands from './brands';
import campaigns from './campaigns';
import posts from './posts';
import categories from './categories';
import influencers from './influencers';
import session from './session';
import messages from './messages';
import chat_messages from './chat_messages';

export default combineReducers({
  brands,
  campaigns,
  posts,
  categories,
  messages,
  influencers,
  chat_messages,
  session,
  users,
});
