export const SHOW_SHIPPING_MODAL_FORM = "SHOW_SHIPPING_MODAL_FORM"
export const HIDE_SHIPPING_MODAL_FORM = "HIDE_SHIPPING_MODAL_FORM"
export const SET_SHIPPING_MODAL_FORM = "SET_SHIPPING_MODAL_FORM"
export const SUBMIT_SHIPPING_FORM = "SUBMIT_SHIPPING_FORM"
export const SUBMITTED_SHIPPING_FORM = "SUBMITTED_SHIPPING_FORM"
export const UPDATE_DELIVER_DETAILS = "UPDATE_DELIVER_DETAILS"
export const RECEIVE_DELIVERY_DETAILS = "RECEIVE_DELIVERY_DETAILS"
export const REQUEST_DELIVERY_DETAILS = "REQUEST_DELIVERY_DETAILS"

const initialState = {
  productId: '',
  influencerId: '',
  show: false,
  submitting: false,
  delivery: {
    fetching: false,
    shipDate: '',
    estimatedDeliveryDate: '',
    actualDeliveryDate: '',
    events: []
  }
}

const shippingFormModal = (state = initialState, action) => {
  switch(action.type) {
    case SHOW_SHIPPING_MODAL_FORM:
      const { productId, influencerId } = action.payload
      return { ...state, show: true, productId, influencerId };

    case HIDE_SHIPPING_MODAL_FORM:
      return { ...state, show: false };
    case SET_SHIPPING_MODAL_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload
        }
      }

    case SUBMIT_SHIPPING_FORM: return { ...state, submitting: true }
    case SUBMITTED_SHIPPING_FORM: return { ...state, submitting: false }
    case REQUEST_DELIVERY_DETAILS:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          fetching: true
        }
      }

    case RECEIVE_DELIVERY_DETAILS:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          fetching: false
        }
      }

    case UPDATE_DELIVER_DETAILS:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          ...action.payload
        }
      }

    default: return state;
  }
}

export default shippingFormModal;
