import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import app from 'firebase/app';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.app = app;
  }

  doCreateUserWithEmailAndPassword = (email, password) => (
    this.auth.createUserWithEmailAndPassword(email, password)
  );

  doSignInWithEmailAndPassword = (email, password) => (
    this.auth.signInWithEmailAndPassword(email, password)
  );

  doSignOut = (event) => {
    event.preventDefault();
    this.auth.signOut();
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  user = uid => this.db.ref(`users/${uid}`);

  brand = uid => this.db.ref(`brands/${uid}`);

  brands = () => this.db.ref('brands');

  brandInfo = uid => this.db.ref(`brands/${uid}/details`);

  users = () => this.db.ref('users');

  posts = () => this.db.ref('posts');

  influencer = uid => this.db.ref(`influencers/${uid}`);

  influencers = () => this.db.ref('influencers');

  influencerProduct = (influencerUID, campaignUID) => this.db.ref(`influencers/${influencerUID}/partnerships/products/${campaignUID}`);

  message = messageId => this.db.ref(`messages/${messageId}`);

  messages = () => this.db.ref('messages');

  chatMessages = messageId => this.db.ref(`messages/${messageId}/messages`);

  brandMessages = brandID => this.db.ref(`brands/${brandID}/messages`);

  influencers = () => this.db.ref('influencers');

  adminCategories = () => this.db.ref('admin/categories');

  activeProducts = () => this.db.ref('products/active');

  activeProduct = uid => this.db.ref(`products/active/${uid}`);

  completedProduct = campaignUID => this.db.ref(`products/completed/${campaignUID}`);

  completedProducts = () => this.db.ref('products/completed')

  campaignPosts = uid => this.db.ref('influencers').orderByChild(`partnerships/products/${uid}/posts`);

  campaignShipments = uid => this.db.ref('influencers').orderByChild(`partnerships/products/${uid}`);

  paypalRef = () => this.db.ref('admin/payPal');

  payoutsRef = () => this.db.ref('admin/payouts');
}

export default Firebase;
