import {
  RECEIVE_CAMPAIGN_HISTORIES,
  HISTORY_FETCH_REQUEST,
  HISTORY_RECEIVE_REQUEST
} from 'reducers/ui/campaignHistory'
import { fetchBrandCompletedCampaignsApi } from 'api/campaigns'
import { receiveCampaigns } from 'actions/campaigns'

export const receiveCampaignHistories = (payload) => ({
  type: RECEIVE_CAMPAIGN_HISTORIES,
  payload
})

export const historyFetchRequest = () => ({
  type: HISTORY_FETCH_REQUEST
})
export const historyReceiveRequest = () => ({
  type: HISTORY_RECEIVE_REQUEST
})

export const fetchCampaignHistories = (brandUID) => (dispatch) => {
  dispatch(historyFetchRequest())
  fetchBrandCompletedCampaignsApi(brandUID, (campaigns) => {

    dispatch(receiveCampaigns(campaigns))
    dispatch(receiveCampaignHistories(campaigns))
    dispatch(historyReceiveRequest())
  } )
}
