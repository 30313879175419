import {
  fetchRequest,
  receiveRequest
} from './common/request';

export const INFLUENCER_FETCH_REQUEST = 'INFLUENCER_FETCH_REQUEST';

export const INFLUENCER_RECEIVE_REQUEST = 'INFLUENCER_RECEIVE_REQUEST';

export const SET_INFLUENCERS = 'SET_INFLUENCERS';

export const SET_INFLUENCER = 'SET_INFLUENCER';

export const UPDATE_INFLUENCER = 'UPDATE_INFLUENCER';

export const UPDATE_INFLUENCER_DETAILS = 'UPDATE_INFLUENCER_DETAILS';

export const UPDATE_INFLUENCER_POST = 'UPDATE_INFLUENCER_POST';

const defaultState = {
  byId: {
    // 1: {
    // ... attr
    // }
  },
  all: [
    // ids
    // 1,2,3
  ],
  isFetching: false
};

const addInfluencers = (state, influencers) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      ...influencers,
    },
    all: [
      ...new Set(state.all.concat(Object.keys(influencers)))
    ]
  }
}

const addInfluencer = (state, influencer) => ({
  ...state,
  byId: {
    ...state.byId,
    ...influencer
  },
  all: [
    ...new Set(state.all.concat(Object.keys(influencer)))
  ]
});

const updateInfluencer = (state, influencerUID, influencer) => {
  return {
    ...state,
    byId: {
      ...state.byId,
      [influencerUID]: {
        ...state.byId[influencerUID],
        ...influencer
      }
    }
  };
}

const updateInfluencerDetails = (state, influencerUID, influencerDetails) => {
  const influencer = {
    ...state.byId[influencerUID],
    details: {
      ...state.byId[influencerUID].details,
      ...influencerDetails
    },
  };

  return {
    ...state,
    byId: {
      ...state.byId,
      [influencerUID]: influencer
    }
  };
}

const updateInfluencerPost = (state, influencerUID, campaignUID, campaignStatus, postUID, postDetails) => {
  const campaignPost = state.byId[influencerUID].partnerships.products[campaignUID];
  let post = {};

  if (postUID) {
    post[postUID] = {
      details: {
        ...campaignPost.posts[postUID].details,
        ...(postDetails || {})
      }
    };
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      [influencerUID]: {
        ...state.byId[influencerUID],
        partnerships: {
          products: {
            ...state.byId[influencerUID].partnerships.products,
            [campaignUID]: {
              ...state.byId[influencerUID].partnerships.products[campaignUID],
              posts: {
                ...campaignPost.posts,
                ...post
              }, // [posts]
              status: (campaignStatus || campaignPost.status)
            } // [campaignUID]
          } // products
        } // partnerships
      } // [influencerUID]
    } // byId
  };
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_INFLUENCERS:
      return addInfluencers(state, action.influencers);

    case SET_INFLUENCER:
      return addInfluencer(state, action.influencer);

    case UPDATE_INFLUENCER:
      return updateInfluencer(state, action.influencerUID, action.influencer);

    case UPDATE_INFLUENCER_DETAILS:
      return updateInfluencerDetails(state, action.influencerUID, action.influencerDetails);

    case UPDATE_INFLUENCER_POST:
      return updateInfluencerPost(
        state,
        action.influencerUID,
        action.campaignUID,
        action.campaignStatus,
        action.postUID,
        action.postDetails
      );

    case INFLUENCER_FETCH_REQUEST:
      return fetchRequest(state);

    case INFLUENCER_RECEIVE_REQUEST:
      return receiveRequest(state);

    default:
      return state;
  }
};
