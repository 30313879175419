import React, { Component } from 'react';
import {
  Row,
  Col,
  Slider,
  Button,
  Modal,
  notification,
  Divider,
} from 'antd';
import { addCredits } from 'actions/ui/brandAccount';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import CurrencyFormat from 'react-currency-format';


const PRICING = {
  standard: 100,
  vip: 200,
};

class AddCredits extends Component {
  state = {
    selectedStandardCredits: 0,
    selectedVipCredits: 0,
    showConfirmModal: false,
    submitting: false,
  }

  onStandardChange = (value) => {
    this.setState({ selectedStandardCredits: value });
  }

  onVipsChange = (value) => {
    this.setState({ selectedVipCredits: value });
  }

  totalCredits = () => {
    const { selectedStandardCredits, selectedVipCredits } = this.state;
    return (selectedStandardCredits + selectedVipCredits);
  }

  toggleModal = (visisble) => {
    this.setState({ showConfirmModal: visisble });
  }

  addCredits = () => {
    const { selectedStandardCredits, selectedVipCredits } = this.state;
    const { authUser, payCredits, history } = this.props;
    this.setState({ submitting: true });

    payCredits(authUser.uid, selectedStandardCredits, selectedVipCredits, (response, error) => {
      this.setState({ submitting: false });
      this.toggleModal(false);
      if (error) {
        notification.error({ message: error.message, duration: 0 });
      } else {
        history.push('/');
        notification.success({ message: 'Successfully purchased credits.', duration: 0 });
      }
    });
  }

  totalAmmount = () => {
    const { selectedStandardCredits, selectedVipCredits } = this.state;
    const standardPrice = selectedStandardCredits * PRICING.standard;
    const vipPrice = selectedVipCredits * PRICING.vip;

    return (standardPrice + vipPrice);
  }

  sliderMarks = () => {
    const marks = {};
    for (let i = 0; i <= 50; i += 5) {
      marks[i] = i.toString();
    }
    return marks;
  }

  render() {
    const {
      selectedStandardCredits,
      selectedVipCredits,
      showConfirmModal,
      submitting,
    } = this.state;

    return (
      <Row type="flex" align="center" gutter={42} className="add-credits-component">
        <Col span={16} className="text-white" >
          <Col span={15}>
            <div>
              <h1 className="text-white">Standard Credits</h1>
              <p className="text-gray-darkest">
                If your looking to go over your usual number of standard influencers for the month,
                add as many credits as you'd like with the slider below. You will be charged now for
                the posts you add to your account but your monthly plan will remain the same.
                If youd like to change your monthly plan, you can do that below.
              </p>

              <h1 className="text-white mb-0">{ selectedStandardCredits }</h1>
              <h4 className="text-white">Additional Standard Credits</h4>

              <Slider
                max={50}
                tooltipVisible={false}
                marks={this.sliderMarks()}
                defaultValue={selectedStandardCredits}
                onChange={this.onStandardChange}
                value={typeof selectedStandardCredits === 'number' ? selectedStandardCredits : 0}
              />
            </div>

            <div style={{ marginTop: '100px' }}>
              <h1 className="text-white">VIP Credits</h1>
              <p className="text-gray-darkest">
                VIP influencers are our best of the best influencers, They have a combination of amazing
                content creation skills, a very engaged audience, and sizeable following.
                You can always add more VIP influencer credits to your plan from the dashboard.
              </p>

              <h1 className="text-white mb-0">{ selectedVipCredits }</h1>
              <h4 className="text-white">Additional VIP Credits</h4>
              <Slider
                max={50}
                tooltipVisible={false}
                marks={this.sliderMarks()}
                defaultValue={selectedVipCredits}
                onChange={this.onVipsChange}
                value={selectedVipCredits}
              />
            </div>
          </Col>
          <Col span={9}>
            <h2 style={{ textAlign: 'center' }} className="text-white">You've Selected</h2>
            <Divider className="bg-gray-darkest"/>
            <Row className="mt-8">
              <Col span={12}>
                <h3 className="text-white">{ selectedStandardCredits }</h3>
                <p className="text-gray-darkest size-12px">Standard Credits</p>
              </Col>
              <Col span={12} align="right">
                <h3 className="text-white">{ selectedStandardCredits * PRICING.standard }</h3>
                <p className="text-gray-darkest size-12px">Saving 5$/Post</p>
              </Col>
            </Row>

            <Row className="mt-8">
              <Col span={12}>
                <h3 className="text-white">{ selectedVipCredits }</h3>
                <p className="text-gray-darkest size-12px">VIP Credits</p>
              </Col>
              <Col span={12} align="right">
                <h3 className="text-white">{ selectedVipCredits * PRICING.vip }</h3>
                <p className="text-gray-darkest size-12px">Saving 15$/Post</p>
              </Col>
            </Row>
            <Divider className="bg-gray-darkest" />
            <Row>
              <Col span={12}><h4 className="text-white pt-2">TOTAL COST</h4></Col>
              <Col span={12} align="right">
                <h1 className="text-white type-light">
                  <CurrencyFormat value={this.totalAmmount()} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
                </h1>
              </Col>
            </Row>

            <Button
              onClick={() => { this.toggleModal(true); }}
              disabled={selectedStandardCredits + selectedVipCredits === 0}
              block
              className="trend-btn huge-btn mt-4"
            >
              {`Buy ${this.totalCredits()} Credits`}
            </Button>
            <Modal
              title="You will purchase"
              visible={showConfirmModal}
              onOk={this.addCredits}
              onCancel={() => { this.toggleModal(false); }}
              okText="Confirm"
              confirmLoading={submitting}
            >
              <Row>
                <Col span={12}>
                  <p>{`${selectedStandardCredits} Standard Credits`}</p>
                </Col>
                <Col span={12} align="right">
                  <span>{selectedStandardCredits * PRICING.standard}</span>
                </Col>
              </Row>


              <Row>
                <Col span={12}>
                  <p>{`${selectedVipCredits} VIP Credits`}</p>
                </Col>
                <Col span={12} align="right">
                  <span>{selectedVipCredits * PRICING.vip}</span>
                </Col>
              </Row>

              <Row>
                <Col span={12}><p> TOTAL </p></Col>
                <Col span={12} align="right">
                  <strong> {`$${this.totalAmmount()}`} </strong>
                </Col>
              </Row>
            </Modal>
          </Col>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  payCredits: (uid, selectedStandardCredits, selectedVipCredits, onComplete) => {
    dispatch(addCredits(uid, selectedStandardCredits, selectedVipCredits, onComplete));
  },
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(AddCredits);
