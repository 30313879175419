/* eslint no-console: ["error", { allow: ["error"] }] */
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import moment from 'moment';
import { saveAs } from 'file-saver';

class PostImages {
  downloadImage = (link, fileName, callback) => {
    if (!link) {
      callback('No HD image yet!');
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', link);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      saveAs(xhr.response, fileName, { type: 'blob' });
    };
    xhr.onerror = () => {
      callback('Could not download image');
    };
    xhr.send();
  }

  getFullResImages = (posts) => {
    const fullResImages = [];
    let count = 0;
    Object.values(posts).forEach(({ details: postDetails }) => {
      if (postDetails.fullResImage) {
        count += 1;

        fullResImages.push({
          link: postDetails.fullResImage,
          fileName: `${postDetails.code}-${count}`,
        });
      }
    });

    return fullResImages;
  }

  downloadAllHD = (folderName, posts, callback) => {
    const jszip = new JSZip();
    const fullResImages = this.getFullResImages(posts);

    let count = 0;

    fullResImages.forEach((post) => {
      JSZipUtils.getBinaryContent(post.link, (error, data) => {
        if (!error) {
          jszip.file(`${post.fileName}.jpg`, data, { binary: true });

          if (count === (fullResImages.length - 1)) {
            jszip.generateAsync({ type: 'blob' }).then((content) => {
              saveAs(content, `${folderName} ${moment().format()}.zip`);
            });

            callback();
          }
        }
        count += 1;
      });
    });
  }
}

export default PostImages;
