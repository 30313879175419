import React, { Component } from 'react';
import { Button, Row, Col, message } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
} from 'react-stripe-elements';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createSubscription } from 'actions/ui/checkoutForm';
import { updateLoggedUser } from 'actions/session';
import * as routes from 'constants/routes';

const inputStyles = {
  fontSize: '18px',
  color: '#FFB917',
  fontFamily: 'TruenoRegular,Arial,sans-serif',
  paddingBottom: '5px',
  '::placeholder': {
    color: '#3A3A3A',
  },
};

class CheckoutForm extends Component {
  state = {
    paying: false,
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    const {
      plan, stripe, currentUser, paySubscription, history, updateSession,
    } = this.props;
    this.setState({ paying: true });

    const tokenResponse = await stripe.createToken({ name: currentUser.email });

    if (tokenResponse.error) {
      this.setState({ paying: false });
      message.error(tokenResponse.error.message);
    } else {
      const { token } = tokenResponse;
      paySubscription(token.id, plan, currentUser.uid, (brand, err) => {
        this.setState({ paying: false });

        if (err) {
          message.error(err.message);
        } else {
          const { details } = currentUser;
          details.stripeActive = true;
          updateSession({ details });
          history.push(routes.BRAND_EDIT_PATH);
        }
      });
    }
  }

  render() {
    const { amount } = this.props;
    const { paying } = this.state;
    return (
      <form onSubmit={this.handleSubmit} className="text-white checkout dark-theme white">
        <label className="text-gray-darkest">
          Card Number
          <CardNumberElement
            placeholder="Enter your credit card number"
            style={{
              base: inputStyles,
            }}
          />
        </label>
        <Row gutter={28}>
          <Col span={12}>
            <label className="text-gray-darkest">
              Expiration Date
              <CardExpiryElement style={{ base: inputStyles }} />
            </label>
          </Col>
          <Col span={12}>
            <label className="text-gray-darkest">
              CVC
              <CardCVCElement style={{ base: inputStyles }} />
            </label>
          </Col>
        </Row>
        <label className="text-gray-darkest">
          Postal Code
          <PostalCodeElement
            style={{
              base: inputStyles,
            }}
          />
        </label >
        <Row className="mt-6">
          <Button block htmlType="submit" size="large" className="trend-btn huge-btn" loading={paying}>
            {`Pay $${amount}`}
          </Button>
        </Row>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.entities.session.authUserData,
});

const mapDispatchToProps = dispatch => ({
  paySubscription: (token, plan, uid, onComplete) => { dispatch(createSubscription(token, plan, uid, onComplete)); },
  updateSession: (authUserData) => { dispatch(updateLoggedUser(authUserData)); },
});
export default compose(
  withRouter,
  injectStripe,
  connect(mapStateToProps, mapDispatchToProps),
)(CheckoutForm);
