import React, { Component } from 'react';
import { withFirebase } from 'lib/Firebase';
import { getAllApprovedPosts, clearAllApprovedPosts } from 'actions/ui/postList';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  Layout, Row, Col, Spin,
} from 'antd';
import PostItem from 'components/PostList/PostItem';
import { sortPosts } from 'selectors/posts';

const { Content } = Layout;

class PostList extends Component {
  componentDidMount() {
    const { getAllApprovedPosts: getPosts } = this.props;
    getPosts();
  }

  componentWillUnmount() {
    const { clearAllApprovedPosts: clearPosts } = this.props;
    clearPosts();
  }

  render() {
    const postsById = this.props.posts.byId
    const influencersById = this.props.influencers.byId
    const brandById = this.props.brands.byId
    const postsItems = (
      this.props.approvedPosts.map( key => {
        let post = postsById[key]
        let brand = brandById[post.brandUID]
        let influencer = influencersById[post.influencerUID]
        return (
          <Col span={8} key={key} >
            <PostItem
              postImg={ post.details.standardResolutionImage }
              infImg={ influencer.details.image }
              infName={ influencer.details.name }
              brandName={ brand ? brand.details.name : '' }
              likes={post.details.likes}
              comments={post.details.comments}
            />
          </Col>
        )
      } )
    )

    return (
      <Content style={{ background: '#fff', minHeight: 600 }} className="content-padding">
        <Row type="flex" justify="space-around" gutter={16}>
          <div className="min-layout-content">
            <h1>Posts</h1>
            <Spin spinning={this.props.posts.isFetching}>{ postsItems }</Spin>
          </div>
        </Row>
      </Content>
    )
  }

}

const mapStateToProps = (state) => ({
  posts: state.entities.posts,
  approvedPosts: sortPosts(state.ui.postList.approvedPosts, state.entities.posts.byId),
  influencers: state.entities.influencers,
  brands: state.entities.brands
})

const mapDispatchToProps = (dispatch) => ({
  getAllApprovedPosts: () => dispatch(getAllApprovedPosts()),
  clearAllApprovedPosts: () => dispatch(clearAllApprovedPosts())
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase
)(PostList);
