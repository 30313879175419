import { firebase } from 'lib/Firebase';

import {
  RECEIVE_CHAT_MESSAGES,
  CHAT_MESSAGE_FETCH_REQUEST,
  CHAT_MESSAGE_RECEIVE_REQUEST
} from 'reducers/entities/chat_messages'

export const fetchRequest = () => ({ type: CHAT_MESSAGE_FETCH_REQUEST });

export const receiveRequest = () => ({ type: CHAT_MESSAGE_RECEIVE_REQUEST });

export const receiveChatMessages = (chat_messages) => ({
  type: RECEIVE_CHAT_MESSAGES,
  chat_messages
});

export const fetchChatMessages = (messageID, brandID) => (dispatch) => {
  dispatch(fetchRequest());
  firebase.message(messageID).on('value', (snapshot) => {
    if (!snapshot.val()) {
      dispatch(receiveRequest());
      return;
    }
    snapshot.ref.child(`users/${brandID}`).update({hasUnread: false})

    dispatch(receiveChatMessages(snapshot.val()))
  }, (e)=>{
    console.log('Error on fetching messages: ', e);
    dispatch(receiveRequest());
  });
}

export const sendChatMessage = (messageId, influencerUID, data) => (dispatch) => {
  dispatch(fetchRequest());
  firebase.chatMessages(messageId).push(data)
  firebase.message(messageId).child(`users/${influencerUID}`).update({hasUnread: true})
  dispatch(receiveRequest());
}