export const CURRENT_CAMPAIGN = 'CURRENT_CAMPAIGN';
export const SET_APPROVED_INFLUENCER_COUNT = 'SET_APPROVED_INFLUENCER_COUNT';
export const SET_APPLIED_INFLUENCER_COUNT = 'SET_APPLIED_INFLUENCER_COUNT';
export const RESET_CAMPAIGN_DASHBOARD = 'RESET_CAMPAIGN_DASHBOARD';
export const SET_NEW_MESSAGES_COUNT = 'SET_NEW_MESSAGES_COUNT';

const defaultState = {
  currentCampaign: {},
  approvedInfluencerCount: 0,
  appliedInfluencerCount: 0,
  newMessagesCount: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CURRENT_CAMPAIGN:
      return {
        ...state,
        currentCampaign: action.campaign,
      };
    case SET_APPROVED_INFLUENCER_COUNT:
      return {
        ...state,
        approvedInfluencerCount: action.influencers.length,
      };
    case SET_APPLIED_INFLUENCER_COUNT:
      return {
        ...state,
        appliedInfluencerCount: action.influencers.length,
      };
    case SET_NEW_MESSAGES_COUNT:
      return {
        ...state,
        newMessagesCount: action.messages.length,
      };
    case RESET_CAMPAIGN_DASHBOARD:
      return defaultState;
    default:
      return state;
  }
};
