export const selectCampaignById = (state, campaignId) => {
  const campaign = state.entities.campaigns.byId[campaignId];
  return campaign;
};

export const unListed = (list, campaignEntity) => {
  let unlisted = [];
  unlisted = list.filter(id => !campaignEntity.byId[id].isListed);
  return unlisted;
};
