import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import CustomEmpty from 'components/Common/CustomEmpty';
import {
  Table,
  Button,
  Row,
  Radio,
  Icon,
  Modal,
  Input,
  Dropdown,
  Menu,
  Alert,
  notification,
} from 'antd';
import { withFirebase } from 'lib/Firebase';
import { rejectInfluencer } from 'actions/ui/influencersAction';
import { REJECT_REASONS } from 'constants/influencers';
import { POST_DETAIL_STATUS } from 'constants/posts';
import sortInfluencers from 'selectors/sortInfluencers';
import MinLayout from 'components/Common/MinLayout';
import { ReactComponent as IconMessages } from 'images/icon-messages.svg';
import InfluencerAvatar from 'components/Common/InfluencerAvatar';

const { Group: RadioGroup } = Radio;
const { TextArea } = Input;
const { Item: MenuItem } = Menu;

const ActionButton = ({
  influencerUID,
  isFetchingMessageUID,
  clickMsgInfluencerUID,
  showConfirmModal,
  onClickToMessageThread,
}) => {
  const moreOptions = (
    <Menu>
      <MenuItem>
        <span
          onClick={() => showConfirmModal(influencerUID)}
          role="presentation"
          style={{
            fontSize: '9px',
            color: '#5b6572',
            letterSpacing: '0.5px',
            fontFamily: 'truenosemibold',
          }}
        >
          REMOVE FROM CAMPAIGN
        </span>
      </MenuItem>
    </Menu>
  );
  const loadingMsg = isFetchingMessageUID && clickMsgInfluencerUID === influencerUID;
  return (
    <div>
      <Button
        onClick={() => onClickToMessageThread(influencerUID)}
        shape="circle"
        style={{
          background: '#ffb917',
          border: '0',
          marginRight: '5px',
          width: '35px',
          height: '35px',
        }}
      >
        {
          loadingMsg && (
            <Icon
              type="loading"
              style={{
                width: '15px',
                height: '15px',
                position: 'relative',
              }}
              spin
            />
          )
        }
        {
          !loadingMsg && (
            <IconMessages
              style={{
                position: 'relative',
                top: '2px',
                width: '15px',
                height: '14px',
              }}
            />
          )
        }
      </Button>

      <Dropdown overlay={moreOptions} placement="bottomRight" trigger={['click']}>
        <Button shape="circle">
          <Icon type="ellipsis" />
        </Button>
      </Dropdown>
    </div>
  );
};

const columns = [
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    render: InfluencerAvatar,
  },
  {
    title: 'AGE',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'FOLLOWERS',
    dataIndex: 'followers',
    key: 'followers',
  },
  {
    title: 'COMMENTS/POST',
    dataIndex: 'commentsPost',
    key: 'commentsPost',
  },
  {
    title: 'LIKES/POST',
    dataIndex: 'likesPost',
    key: 'likesPost',
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    render: ActionButton,
  },
];

const defaultState = {
  rejectReason: REJECT_REASONS[0][1],
  rejectCustomReason: '',
  confirmModal: false,
  rejectModal: false,
  isValidReason: true,
  submitReject: false,
  influencerUID: undefined,
  clickMsgInfluencerUID: undefined,
};

class Influencers extends Component {
  state = defaultState;

  rejectInfluencer = () => {
    const {
      rejectReason: reason,
      rejectCustomReason: customReason,
      influencerUID,
    } = this.state;

    const {
      rejectInfluencer: reject,
      influencers,
      campaign,
      match,
    } = this.props;

    if (!reason && !customReason.trim()) {
      this.setState(() => ({ isValidReason: false }));
    } else {
      this.setState(() => ({ submitReject: true }));

      const rejectReason = reason || customReason.trim();
      const args = {
        rejectReason,
        influencerUID,
        influencer: influencers[influencerUID].details,
        campaignUID: match.params.campaignId,
        campaign,
        brandUID: campaign.brandUID,
      };

      reject(args, (isSuccess) => {
        if (isSuccess) {
          notification.success({
            message: 'Rejected',
            description: 'Influencers successfully rejected!',
            duration: 2,
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Somethings wrong on rejecting influencer',
            duration: 2,
          });
        }
        this.setState(() => ({ ...defaultState }));
      });
    }
  }

  onChangeRejectReason = (e) => {
    this.setState(() => ({
      isValidReason: true,
      rejectReason: e.target.value,
    }));
  }

  onChange = (e) => {
    const { target } = e;
    this.setState(() => ({ [target.name]: target.value }));
  }

  showConfirmModal = (influencerUID) => {
    this.setState(() => ({ influencerUID }));

    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure you want to remove this Influencer? Influencers should only be removed in an emergency situation.',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => this.setState(() => ({ rejectModal: true })),
    });
  }

  formatTotalDisplay = (number) => {
    if (number < 1000) {
      return number;
    }

    if (number < 1000000) {
      return `${(number / 1000).toFixed(1)}K`;
    }

    return `${(number / 1000000).toFixed(1)}M`;
  }

  onClickToMessageThread = (influencerUID) => {
    this.setState(() => ({
      clickMsgInfluencerUID: influencerUID,
    }));

    const { onClickToMessageThread: toMessageThread } = this.props;

    toMessageThread(influencerUID);
  }

  render() {
    const {
      influencers,
      influencerIds,
      match,
      isFetchingMessageUID,
      isFetching,
      isUpdating,
    } = this.props;

    const {
      clickMsgInfluencerUID,
      influencerUID: stateInfluencerUID,
      submitReject,
      rejectModal,
      rejectReason,
      rejectCustomReason,
      isValidReason,
    } = this.state;

    const mapInfluencers = influencerIds.map((influencerUID) => {
      const influencer = influencers[influencerUID];
      const influencerDetails = influencer.details;
      let totalLikes = 0;
      let totalPosts = 0;

      const influencerCampaign = influencer.partnerships.products[match.params.campaignId];

      if (influencerCampaign && influencerCampaign.posts) {
        const { posts = {} } = influencerCampaign;

        Object.keys(posts).forEach((postUID) => {
          if (posts[postUID].details.status !== POST_DETAIL_STATUS.approved) {
            return;
          }

          totalLikes += parseInt(posts[postUID].details.likes, 10);
          totalPosts += parseInt(posts[postUID].details.comments, 10);
        });
      }

      return {
        key: influencerUID,
        influencer: influencerDetails,
        age: influencerDetails.ageRange,
        followers: this.formatTotalDisplay(parseInt(influencerDetails.followers || 0, 10)),
        commentsPost: totalPosts,
        likesPost: totalLikes,
        action: {
          influencerUID,
          isFetchingMessageUID,
          clickMsgInfluencerUID,
          showConfirmModal: this.showConfirmModal,
          onClickToMessageThread: this.onClickToMessageThread,
        },
      };
    });

    const reasons = REJECT_REASONS.map(([reasonShow, reasonMessage]) => (
      <Radio
        key={reasonShow}
        style={{ display: 'block', height: '30px', lineHeight: '30px' }}
        value={reasonMessage}
      >
        {reasonShow}
      </Radio>
    ));

    const influencer = influencers[stateInfluencerUID];
    const isVIP = influencer && influencer.details.isVIP;

    return (
      <Row type="flex" justify="space-around">
        <MinLayout>
          <Table
            locale={{ emptyText: <CustomEmpty description="No Influencers to show" /> }}
            loading={isFetching || isUpdating}
            columns={columns}
            dataSource={mapInfluencers}
            pagination={false}
            className="trend-table"
          />
          <Modal
            title={isVIP ? 'Reject VIP Influencer' : 'Reject Standard Influencer'}
            visible={rejectModal}
            onOk={this.rejectInfluencer}
            onCancel={() => this.setState(() => ({ rejectModal: false }))}
            footer={[
              <Button key="cancel" disabled={submitReject} onClick={() => this.setState(() => ({ rejectModal: false }))}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" loading={submitReject} onClick={this.rejectInfluencer}>
                Reject
              </Button>,
            ]}
          >
            {
              isVIP && (
                <div>
                  You&apos;re about to reject a VIP influencer. You will get back 1 VIP token.
                </div>
              )
            }
            {
              !isVIP && (
                <div>
                  You&apos;re about to reject a standard influencer.
                  You will get back 1 standard token.
                </div>
              )
            }
            <RadioGroup
              onChange={this.onChangeRejectReason}
              value={rejectReason}
              style={{ marginTop: '10px' }}
            >
              {reasons}
            </RadioGroup>
            {
              !rejectReason && (
                <TextArea
                  rows={4}
                  onChange={this.onChange}
                  name="rejectCustomReason"
                  value={rejectCustomReason}
                />
              )
            }
            {
              !rejectReason && !isValidReason && (
                <Alert
                  message="Please enter a reason to explain to the influencer why they are being rejected."
                  type="warning"
                  style={{ marginTop: '5px' }}
                />
              )
            }
          </Modal>
        </MinLayout>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  influencers: state.entities.influencers.byId,
  influencerIds: sortInfluencers(
    state.ui.influencerList,
    state.ui.campaignDashboard.currentCampaign,
  ),
  campaign: state.ui.campaignDashboard.currentCampaign,
  isFetching: state.entities.influencers.isFetching,
  isUpdating: state.entities.campaigns.isFetching,
  isFetchingMessageUID: state.entities.messages.isFetching,
});

const mapDispatchToProps = dispatch => ({
  rejectInfluencer: (args, callback) => dispatch(rejectInfluencer(args, callback)),
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
)(Influencers);
