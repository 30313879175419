import React, { Component } from 'react';
import {
  message,
  Modal,
  notification,
  Button,
} from 'antd';
import { connect } from 'react-redux';
import * as routes from 'constants/routes';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite';
import { setOwnedCampaigns } from 'actions/ui/sideNav';
import { checkIfAllApprovedAreCompleted } from 'api/campaigns';
import {
  removeCampaignFromStore, addCampaignBackToStore,
  archiveCampaign, revertArchiveCampaign,
} from 'actions/campaigns';
import MinLayout from 'components/Common/MinLayout';
import { AuthUserContext } from 'lib/Session';

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    background: '#f5f9fe',
    padding: '14px 0px 15px 210px',
    width: '100%',
    bottom: 0,
    left: 0,
  },
  button: {
    marginRight: '3px',
  },
});

class SettingsDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removeModalVisible: false,
      addModalVisible: false,
      archiveModalVisible: false,
    };
  }

  revertArchive = () => {
    const {
      revertArchiveCampaign: revert,
      campaignUID,
      currentUser,
      updateSideNav,
    } = this.props;

    revert(campaignUID, currentUser.uid, () => {
      updateSideNav(currentUser.uid);
      message.success('Removed Listing From Archive');
    });
  }

  showRemoveModal = () => {
    this.setState({ removeModalVisible: true });
  }

  showArchiveModal = () => {
    this.setState({ archiveModalVisible: true });
  }

  showAddModal = () => {
    this.setState({ addModalVisible: true });
  }

  handleCancel = () => {
    this.setState({
      removeModalVisible: false,
      addModalVisible: false,
      archiveModalVisible: false,
      loadingSubmit: false,
    });
  }

  handleRemoveOk = () => {
    const {
      campaignUID,
      currentUser,
      updateSideNav,
      removeCampaignFromStore: removeCampaign,
    } = this.props;

    this.setState(() => ({ loadingSubmit: true }));
    removeCampaign(campaignUID, () => {
      // Update sidenav MY CAMPAIGNS
      updateSideNav(currentUser.uid);
      this.handleCancel();
      message.success('Listing removed to store.');
    });
  }

  handleAddOk = () => {
    const {
      campaignUID,
      updateSideNav,
      addCampaignBackToStore: addBack,
      currentUser,
    } = this.props;

    this.setState({ loadingSubmit: true });
    addBack(campaignUID, () => {
      updateSideNav(currentUser.uid);
      this.handleCancel();
      message.success('Listing added back to store.');
    });
  }

  handleArchiveOk = () => {
    const {
      campaignUID,
      currentUser,
      archiveCampaign: archive,
      updateSideNav,
      history,
    } = this.props;
    this.setState({ loadingSubmit: true });
    checkIfAllApprovedAreCompleted(campaignUID, (allCompleted) => {
      if (allCompleted) {
        archive(campaignUID, currentUser.uid, () => {
          updateSideNav(currentUser.uid);
          history.push(routes.CAMPAIGN_HISTORY_PATH);
        });
      } else {
        const errMsg = 'All influencer campaigns must be completed before you can archive a product. You may remove the product from the store anytime, however, to prevent new influencers from applying.';

        notification.warning({
          message: 'Not All Campaigns Completed',
          description: errMsg,
          duration: 10,
        });
        this.handleCancel();
      }
    });
  }

  render() {
    let actionItems = [];
    const {
      isCompleted, isListed, currentUser,
      brandUID, history, campaignUID,
    } = this.props;

    if (currentUser && currentUser.uid !== brandUID) return '';

    if (!isCompleted) {
      if (isListed) {
        actionItems = [
          <Button key="remove-listing" onClick={this.showRemoveModal} className="trend-btn secondary">
            Remove Listing
          </Button>,
        ];
      } else {
        actionItems = [
          <Button key="add-listing" onClick={this.showAddModal} className="trend-btn secondary">
            Add Listing Back to Store
          </Button>,
        ];
      }
      actionItems = [
        ...actionItems,
        <Button key="archive-listing" onClick={this.showArchiveModal} className="trend-btn secondary">
          Archive Listing
        </Button>,
      ];
    } else {
      actionItems = [
        ...actionItems,
        <Button onClick={this.revertArchive}>
          Revert Archive Listing
        </Button>,
      ];
    }

    const {
      removeModalVisible,
      loadingSubmit,
      addModalVisible,
      archiveModalVisible,
    } = this.state;

    return (
      <AuthUserContext.Consumer>
        {
          authUser => (
            authUser && authUser.uid === brandUID && (
              <div className={css(styles.container)}>
                <div className="ant-row-flex ant-row-flex-space-around">
                  <MinLayout>
                    <Button
                      onClick={() => history.push(`/campaigns/${campaignUID}/edit`)}
                      className="trend-btn secondary"
                    >
                      Edit Listing
                    </Button>
                    <span>
                      { actionItems }
                      <Modal
                        title="Remove Listing?"
                        visible={removeModalVisible}
                        onOk={this.handleRemoveOk}
                        confirmLoading={loadingSubmit}
                        onCancel={this.handleCancel}
                      >
                        <p>Are you sure you want to remove this listing?</p>
                      </Modal>
                      <Modal
                        title="Add Listing Back to Store?"
                        visible={addModalVisible}
                        onOk={this.handleAddOk}
                        confirmLoading={loadingSubmit}
                        onCancel={this.handleCancel}
                      >
                        <p>Are you sure you want to add this listing back to store?</p>
                      </Modal>
                      <Modal
                        title="Archive Listing?"
                        visible={archiveModalVisible}
                        onOk={this.handleArchiveOk}
                        confirmLoading={loadingSubmit}
                        onCancel={this.handleCancel}
                      >
                        <p>Are you sure you want to archive this project?  </p>
                      </Modal>
                    </span>
                  </MinLayout>
                </div>
              </div>
            )
          )
        }
      </AuthUserContext.Consumer>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.entities.session.authUserData,
});

const mapDispatchToProps = dispatch => ({
  removeCampaignFromStore: (id, onComplete) => dispatch(removeCampaignFromStore(id, onComplete)),
  addCampaignBackToStore: (id, onComplete) => dispatch(addCampaignBackToStore(id, onComplete)),
  archiveCampaign: (campaignUID, brandUID, onComplete) => (
    dispatch(archiveCampaign(campaignUID, brandUID, onComplete))
  ),
  updateSideNav: brandUID => dispatch(setOwnedCampaigns(brandUID)),
  revertArchiveCampaign: (campaignUID, brandUID, onComplete) => (
    dispatch(revertArchiveCampaign(campaignUID, brandUID, onComplete))
  ),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SettingsDropdown);
