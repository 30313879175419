import React from 'react';
import { Row } from 'antd';

const PrivacyPolicy = () => (
  <Row type="flex" justify="space-around">
    <div className="min-layout-content small">
      <h1 className="white mb-2 text-white"> Privacy Policy </h1>
      <p className="type-light" style={{color: '#a6a6a6'}}>Make Influencers include your brands Instagram handle with their posts. This way audience members will have a clear path to visit your page. add required hashtags to drive people help your posts be discovered by people searching for specific themes (ex. #healthysnack, #feelinggood, #newyearnewyou)</p>
    </div>
  </Row>
);

export default PrivacyPolicy;
