import React, { Component } from 'react';
import { Layout, Button } from 'antd';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import 'styles/base/antd-custom.less'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import trendLogo from 'images/trend@2x.png'

import FileUploader from 'react-firebase-file-uploader';

import { withFirebase } from 'lib/Firebase';
import { AuthUserContext, withAuthorization } from 'lib/Session';

const { Content } = Layout;
const { TextArea } = Input;

const INITIAL_STATE = {
  name: '',
  storeImage: '',
  description: '',
  isUploading: false,
  progress: 0,
  error: null,
}

class OpenStoreFormBase extends Component {
  constructor(props) {
    super(props)
    this.state = {...INITIAL_STATE};
  }

  handleUploadStart = () => this.setState({isUploading: true, progress: 0});
  handleProgress = (progress) => this.setState({progress});
  handleUploadError = (error) => {
    this.setState({isUploading: false});
    console.error(error);
  }
  handleUploadSuccess = (filename) => {
    this.setState({progress: 100, isUploading: false});
    this.props.firebase.storage.ref('images').child(filename).getDownloadURL().then(url => this.setState({storeImage: url}));
  };

  onSubmit = event => {
    const { name, description, storeImage } = this.state;
    this.props.firebase.brandInfo(this.props.authUser.uid)
    .update({
      uid: this.props.authUser.uid,
      name: name,
      description: description,
      storeImage: storeImage,
      logo: storeImage,
      isPushNotificationsEnabled: true,
      isActive: true,
      isAdmin: false,
      isDisabled: false,
      isFree: true,
      lastReloadDate: new Date(),
      createdAt: new Date()
    })
    .then(() => {
      this.props.history.push("/grand/opening");
    })
    .catch(error => {
      this.setState({ error });
    });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { name, description, storeImage, error } = this.state;
    const isInvalid = name === '' || description === '' || storeImage === '';
    return (
      <div className="bg-black">
        <div style={{textAlign: 'center', padding: '4em 0'}}>
          <img alt='Trend' src={trendLogo} width="162" height="31" style={{ display: 'inline-block'}}/>
        </div>
        <h1 style={{textAlign: 'center', color: '#fff'}}>Open your Store</h1>
        <h3 style={{textAlign: 'center', color: '#ddd'}}>Set up your presence in the marketplace.</h3>
        <Layout>
          <Content style={{ padding: 24, background: '#000', minHeight: 600 }}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={6}>
                <Form layout="vertical" onSubmit={this.onSubmit}>
                  <Form.Item>
                    <label className="trend-label">Company Name</label>
                    <Input type="text" size="large" name="name" value={name} onChange={this.onChange} className="trend-input"/>
                  </Form.Item>
                  <Form.Item>
                    <label className="trend-label">Company Description</label>
                    <TextArea rows={4} name="description" value={description} onChange={this.onChange} className="trend-input"/>
                  </Form.Item>
                  <Form.Item>
                    <label className="trend-label">Company Store Image</label>
                    <p style={{color: '#494949', paddingTop: '5px'}}><small>This important image will help attract influencers to your brand. Make sure to choose something aesthetically pleasing and representative of your message.</small></p>
                  </Form.Item>
                  {this.state.isUploading &&
                    <p className="trend-label">{this.state.progress}%</p>
                  }
                  {this.state.storeImage &&
                    <img src={this.state.storeImage} alt="" style={{maxWidth: '100%'}} />
                  }
                  <label style={{cursor:'pointer', backgroundColor: '#1e1e1e', color: 'white', padding: 10, borderRadius: 4, marginBottom:12,  display:'block', textAlign:'center', fontSize: '11px', textTransform: 'uppercase', letterSpacing: '0.5px'}}>
                    {this.state.storeImage ? <span>Change Company Store Image</span> : <span>Add Company Store Image</span>}
                    <FileUploader
                    hidden
                    accept="image/*"
                    storageRef={this.props.firebase.storage.ref('images')}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                    />
                  </label>
                  <Button block size="large" type="submit" className="trend-btn" disabled={isInvalid} onClick={this.onSubmit}>Next</Button>
                  {error && <p>{error.message}</p>}
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    );
  }
}

const OpenStorePage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <OpenStoreForm authUser={authUser}/>
      </div>
    )}
  </AuthUserContext.Consumer>
);

const OpenStoreForm = compose(
  withRouter,
  withFirebase
)(OpenStoreFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(OpenStorePage);
