import React from 'react';
import { withFirebase } from 'lib/Firebase';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fetchLoggedUserData, clearSession } from 'actions/session';
import AuthUserContext from './context';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    /* eslint no-underscore-dangle: ["error", { "allow": ["_isMounted"] }] */
    _isMounted = false;

    state = {
      authUser: null,
    };

    componentDidMount() {
      this._isMounted = true;
      const {
        fetchLoggedUserData: loggedUserData,
        clearSession: clear,
        firebase,
      } = this.props;

      this.listener = firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          if (this._isMounted) {
            this.setState(() => ({ authUser }));
          }

          loggedUserData(authUser);
        } else {
          if (this._isMounted) {
            this.setState({ authUser: null });
          }

          clear();
        }
      });
    }

    componentWillUnmount() {
      this._isMounted = false;
      this.listener();
    }

    render() {
      const { authUser } = this.state;

      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    fetchLoggedUserData: authUser => dispatch(fetchLoggedUserData(authUser)),
    clearSession: () => dispatch(clearSession()),
  });

  const mapStateToProps = () => ({

  });

  return compose(
    withFirebase,
    connect(mapStateToProps, mapDispatchToProps),
  )(props => <WithAuthentication {...props} />);
};

export default withAuthentication;
