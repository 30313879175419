import { firebase } from 'lib/Firebase'
import { SET_OWNED_CAMPAIGNS, SET_OWNED_ARCHIVED_CAMPAIGNS } from 'reducers/ui/sideNav'
import { receiveCampaigns } from 'actions/campaigns'

const receiveOwnCampaigns = (campaigns) => ({
  type: SET_OWNED_CAMPAIGNS,
  payload: campaigns
});

const receiveOwnArchivedCampaigns = (campaigns) => ({
  type: SET_OWNED_ARCHIVED_CAMPAIGNS,
  payload: campaigns
});

export const setOwnedCampaigns = (brandUID) => (dispatch) => {
  dispatch(receiveOwnCampaigns({}))

  firebase.activeProducts().orderByChild('brandUID').equalTo(brandUID).once('value', (snapshot) => {
    let campaigns = {}
    if(snapshot.exists()) {
      campaigns = snapshot.val()
    }

    dispatch(receiveCampaigns(campaigns))
    dispatch(receiveOwnCampaigns(campaigns))
  });

  firebase.completedProducts().orderByChild('brandUID').equalTo(brandUID).once('value', (snapshot) => {
    let campaigns = {}
    if(snapshot.exists()) {
      campaigns = snapshot.val()
    }

    dispatch(receiveCampaigns(campaigns))
    dispatch(receiveOwnArchivedCampaigns(campaigns))
  })
}
