import * as Promise from 'bluebird';
import { firebase } from 'lib/Firebase';
import {
  fetchRequest,
  receiveRequest,
  addPost
} from 'actions/posts'
import { setInfluencer } from 'actions/influencers';
import { SET_POSTS_ANALYTICS, RESET_POSTS_ANALYTICS } from 'reducers/ui/postsAnalytics';
import {
  POST_STATUS,
  POST_DETAIL_STATUS
} from 'constants/posts';

export const setPostsAnalytics = (posts) => ({
  type: SET_POSTS_ANALYTICS,
  posts
});

export const resetPostsAnalytics = () => ({
  type: RESET_POSTS_ANALYTICS
});

export const getPerCampaignPosts = (args = {}) => {
  return (dispatch) => {
    dispatch(fetchRequest());
    dispatch(resetPostsAnalytics());

    firebase.activeProducts().child(args.campaignId).once('value').then((snapshot) => {
      if (!snapshot.val() || !snapshot.val().appliedUID) {
        dispatch(receiveRequest());
        return;
      }

      let approvedUIDs = [];
      const appliedUID = snapshot.val().appliedUID;
      Object.keys(appliedUID).forEach((influencerUID) => {
        if (appliedUID[influencerUID].status === args.status) {
          approvedUIDs.push(influencerUID);
        }
      });

      let posts = {};
      Promise.map(approvedUIDs, (influencerUID) => {
        return firebase.influencers().child(influencerUID).once('value', (influencer) => {
          const partnerships = influencer.val().partnerships;

          dispatch(setInfluencer({ [influencerUID]: influencer.val() }));

          if (partnerships && partnerships.products) {
            const {
              posts: campaignPosts = null,
              status = null
            } = partnerships.products[args.campaignId];

            if (campaignPosts && status !== POST_STATUS.rejected) {
              Object.keys(campaignPosts).forEach((postUID) => {
                const status = campaignPosts[postUID].details.status;

                if (status !== POST_DETAIL_STATUS.approved) {
                  return;
                }

                const post = {
                  postUID,
                  ...campaignPosts[postUID],
                  influencerUID,
                  productUID: args.campaignId,
                };

                posts[postUID] = post;

                dispatch(addPost({ [postUID]: post }));
              });
            }
          }
        });
      }, {
        concurrency: 5
      }).then(() => {
        dispatch(setPostsAnalytics(posts));
      }).catch((e) => {
        console.log('Error on getting influencer: ', e);
      }).finally(() => {
        dispatch(receiveRequest());
      });
    }).catch((e) => {
      dispatch(receiveRequest());
      console.log('Error on getting activeProducts: ', e);
    });
  };
};
