import React from 'react'
import { Menu, Badge } from 'antd';
import { Link } from 'react-router-dom'

const { Item: MenuItem } = Menu;

const TabLinks = ({ defaultSelectedKeys, matchUrl, approveCount, appliedCount, newMessagesCount }) => {
  return (
    <Menu mode="horizontal" defaultSelectedKeys={defaultSelectedKeys} selectedKeys={defaultSelectedKeys} className='trend-menu-horizontal'>
      <MenuItem key="summary" >
        <Link to={`${matchUrl}/summary`}>
          Summary
        </Link>
      </MenuItem>
      <MenuItem key="applications">
        <Link to={`${matchUrl}/applications`}>
          <Badge count={appliedCount} offset={[10, 0]} style={{ backgroundColor: '#ffb917' }} />
          Applications
        </Link>
      </MenuItem>
      <MenuItem key="my_influencers">
        <Link to={`${matchUrl}/my_influencers`}>
          <Badge count={approveCount} offset={[10, 0]} style={{ backgroundColor: '#ffb917' }} />
          Influencers
        </Link>
      </MenuItem>
      <MenuItem key="messages">
        <Link to={`${matchUrl}/messages`}>
          <Badge count={newMessagesCount} offset={[10, 0]} style={{ backgroundColor: '#ffb917' }} />
          Messages
        </Link>
      </MenuItem>
      <MenuItem key="posts">
        <Link to={`${matchUrl}/posts`}>
          Posts & Analytics
        </Link>
      </MenuItem>
    </Menu>
  );
}


export default TabLinks;
