import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import {
  Avatar,
  Icon,
  Card,
  Col,
  Row,
  notification,
} from 'antd';
import { IMPRESSIONS } from 'constants/posts';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';
import PostImages from 'lib/Download';

const { Meta } = Card;

const style = StyleSheet.create({
  favorite: {
    color: 'red',
  },
});

class PostCard extends Component {
  onClickFavorite = () => {
    const {
      onClickFavorite: favorite,
      influencerUID,
      postUID,
      post: { isFavorite },
    } = this.props;

    favorite({
      influencerUID,
      postUID,
      isFavorite: !isFavorite,
    });
  }

  render() {
    const { post, influencer } = this.props;
    const iconActions = [
      <Icon
        type="heart"
        theme={post.isFavorite ? 'filled' : 'outlined'}
        className={css(post.isFavorite && style.favorite)}
        onClick={this.onClickFavorite}
      />,
      <Icon
        onClick={() => PostImages.downloadImage(post.fullResImage, post.code, (alert) => {
          notification.warning({
            message: 'Image',
            description: alert,
            duration: 2,
          });
        })}
        type="download"
        theme="outlined"
      />,
    ];

    return (
      <Card
        cover={(
          <img
            role="presentation"
            key="image"
            src={post.standardResolutionImage}
            alt=""
            onClick={() => window.open(post.link, '_blank')}
          />
        )}
        actions={iconActions}
        className="post-card trend-post-card"
      >
        <Meta
          avatar={<Avatar src={influencer.image} size="large" />}
          title={`@${influencer.username}`}
          description={influencer.state}
        />
        <Row className="mt-3">
          <Col span={8}>
            <h2 className="m-0">{post.likes || 0}</h2>
            <UpperCaseLabel>Likes</UpperCaseLabel>
          </Col>
          <Col span={8}>
            <h2 className="m-0">{post.comments || 0}</h2>
            <UpperCaseLabel>Comments</UpperCaseLabel>
          </Col>
          <Col span={8}>
            <h2 className="m-0">{((post.likes || 0) * IMPRESSIONS)}</h2>
            <UpperCaseLabel>Impressions</UpperCaseLabel>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default PostCard;
