import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

// for brand and campaign profile images
const ProfileImage = (props) => {
  return (
    <div>
      <Avatar src={props.src} size={210} style={{borderRadius: '0', backgroundColor: '#000'}}/>
    </div>
  );
};

ProfileImage.defaultProps = {
  src: ''
}

ProfileImage.propTypes = {
  src: PropTypes.string
}

export default ProfileImage;
