// INSTRUCTIONS
//
// ROUTE USAGE FORMAT
// format: toPathName
// ex
//   this.props.history.push(toCampaignSummaryPath(campaignId))
//
// ROUTE DECLERATION FORMAT
// format: NAME_OF_PATH
// ex.
//   <Route to={CAMPAIGN_SUMMARY_PATH} />
//
// NOTE: Always add a 'PATH' suffix to the path.
// ex.
//   - toPostListPath
//   - POST_LIST_PATH
//


export const toCampaignSummaryPath = campaignId => (
  `/campaigns/${campaignId}/summary`
);


export const SUBSCRIPTION_PATH = '/subscribe';

export const CAMPAIGN_SUMMARY_PATH = '/campaigns/:campaignId/summary';

export const CAMPAIGN_HISTORY_PATH = '/campaigns/history/';

export const ACCOUNT_SETTINGS_PATH = '/account';
export const BRAND_EDIT_PATH = '/account/store/edit';
export const TOS_PATH = '/account/tos';
export const PRIVACY_PATH = '/account/privacy';
export const SUBSCRIPTION_SETTINGS_PATH = '/account/subscription';
export const ADD_CREDITS_PATH = '/account/addCredits';
