import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Row, Col } from 'antd';
import { filterFavoritePosts } from 'selectors/postsAnalytics';
import PostCard from 'components/CampaignDashboard/Posts/PostCard';

class Posts extends Component {
  list() {
    const { posts, postIds, influencers } = this.props;

    return postIds.map((postUID) => {
      const { influencerUID, details: post } = posts[postUID];
      const influencer = influencers[influencerUID] && influencers[influencerUID].details;
      return(
        <Col key={postUID} span={8} styles={{marginBottom: '10px'}}>
          <div>
            <PostCard
              onClickFavorite={this.props.onClickFavorite}
              influencerUID={influencerUID}
              postUID={postUID}
              post={post}
              influencer={influencer || {}}
            />
          </div>
        </Col>
      )
    })
  }

  render() {
    return(
      <Row>
        { this.list() }
      </Row>
    )
  }
}

Posts.defaultProps = {};

Posts.propTypes = {};

const mapStateToProps = (state) => ({
  posts: state.entities.posts.byId,
  postIds: filterFavoritePosts(
    state.ui.modeView.isFavorite,
    state.entities.posts.byId,
    state.ui.storeAnalytics
  ),
  influencers: state.entities.influencers.byId

})
export default connect(mapStateToProps)(Posts);
