import React from 'react';
import { Row, Col, Empty } from 'antd';
import { connect } from 'react-redux';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';
import { filteredByApproved, sortPosts } from 'selectors/posts';
import PostItem from './PostItem';

const RecentPost = ({ postIds, posts, influencers }) => {
  const lists = postIds.slice(0, 3).map((id) => {
    const post = posts.byId[id];
    const influencer = influencers.byId[post.influencerUID];
    const username = influencer && influencer.details && influencer.details.username;
    return <PostItem key={id} post={post.details} username={username} />;
  });

  return (
    <div>
      <div>
        <Row gutter={0}>
          <Col span={14}>
            <h3 className="size-13px text-black">Latest Posts</h3>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <UpperCaseLabel>COMMENTS</UpperCaseLabel>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <UpperCaseLabel>LIKES</UpperCaseLabel>
          </Col>
        </Row>
        { lists.length < 1 ? <Empty description={<span style={{ color: '#ddd' }}>No posts yet.</span>} style={{ marginTop: '20px' }} /> : lists }
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  posts: state.entities.posts,
  postIds: sortPosts(
    filteredByApproved(
      state.ui.campaignPosts,
      state.entities.posts,
    ),
    state.entities.posts.byId,
  ),
  influencers: state.entities.influencers,
});

export default connect(mapStateToProps)(RecentPost);
