import FirebaseContext, { withFirebase } from './context';
import Firebase from './firebase';
import UserToken from './userToken';

const firebase = new Firebase();

export {
  Firebase as default,
  FirebaseContext,
  UserToken,
  withFirebase,
  firebase,
};
