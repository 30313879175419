import React, { Component } from 'react';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';
import CampaignPreview from './CampaignPreview';
import PerCategory from './PerCategory';
import { Icon } from 'antd';
import { connect } from 'react-redux'
import { Skeleton } from 'antd'

class RightSideInfo extends Component {
  render () {
    const {website, postPerInfluencer, productValue, category, states, hashtags, brandtags } = this.props
    const currentCampaign = this.props.currentCampaign

    return (
      <div style={{ marginTop: '23px', paddingTop: '23px', borderTop: '1px solid #e8e8e8' }}>
        <Skeleton loading={!currentCampaign.campaignUID} title={false} paragraph={{rows: 10}}>
          <h3 className="size-13px">
            Listing Details
          </h3>
          <div >
            <UpperCaseLabel bold={true}>website</UpperCaseLabel>
            <div style={{ fontFamily: 'truenoextrabold', color: '#000000', fontSize: '12px'}}>{currentCampaign.url}</div>
          </div>
          <div style={{ marginTop: '25px' }}>
            <UpperCaseLabel bold={true}>posts per influencer</UpperCaseLabel>
            <div style={{ fontFamily: 'truenoextrabold', color: '#000000', fontSize: '12px'}}>{currentCampaign.numberOfPosts}</div>
          </div>
          <div style={{ marginTop: '25px' }}>
            <UpperCaseLabel>product value</UpperCaseLabel>
            <div style={{ fontFamily: 'truenoextrabold', color: '#000000', fontSize: '12px'}}>{`$${productValue}`}</div>
          </div>
          <div style={{ marginTop: '30px' }}>
            <UpperCaseLabel>Product Category</UpperCaseLabel>
            <div style={{ fontFamily: 'truenoextrabold', color: '#000000', fontSize: '12px'}}>{category}</div>
          </div>
          <div style={{ marginTop: '30px' }}>
            <UpperCaseLabel>target states</UpperCaseLabel>
            { Object.values(states || {}).map( (state) => (
              <span style={{ fontFamily: 'truenoextrabold', color: '#000000', fontSize: '12px'}} key={state}> {state} </span>
            ) ) }
          </div>

          <h3 style={{ marginTop: '30px', paddingTop: '30px', borderTop: '1px solid #e8e8e8'}} className="size-13px"> Post Requirements </h3>
          <div>
            <UpperCaseLabel>REQUIRED HASHTAGS</UpperCaseLabel>
            { Object.values(hashtags || {}).map( (tag) => (
              <span style={{ fontFamily: 'truenoextrabold', color: '#000000', fontSize: '12px'}} key={tag}> #{tag} </span>
            ) ) }
          </div>
          <div style={{ marginTop: '30px' }}>
            <UpperCaseLabel>REQUIRED BRANDTAGS</UpperCaseLabel>
            { Object.values(brandtags || {}).map( (tag) => (
              <span style={{ fontFamily: 'truenoextrabold', color: '#000000', fontSize: '12px'}} key={tag}> @{tag}  </span>
            ) ) }
          </div>
        </Skeleton>
      </div>
      )
  }
}

const mapStateToProps = (state) => ({
  currentCampaign: state.ui.campaignDashboard.currentCampaign
})
export default connect(mapStateToProps)(RightSideInfo)
