import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import {
  changeModeView,
  filterFavorite,
} from 'actions/ui/creativeAssets';
import {
  Row,
  Col,
  Icon,
  Radio,
  Divider,
  Button,
} from 'antd';
import { filterFavoritePosts } from 'selectors/postsAnalytics';
import PostImages from 'lib/Download';
import { fetchBrand } from 'actions/brands';

const {
  Button: RadioButton,
  Group: RadioGroup,
} = Radio;

const style = StyleSheet.create({
  favorite: {
    color: 'red',
  },
});

class ViewOption extends Component {
  state = {
    folderName: 'defaultFolderName',
    downloadAllHD: false,
  };

  constructor(props) {
    super(props);

    const {
      fetchBrand: brand,
      authUser,
    } = props;

    brand(authUser.uid, ({ details: brandDetails }) => {
      this.setState(() => ({
        folderName: brandDetails.description,
      }));
    });
  }

  onClickFilterFavorite = () => {
    const {
      filterFavorite: favorite,
      isFavorite,
    } = this.props;

    favorite(!isFavorite);
  }

  downloadHDImages = () => {
    const {
      posts,
      postUIDs,
    } = this.props;
    const { folderName } = this.state;

    const selectedPosts = {};

    postUIDs.forEach((postUID) => {
      selectedPosts[postUID] = posts[postUID];
    });

    this.setState(() => ({ downloadAllHD: true }));
    PostImages.downloadAllHD(folderName, selectedPosts, () => {
      this.setState(() => ({
        downloadAllHD: false,
      }));
    });
  }

  onChangeView = (e) => {
    const { changeModeView: modeView } = this.props;
    modeView(e.target.value);
  }

  render() {
    const {
      view,
      isFavorite,
    } = this.props;
    const { downloadAllHD } = this.state;

    return (
      <Row type="flex" align="middle" style={{ marginTop: '20px' }}>
        <Col span={4}>
          <RadioGroup defaultValue={view} onChange={this.onChangeView}>
            <RadioButton value="grid">
              <Icon type="appstore" theme="outlined" />
              &nbsp;Grid
            </RadioButton>
            <RadioButton value="list">
              <Icon type="bars" theme="outlined" />
              &nbsp;List
            </RadioButton>
          </RadioGroup>
        </Col>
        <Col span={4}>
          <Divider
            type="vertical"
            style={{
              width: '1.8px',
              height: '1.5em',
              marginLeft: '8px',
              marginRight: '15px',
            }}
          />
          <span role="presentation" onClick={this.onClickFilterFavorite}>
            <Icon
              theme={isFavorite ? 'filled' : 'outlined'}
              className={css(isFavorite && style.favorite)}
              type="heart"
              style={{
                marginRight: '5px',
                fontSize: '18px',
              }}
            />
            <span>Favorites</span>
          </span>
        </Col>
        <Col span={6} offset={10} style={{ textAlign: 'right' }}>
          <Button
            loading={downloadAllHD}
            onClick={this.downloadHDImages}
            size="large"
            className="trend-btn"
          >
            EMAIL HD CREATIVE & STATS
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  view: state.ui.modeView.view,
  isFavorite: state.ui.modeView.isFavorite,
  posts: state.entities.posts.byId,
  postUIDs: filterFavoritePosts(
    state.ui.modeView.isFavorite,
    state.entities.posts.byId,
    state.ui.postsAnalytics,
  ),
});

const mapDispatchToProps = dispatch => ({
  changeModeView: modeView => dispatch(changeModeView(modeView)),
  filterFavorite: isFavorite => dispatch(filterFavorite(isFavorite)),
  fetchBrand: (brandUID, callback) => dispatch(fetchBrand(brandUID, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOption);
