import { firebase } from 'lib/Firebase';
import { MESSAGE_TYPE } from 'constants/common';

export default (campaignUID, onComplete = null) => {
  firebase
    .messages()
    .orderByChild('details/partnershipUID')
    .equalTo(campaignUID)
    .once('value', (snapshot) => {
      const messages = snapshot.val();
      const messageUIDs = [];

      if (!messages) {
        onComplete(messageUIDs);
        return;
      }

      Object.keys(messages).forEach((messageUID) => {
        const { users = {} } = messages[messageUID];

        Object.keys(users).forEach((userMessageUID) => {
          const user = users[userMessageUID];

          if (user.type === MESSAGE_TYPE.brand && user.hasUnread) {
            messageUIDs.push(messageUID);
          }
        });
      });

      onComplete(messageUIDs);
    });
};
