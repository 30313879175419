import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { withAuthentication } from 'lib/Session';
import Login from 'components/Login';
import Signup from 'components/Signup';
import PasswordForget from 'components/PasswordForget';
import OpenStore from 'components/OpenStore';
import GrandOpening from 'components/GrandOpening';
import Subscription from 'components/OpenStore/Subscription';
import PostList from 'components/PostList';
import SessionLayout from 'components/Layouts/SessionLayout';
import SiderLayout from 'components/Layouts/SiderLayout';
import CampaignList from 'components/CampaignList';
import CampaignDashboard from 'components/CampaignDashboard';
import CampaignEdit from 'components/CampaignDashboard/CampaignEdit';
import StoreAnalytics from 'components/StoreAnalytics';
import BrandAccount from 'components/BrandAccount/BrandAccount';
import AppRoute from './AppRoute';
import PrivateRoute from './PrivateRoute';

const TrendRouters = () => (
  <Router>
    <div>
      <Switch>
        <AppRoute path="/signup" component={Signup} exact layout={SessionLayout} />
        <AppRoute path="/login" component={Login} exact layout={SessionLayout} />
        <AppRoute path="/forgot_password" component={PasswordForget} exact layout={SessionLayout} />
        <PrivateRoute path="/grand/opening" component={GrandOpening} exact layout={SessionLayout} />
        <PrivateRoute path="/subscribe" component={Subscription} exact layout={SessionLayout} />
        <PrivateRoute path="/setup/store" component={OpenStore} exact layout={SessionLayout} />
        <PrivateRoute path="/account" component={BrandAccount} />
        <PrivateRoute path="/store/analytics" component={StoreAnalytics} exact layout={SiderLayout} />

        <PrivateRoute path="/campaigns/new" component={CampaignDashboard} />
        <PrivateRoute path="/campaigns/:campaignId/edit" component={CampaignEdit} exact />
        <PrivateRoute path="/campaigns/:campaignId" component={CampaignDashboard} layout={SiderLayout} />
        <PrivateRoute path="/messages/:messageId" component={CampaignDashboard} layout={SiderLayout} />
        <PrivateRoute path="/products" component={CampaignList} layout={SiderLayout} />
        <PrivateRoute path="/posts" component={PostList} exact layout={SiderLayout} />
        <Redirect from="/" exact to="/posts" />
      </Switch>
    </div>
  </Router>
);

export default withAuthentication(TrendRouters);
