import axios from 'axios';
import { firebase } from 'lib/Firebase';
import Routes from 'api/routes';

export const paySubscription = async (token, plan, uid, onComplete = null) => {
  const authToken = await firebase.auth.currentUser.getIdToken(true);
  const data = { token, plan, uid };

  axios.post(Routes.paySubscription, data, {
    headers: { Authorization: `Bearer ${authToken}` },
  }).then((response) => {
    onComplete(response);
  }).catch((error) => {
    onComplete(null, error);
  });
};

export const fetchPlans = async (onComplete = null) => {
  const token = await firebase.auth.currentUser.getIdToken(true);

  axios.get(Routes.plans, { headers: { Authorization: `Bearer ${token}` } }).then((response) => {
    onComplete(response.data);
  });
};

export const fetchCurrentSubscription = async (uid, onComplete = null) => {
  const token = await firebase.auth.currentUser.getIdToken(true);

  axios.get(Routes.currentSubscription, {
    params: { uid },
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => {
    onComplete(response.data);
  });
};

export const updatePlan = async (uid, plan, onComplete = null) => {
  const token = await firebase.auth.currentUser.getIdToken(true);
  const data = { uid, plan };

  axios.post(Routes.updatePlan, data, {
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => {
    onComplete(response.data, null);
  }).catch((err) => {
    onComplete(null, err);
  });
};
