import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import CustomEmpty from 'components/Common/CustomEmpty';
import {
  Table,
  Avatar,
  Popconfirm,
  Tag,
  Modal,
  Radio,
  Input,
  Alert,
  Button,
  Checkbox,
  Row,
  Col,
  List,
  Badge,
  Divider,
  notification,
} from 'antd';
import {
  setInfluencerList,
  getInfluencerList,
} from 'actions/ui/influencerList';
import {
  checkLastReloadDate,
  approveInfluencers,
  getAllInfluencersInBrand,
  rejectInfluencers,
  getInfluencerByCreditsType,
} from 'actions/ui/applicationsAction';
import { addCurrentCampaign } from 'actions/ui/campaignDashboard';
import { fetchBrand } from 'actions/brands';
import sortInfluencers from 'selectors/sortInfluencers';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import { REJECT_REASONS } from 'constants/influencers';
import {
  PURCHASE_VIP_EMAIL,
  SUPPORT_EMAIL,
  DATE_TIMEZONE_FORMAT,
} from 'constants/common';
import { ReactComponent as IconProfile } from 'images/icon-profile.svg';
import { ReactComponent as IconApprove } from 'images/icon-approve.svg';
import { ReactComponent as IconReject } from 'images/icon-reject.svg';
import { ReactComponent as IconVip } from 'images/icon-vip-profile.svg';
import MinLayout from 'components/Common/MinLayout';

const {
  Group: RadioGroup,
  Button: RadioButton,
} = Radio;
const { TextArea } = Input;

const confirmApproveText = 'Are you sure you want to approve this influencer?';
const confirmRejectText = 'Are you sure you want to reject this influencer?';

const Reasons = REJECT_REASONS.map(([reasonShow, reasonMessage]) => (
  <Radio
    key={reasonShow}
    style={{ display: 'block', height: '30px', lineHeight: '30px' }}
    value={reasonMessage}
  >
    {reasonShow}
  </Radio>
));

const MultipleSelect = ({ influencerUID, selectedRowKeys, onChangeSelect }) => (
  <Checkbox
    key={influencerUID}
    checked={selectedRowKeys.includes(influencerUID)}
    onChange={() => onChangeSelect(influencerUID)}
  />
);

const InfluencerAvatar = ({ influencerUID, influencer, allInfluencersInBrand }) => (
  <div>
    <div className="p-relative">
      <Avatar src={influencer.image} size={50} style={{ float: 'left' }} />
      { influencer.isVIP && <IconVip style={{ position: 'absolute', top: '35px', left: '35px'}} /> }
      {allInfluencersInBrand.includes(influencerUID) && <div className="status-tag"><span>WORKED TOGETHER BEFORE</span></div>}
    </div>
    <div
      style={{
        position: 'relative',
        fontWeight: 'bold',
        marginLeft: '10px',
        float: 'left',
      }}
    >
      <div className="pt-1 type-bold">{influencer.username}</div>
      <div style={{ fontSize: '11px', color: '#5b6572' }}>{influencer.state}</div>
    </div>
  </div>
);

const Category = (categories) => {
  const mapCategories = categories.map(category => (
    <span style={{ textTransform: 'capitalize' }} key={category}>
      {category}
      <br />
    </span>
  ));

  return (
    <div>{mapCategories}</div>
  );
};

const ActionButtons = ({
  influencerUID,
  influencer,
  onClickApprove,
  showRejectModal,
}) => (
  <div>
    <a
      href={`https://www.instagram.com/${influencer.instagramID}/`}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'block',
        color: '#5b6572',
        letterSpacing: '0.5px',
        fontSize: '9px',
      }}
    >
      <IconProfile width="14" height="15" style={{ position: 'relative', top: '3px', marginRight: '5px' }} />
      PROFILE
    </a>
    <div>
      <div style={{ cursor: 'pointer' }}>
        <Popconfirm
          onConfirm={() => onClickApprove(influencerUID)}
          placement="topRight"
          title={confirmApproveText}
          okText="Approve"
          cancelText="Cancel"
        >
          <IconApprove width="14" height="15" style={{ position: 'relative', top: '5px', marginRight: '5px' }} />
          <span style={{ color: '#5b6572', letterSpacing: '0.5px', fontSize: '9px' }}>APPROVE</span>
        </Popconfirm>
      </div>

      <div style={{ cursor: 'pointer' }}>
        <Popconfirm
          onConfirm={() => showRejectModal(influencerUID)}
          placement="topRight"
          title={confirmRejectText}
          okText="Reject"
          cancelText="Cancel"
        >
          <IconReject width="14" height="15" style={{ position: 'relative', top: '5px', marginRight: '5px' }} />
          <span style={{ color: '#5b6572', letterSpacing: '0.5px', fontSize: '9px' }}>REJECT</span>
        </Popconfirm>
      </div>
    </div>
  </div>
);

const columns = [
  {
    title: '',
    dataIndex: 'multipleSelect',
    key: 'multipleSelect',
    render: MultipleSelect,
  },
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    render: InfluencerAvatar,
  },
  {
    title: 'LOCATION',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'AGE RANGE',
    dataIndex: 'ageRange',
    key: 'ageRange',
  },
  {
    title: 'GENDER',
    dataIndex: 'gender',
    key: 'gender',
    render: gender => (<span style={{ textTransform: 'capitalize' }}>{gender}</span>),
  },
  {
    title: 'CATEGORY',
    dataIndex: 'categories',
    key: 'categories',
    render: Category,
  },
  {
    title: '',
    dataIndex: 'viewProfile',
    key: 'viewProfile',
    render: ActionButtons,
  },
];

const creditsColumn = [
  {
    title: '',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Standard',
    dataIndex: 'standard',
    key: 'standard',
  },
  {
    title: 'VIP',
    dataIndex: 'vip',
    key: 'vip',
  },
];

const defaultState = {
  rejectReason: REJECT_REASONS[0][1],
  rejectCustomReason: '',
  visible: false,
  selectedRowKeys: [],
  isValidReason: true,
  submitReject: false,
  submitApprove: false,
  cancelAll: false,
  selectedInfluencerUIDs: [],
  visibleNotEnoughCredits: false,
  visibleConfirmApprove: false,
  tallyCredits: [],
  massFinalReject: 'no',
};

class ApplicationList extends Component {
  state = defaultState;

  componentWillMount() {
    const {
      addCurrentCampaign: currentCampaign,
      getAllInfluencersInBrand: allInfluencersInBrand,
      fetchBrand: brand,
      getInfluencerList: influencerList,
      match,
    } = this.props;

    currentCampaign((campaign) => {
      const { brandUID } = campaign;

      allInfluencersInBrand(brandUID);
      brand(brandUID);

      this.setState(() => ({ brandUID }));
    });

    influencerList({
      statuses: [CAMPAIGN_APPLIED_UID_STATUS.submitted],
      campaignId: match.params.campaignId,
    });
  }

  componentWillUnmount() {
    const { resetApplicationList } = this.props;
    resetApplicationList();
  }

  onChangeSelect = (influencerUID) => {
    let { selectedRowKeys } = this.state;

    if (selectedRowKeys.includes(influencerUID)) {
      selectedRowKeys = selectedRowKeys.filter(uid => (uid !== influencerUID));
    } else {
      selectedRowKeys = [
        ...selectedRowKeys,
        influencerUID,
      ];
    }

    if (selectedRowKeys.length > 0) {
      this.setState(() => ({ cancelAll: false }));
    }

    this.setState(() => ({ selectedRowKeys }));
  }

  onChangeCancelAll = () => {
    this.setState(() => ({
      selectedRowKeys: [],
      cancelAll: true,
    }));
  }

  hasSelectedRowKeys = (callback) => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys.length > 0) {
      callback();
    } else {
      Modal.info({
        title: 'Applicant',
        content: 'No selected applicants!',
      });
    }
  }

  multipleApprove = () => {
    this.hasSelectedRowKeys(() => {
      const { selectedRowKeys } = this.state;

      this.setState(() => ({
        selectedInfluencerUIDs: selectedRowKeys,
      }));

      this.onClickApprove();
    });
  }

  multipleReject = () => {
    this.hasSelectedRowKeys(() => {
      const { selectedRowKeys } = this.state;

      this.setState(() => ({
        selectedInfluencerUIDs: selectedRowKeys,
      }));

      this.showRejectModal();
    });
  }

  onClickApprove = (influencerUID = null) => {
    const {
      selectedRowKeys,
      brandUID,
    } = this.state;

    if (influencerUID) {
      this.setState(() => ({
        selectedInfluencerUIDs: [influencerUID],
        selectedRowKeys: [
          new Set(selectedRowKeys.concat(influencerUID)),
        ],
      }));
    }

    const { checkLastReloadDate: reloadDate } = this.props;

    reloadDate(brandUID, this.isUpdatedLastReloadData);
  }

  isUpdatedLastReloadData = (isSuccess, brand, alertOption) => {
    const { influencers } = this.props;
    const { selectedInfluencerUIDs } = this.state;

    if (isSuccess) {
      const brandDetails = brand.details;
      const {
        standard,
        vip,
      } = getInfluencerByCreditsType(selectedInfluencerUIDs, influencers);

      if (brandDetails.credits < vip || brandDetails.standardCredits < standard) {
        const tallyCredits = [
          {
            key: '1',
            title: 'Credit(s)',
            standard: brandDetails.standardCredits,
            vip: brandDetails.credits,
          },
          {
            key: '2',
            title: 'Applicant(s)',
            standard,
            vip,
          },
        ];

        let nextPayment = null;
        if (brandDetails.standardCredits === 0) {
          nextPayment = moment(new Date(brandDetails.lastReloadDate), DATE_TIMEZONE_FORMAT).add('days', 30).format('MMMM DD, YYYY');
        }

        this.setState(() => ({
          tallyCredits,
          nextPayment,
          visibleNotEnoughCredits: true,
        }));
      } else {
        this.setState(() => ({ visibleConfirmApprove: true }));
      }
    } else {
      Modal[alertOption.type]({ ...alertOption });
    }
  }

  approveInfluencers = () => {
    const {
      selectedInfluencerUIDs: influencerUIDs,
      brandUID,
    } = this.state;

    const {
      brands,
      match,
      influencers,
      campaigns,
      approveInfluencers: approve,
    } = this.props;

    const args = {
      influencerUIDs,
      influencers,
      campaignUID: match.params.campaignId,
      campaign: campaigns[match.params.campaignId],
      brandUID,
      brand: brands[brandUID],
    };

    this.setState(() => ({ submitApprove: true }));

    approve(args, (isSuccess) => {
      this.setState(() => ({ ...defaultState }));

      if (isSuccess) {
        notification.success({
          message: 'Approved',
          description: 'Influencer(s) successfully approved!',
          duration: 2,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Somethings wrong on accepting influencer',
          duration: 2,
        });
      }
    });
  }

  onChangeMassFinalReject = (e) => {
    this.setState(() => ({ massFinalReject: e.target.value }));
  }

  onChangeRejectReason = (e) => {
    this.setState(() => ({ rejectReason: e.target.value }));
  }

  onChange = (e) => {
    const { target } = e;
    this.setState(() => ({ [target.name]: target.value }));
  }

  showRejectModal = (influencerUID = null) => {
    const { selectedRowKeys } = this.state;

    if (influencerUID) {
      this.setState(() => ({
        selectedRowKeys: [
          ...new Set(selectedRowKeys.concat(influencerUID)),
        ],
        selectedInfluencerUIDs: [influencerUID],
      }));
    }

    this.setState(() => ({ visible: true }));
  }

  onCancel = () => {
    const { selectedRowKeys } = this.state;

    this.setState(() => ({
      ...defaultState,
      selectedRowKeys,
    }));
  }

  rejectInfluencers = () => {
    const {
      rejectReason: reason,
      rejectCustomReason: customReason,
      brandUID,
      selectedRowKeys: influencerUIDs,
      massFinalReject,
    } = this.state;

    const {
      rejectInfluencers: reject,
      brands,
      match,
      influencers,
      campaigns,
    } = this.props;

    if (!reason && !customReason.trim()) {
      this.setState(() => ({ isValidReason: false }));
    } else {
      this.setState(() => ({ submitReject: true }));

      const rejectReason = reason || customReason.trim();
      const args = {
        rejectReason,
        influencers,
        influencerUIDs,
        campaignUID: match.params.campaignId,
        campaign: campaigns[match.params.campaignId],
        brandUID,
        brandDetails: brands[brandUID].details,
        massFinalReject,
      };

      reject(args, (isSuccess) => {
        if (isSuccess) {
          notification.success({
            message: 'Rejected',
            description: 'Influencers successfully rejected!',
            duration: 2,
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Somethings wrong on rejecting influencer',
            duration: 2,
          });
        }
        this.setState(() => ({ ...defaultState }));
      });
    }
  }

  render() {
    const {
      influencerIds,
      influencers,
      allInfluencersInBrand,
      isFetching,
      isUpdating,
      brands,
    } = this.props;

    const {
      selectedRowKeys,
      visible,
      submitReject,
      rejectReason,
      rejectCustomReason,
      isValidReason,
      cancelAll,
      visibleNotEnoughCredits,
      tallyCredits,
      visibleConfirmApprove,
      submitApprove,
      brandUID,
      selectedInfluencerUIDs,
      nextPayment,
      massFinalReject,
    } = this.state;

    const mapInfluencers = influencerIds.map((influencerUID) => {
      const { details: influencer } = influencers[influencerUID];

      return {
        key: influencerUID,
        multipleSelect: {
          influencerUID,
          selectedRowKeys,
          onChangeSelect: this.onChangeSelect,
        },
        influencer: {
          influencerUID,
          influencer,
          allInfluencersInBrand,
        },
        location: influencer.state,
        ageRange: influencer.ageRange,
        gender: influencer.gender,
        categories: (influencer.categories ? Object.values(influencer.categories) : []),
        viewProfile: {
          onClickApprove: this.onClickApprove,
          showRejectModal: this.showRejectModal,
          influencerUID,
          influencer,
          status: influencers[influencerUID].appliedUIDStatus,
        },
      };
    });

    const mapSelectedInfluencers = selectedInfluencerUIDs.map(uid => ({
      title: influencers[uid].details.name,
      isVIP: !!influencers[uid].details.isVIP,
    }));

    const { details: brandDetails = {} } = brands[brandUID] || {};

    return (
      <div>
        <Row type="flex" justify="space-around" style={{ padding: '20px 0', background: '#fcfcfc', borderBottom: '1px solid #e8e8e8'}}>
          <MinLayout>
            <Col span={3}>
              <label className="d-block clickable leading-18px">
                <Button
                  onClick={this.multipleApprove}
                  icon="check"
                  shape="circle"
                  style={{ float: 'left', width: '20px', height: '20px', backgroundColor: '#ffb917', marginRight: '8px', fontSize: '12px', border: '0' }}
                />
                <span className="fsz-2 type-ebold spacing-1px text-black pl-1">APPROVE</span>
              </label>
            </Col>
            <Col span={3}>
              <label className="d-block clickable leading-18px">
                <Button
                  onClick={this.multipleReject}
                  icon="close"
                  shape="circle"
                  style={{ float: 'left', backgroundColor: '#000000', width: '20px', height: '20px', color: '#ffffff', marginRight: '8px', fontSize: '12px', border: '0' }}
                />
                <span className="fsz-2 type-ebold spacing-1px text-black pl-1">REJECT</span>
              </label>
            </Col>
            <Col span={3} offset={14}>
              <label className="d-block clickable leading-18px">
                <Checkbox
                  checked={cancelAll}
                  onChange={this.onChangeCancelAll}
                  className="custom-checkbox"
                  style={{ float: 'left', marginRight: '8px' }}
                />
                <span className="fsz-2 type-ebold spacing-1px text-black pl-1">CANCEL</span>
              </label>
            </Col>
          </MinLayout>
        </Row>
        <Row type="flex" justify="space-around">
          <MinLayout>
            <Table
              locale={{ emptyText: <CustomEmpty description="No applications to show" /> }}
              loading={isFetching || isUpdating}
              columns={columns}
              dataSource={mapInfluencers}
              pagination={false}
              className="trend-table"
              cellpadding="0"
              cellspacing="0"
            />
          </MinLayout>
        </Row>
        <Modal
          title="Confirm Approve Influencers"
          visible={visibleConfirmApprove}
          closable={false}
          footer={[
            <Button key="cancel" disabled={submitApprove} onClick={() => this.setState(() => ({ visibleConfirmApprove: false }))}>
              Cancel
            </Button>,
            <Button key="approve" type="primary" loading={submitApprove} onClick={this.approveInfluencers}>
              Approve
            </Button>,
          ]}
        >
          <div style={{ marginBottom: '15px' }}>
            By accepting these peaple you will be using your credit(s).
            Are you sure you want to spend your credit(s)?
          </div>
          <div>
            <Badge
              showZero
              count={(brandDetails.standardCredits || 0)}
              style={{
                backgroundColor: '#fff',
                color: '#999',
                boxShadow: '0 0 0 1px #d9d9d9 inset',
              }}
            />
            <span style={{ marginLeft: '5px' }}>- Standard Credit(s)</span>
            <Divider type="vertical" />
            <Badge
              showZero
              count={(brandDetails.credits || 0)}
              style={{ backgroundColor: '#ffb917' }}
            />
            <span style={{ marginLeft: '5px' }}>- VIP Credit(s)</span>
          </div>
          <List
            itemLayout="horizontal"
            dataSource={mapSelectedInfluencers}
            style={{ marginTop: '15px' }}
            size="small"
            renderItem={(item, ndx) => (
              <List.Item>
                {(ndx + 1)}
                .&nbsp;
                <span style={{ marginRight: '5px' }}>{item.title}</span>
                {
                  item.isVIP
                    ? <Tag color="orange">VIP</Tag>
                    : <Tag>STANDARD</Tag>
                }
              </List.Item>
            )}
          />
        </Modal>
        <Modal
          title="Not Enough Credits"
          visible={visibleNotEnoughCredits}
          closable={false}
          footer={[
            <Button key="okay" onClick={() => this.setState(() => ({ visibleNotEnoughCredits: false }))}>
              Okay
            </Button>,
          ]}
        >
          Not enought credits. See Table below.
          <Table
            columns={creditsColumn}
            pagination={false}
            dataSource={tallyCredits}
            style={{ margin: '15px 0 15px 0' }}
          />
          {
            tallyCredits.length > 0 && tallyCredits[0].vip === 0 && (
              <div style={{ marginBottom: '15px' }}>
                You don&apos;t have any more credits to spend on VIP influencers.
                Please email &nbsp;
                <span style={{ fontWeight: 'bold' }}>{PURCHASE_VIP_EMAIL}</span>
                &nbsp;to purchase more.
              </div>
            )
          }
          {
            tallyCredits.length > 0 && tallyCredits[0].standard === 0 && (
              <div>
                You don&apos;t have any more standard credits to spend on influencers.
                &nbsp;Your credits refill again on &nbsp;
                <span style={{ fontWeight: 'bold' }}>{nextPayment}</span>
                . Please email &nbsp;
                <span style={{ fontWeight: 'bold' }}>{SUPPORT_EMAIL}</span>
                &nbsp;with questions.
              </div>
            )
          }
        </Modal>
        <Modal
          visible={visible}
          onOk={this.rejectInfluencer}
          closable={false}
          className="trend-modal"
          footer={[
            <Row gutter={20}>
              <Col span={12}>
                <Button
                  key="cancel"
                  disabled={submitReject}
                  className="trend-btn huge-btn secondary"
                  onClick={() => this.setState(() => ({ visible: false, massFinalReject: 'no' }))}
                  block
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="submit"
                  className="trend-btn huge-btn reject"
                  loading={submitReject} onClick={this.rejectInfluencers}
                  block
                >
                  Confirm Rejection
                </Button>
              </Col>
            </Row>

          ]}
        >
          <div>
            <Row>
              <Col
                span={18}
              >
                <h2 className="text-black mb-2 type-ebold leading-24px">
                  Are you sure you want to&nbsp;
                  <span style={{ color: '#af4c4c', fontStyle: 'italic' }}>reject</span>
                  &nbsp;these influencers?
                </h2>
              </Col>
            </Row>
            <Row>
              <Col span={18}>
                <span className="bsize-4 text-black leading-18px">
                  Reject these influencers from all of
                  <br />
                  your current and future listings?</span>
              </Col>
              <Col span={6}>
                <RadioGroup
                  onChange={this.onChangeMassFinalReject}
                  value={massFinalReject}
                  defaultValue="no"
                  buttonStyle="solid"
                  className="plain-radio-group"
                >
                  <RadioButton value="no">NO</RadioButton>
                  <RadioButton value="yes">YES</RadioButton>
                </RadioGroup>
              </Col>
            </Row>
          </div>
          <Divider />
          <RadioGroup onChange={this.onChangeRejectReason} value={rejectReason}>
            {Reasons}
          </RadioGroup>
          {
            !rejectReason && (
              <TextArea
                rows={4}
                onChange={this.onChange}
                name="rejectCustomReason"
                value={rejectCustomReason}
                placeholder="Explain why this influencer is being rejected..."
              />
            )
          }
          {
            !rejectReason && !isValidReason && (
              <Alert
                message="Please enter a reason to explain to the influencer why they are being rejected."
                type="warning"
                style={{ marginTop: '5px' }}
              />
            )
          }
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  influencers: state.entities.influencers.byId,
  influencerIds: sortInfluencers(
    state.ui.influencerList,
    state.ui.campaignDashboard.currentCampaign,
  ),
  authUserData: state.entities.session.authUserData,
  brands: state.entities.brands.byId,
  campaigns: state.entities.campaigns.byId,
  isFetching: state.entities.influencers.isFetching,
  isUpdating: state.entities.campaigns.isFetching,
  allInfluencersInBrand: state.ui.applicationsAction.allInfluencersInBrand,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetApplicationList: () => dispatch(setInfluencerList({})),
  getInfluencerList: args => dispatch(getInfluencerList(args)),
  checkLastReloadDate: (brandUID, callback) => dispatch(checkLastReloadDate(brandUID, callback)),
  approveInfluencers: (args, callback) => dispatch(approveInfluencers(args, callback)),
  fetchBrand: brandUID => dispatch(fetchBrand(brandUID)),
  addCurrentCampaign: callback => dispatch(addCurrentCampaign(
    ownProps.match.params.campaignId,
    callback,
  )),
  getAllInfluencersInBrand: brandUID => dispatch(getAllInfluencersInBrand(brandUID)),
  rejectInfluencers: (args, callback) => dispatch(rejectInfluencers(args, callback)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ApplicationList);
