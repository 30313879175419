import {
  POST_DETAIL_STATUS,
  IMPRESSIONS
} from 'constants/posts';

export const filterFavoritePosts = (isFavorite, posts, postIds) => {
  return postIds.filter((postUID) => {
    if (!isFavorite) {
      return true;
    }

    return posts[postUID].details.isFavorite;
  });
}

export const totalPostSummary = (posts, postIds) => {
  let totalSummary = {
    posts: 0,
    likes: 0,
    comments: 0,
    impressions: 0,
    totalPost: 0
  }

  postIds.forEach((postUID) => {
    const post = (posts[postUID] || {}).details;

    if (post && post.status === POST_DETAIL_STATUS.approved) {
      totalSummary.posts += 1;
      totalSummary.likes += post.likes || 0;
      totalSummary.comments += post.comments || 0;
      totalSummary.impressions += ((post.likes || 0) * IMPRESSIONS)
    }

    if (post && [POST_DETAIL_STATUS.approved, POST_DETAIL_STATUS.pending].includes(post.status) ) {
      totalSummary.totalPost += 1
    }
  });

  return totalSummary;
}
