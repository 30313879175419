import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import MinLayout from 'components/Common/MinLayout';
import CustomEmpty from 'components/Common/CustomEmpty';
import {
  Row,
  Avatar,
  Spin,
  Table,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import { setOwnedCampaigns } from 'actions/ui/sideNav';
import { withAuthorization } from 'lib/Session';
import { fetchMessages } from 'actions/messages';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import { getInfluencerList } from 'actions/ui/influencerList';
import { setMessageList } from 'actions/ui/messages';
import {
  PARTNERSHIP_TYPE,
  DATE_TIMEZONE_FORMAT,
  MESSAGE_TYPE,
} from 'constants/common';
import filterSortMessages from 'selectors/messages';

const columns = [
  {
    title: '',
    dataIndex: 'product',
    key: 'product',
    render: text => <Avatar shape="square" src={text} size={30} />,
  },
  {
    title: '',
    dataIndex: 'influencerAvatar',
    key: 'influencerAvatar',
    render: text => <Avatar src={text} size={50} />,
    className: 'avatar',
  },
  {
    title: '',
    dataIndex: 'influencer',
    key: 'influencer',
    className: 'author',
    render: (text, record) => (
      <div>
        <p>
          @
          {text}
        </p>
        <p>{record.influencerState}</p>
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'message',
    key: 'message',
  },
  {
    title: '',
    dataIndex: 'time',
    key: 'time',
    className: 'time',
  },
  {
    title: '',
    dataIndex: 'count',
    key: 'count',
    className: 'count',
    render: hasUnread => (
      hasUnread && (
        <Avatar
          style={{
            backgroundColor: '#ffb917',
            verticalAlign: 'middle',
          }}
        >
          1
        </Avatar>
      )
    ),
  },
];

class Messages extends Component {
  constructor(props) {
    super(props);

    const {
      getInfluencerList: influencerList,
      match,
    } = this.props;

    influencerList({
      statuses: [
        CAMPAIGN_APPLIED_UID_STATUS.approved,
        CAMPAIGN_APPLIED_UID_STATUS.rejected,
      ],
      campaignId: match.params.campaignId,
    });
  }

  componentWillMount() {
    const {
      fetchMessages: fetchMsg,
      match,
    } = this.props;

    fetchMsg(match.params.campaignId, PARTNERSHIP_TYPE.product);
  }

  render() {
    const dataSource = [];
    const {
      messages,
      messageList,
      history,
      isFetching,
      authUser,
      campaigns,
      influencers,
    } = this.props;

    messageList.forEach((messageUID) => {
      const {
        messages: messageThreads,
        details,
        users,
      } = messages[messageUID];

      const len = Object.keys(messageThreads).length;
      const lastMessage = Object.values(messageThreads)[len - 1];
      const {
        text,
        timestamp,
      } = lastMessage;
      const { hasUnread } = users[authUser.uid];
      const campaign = campaigns[details.partnershipUID];

      Object.keys(users).forEach((userUID) => {
        const user = users[userUID];

        if (user.type === MESSAGE_TYPE.influencer) {
          const influencer = influencers[userUID];
          if (influencer) {
            dataSource.push({
              key: messageUID,
              product: campaign.image,
              influencerAvatar: influencer.details.image,
              influencer: influencer.details.username,
              influencerState: influencer.details.state,
              message: text,
              time: moment(timestamp, DATE_TIMEZONE_FORMAT).fromNow(),
              count: hasUnread,
            });
          }
        }
      });
    });

    return (
      <Spin spinning={isFetching}>
        <Row gutter={64} style={{ height: '100%', marginBottom: '40px' }} type="flex" justify="space-around">
          <MinLayout>
            <Table
              locale={{ emptyText: <CustomEmpty description="No messages to show" /> }}
              onRow={record => (
                {
                  onClick: () => {
                    history.push(`/messages/${record.key}`);
                  },
                }
              )}
              className="messages-table trend-table"
              dataSource={dataSource}
              columns={columns}
            />
          </MinLayout>
        </Row>
      </Spin>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  influencers: state.entities.influencers.byId,
  campaigns: state.entities.campaigns.byId,
  isFetching: state.entities.messages.isFetching,
  messages: state.entities.messages.byId,
  messageList: filterSortMessages(
    state.entities.messages.all,
    state.entities.messages.byId,
    ownProps.match.params.campaignId,
    ownProps.authUser.uid,
  ),
});

const mapDispatchToProps = dispatch => ({
  fetchMessages: (campaignId, type) => dispatch(fetchMessages(campaignId, type)),
  getInfluencerList: args => dispatch(getInfluencerList(args)),
  setOwnedCampaigns: brandUID => dispatch(setOwnedCampaigns(brandUID)),
  setMessageList: args => dispatch(setMessageList(args)),
});

const condition = authUser => !!authUser;

export default compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
  connect(mapStateToProps, mapDispatchToProps),
)(Messages);
