import { firebase } from 'lib/Firebase';
import {
  SET_LOGGED_USER,
  REMOVE_LOGGED_USER,
  UPDATE_LOGGED_USER,
} from 'reducers/entities/session';

export const setLoggedUser = authUserData => ({
  type: SET_LOGGED_USER,
  authUserData,
});

export const updateSession = authUserData => ({
  type: UPDATE_LOGGED_USER,
  authUserData,
});

export const removeLoggedUser = () => ({
  type: REMOVE_LOGGED_USER,
});

export const fetchLoggedUserData = (authUser, callback = null) => (dispatch) => {
  firebase.brand(authUser.uid).once('value', (snapshot) => {
    const brand = snapshot.val();

    dispatch(setLoggedUser({
      ...authUser, ...brand,
    }));

    if (callback) {
      callback(brand);
    }
  });
};

export const clearSession = () => (dispatch) => {
  dispatch(removeLoggedUser());
};

export const updateLoggedUser = authUser => (dispatch) => {
  dispatch(updateSession(authUser));
};
