import React from 'react';

import HeaderText from 'components/Common/HeaderText';

const PostGuideLines = ({ guidelines }) => (
  <div style={{ marginTop: '50px' }}>
    <HeaderText bold className="fsz-6">Post Guidelines</HeaderText>
    <p className="text-black size-12px leading-23">
      { guidelines }
    </p>
  </div>
);
export default PostGuideLines;
