export const RECEIVE_BRANDS = 'RECEIVE_BRANDS';

export const RECEIVE_BRAND = 'RECEIVE_BRAND';

export const UPDATE_BRAND = 'UPDATE_BRAND';

export const UPDATE_BRAND_DETAILS = 'UPDATE_BRAND_DETAILS';

const initialState = {
  byId: {
    // 1: {
    //   ...atr
    // }
  },
  all: [
    // ids
    // 1,2,3
  ],
};

const addBrands = (state, brands) => ({
  ...state,
  byId: {
    ...state.byId,
    ...brands,
  },
  all: [
    ...new Set(state.all.concat(Object.keys(brands))),
  ],
});

const addBrand = (state, brand) => ({
  ...state,
  byId: {
    ...state.byId,
    ...brand,
  },
  all: [
    ...new Set(state.all.concat(Object.keys(brand))),
  ],
});

const updateBrand = (state, brandUID, brand) => ({
  ...state,
  byId: {
    ...state.byId,
    [brandUID]: {
      ...state.byId[brandUID],
      ...brand,
    },
  },
});

const updateBrandDetails = (state, brandUID, brandDetails) => ({
  ...state,
  byId: {
    ...state.byId,
    [brandUID]: {
      ...state.byId[brandUID],
      details: {
        ...state.byId[brandUID].details,
        ...brandDetails,
      },
    },
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BRANDS:
      return addBrands(state, action.brands);

    case RECEIVE_BRAND:
      return addBrand(state, action.brand);

    case UPDATE_BRAND:
      return updateBrand(state, action.brandUID, action.brand);

    case UPDATE_BRAND_DETAILS:
      return updateBrandDetails(state, action.brandUID, action.brandDetails);

    default:
      return state;
  }
};
