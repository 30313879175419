let initialState = {
  approvedPosts: []
}

export const SET_POSTS_FEED = 'SET_POSTS_FEED';
export const CLEAR_POSTS_FEED = 'CLEAR_POSTS_FEED';

function postList(state= initialState, action) {
  switch(action.type) {
    case SET_POSTS_FEED:
      return {
        ...state,
        approvedPosts: [
          ...action.payload,
        ]
      }
    case CLEAR_POSTS_FEED:
      return {
        ...state,
        approvedPosts: []
      }
    default:
      return state;
  }
}

export default postList
