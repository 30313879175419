import {
  fetchRequest,
  receiveRequest
} from './common/request';

export const CAMPAIGN_FETCH_REQUEST = 'CAMPAIGN_FETCH_REQUEST';

export const CAMPAIGN_RECEIVE_REQUEST = 'CAMPAIGN_RECEIVE_REQUEST';

export const RECEIVE_CAMPAIGNS = 'RECEIVE_CAMPAIGNS';

export const RECEIVE_CAMPAIGN = 'RECEIVE_CAMPAIGN'

export const FETCH_BRAND_CAMPAIGNS = 'FETCH_BRAND_CAMPAIGNS'

export const SET_CAMPAIGN_LIST_CATEGORY = 'SET_CAMPAIGN_LIST_CATEGORY';

export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';

export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';

export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';

export const UPDATE_CAMPAIGN_APPLIED_UID = 'UPDATE_CAMPAIGN_APPLIED_UID';

let defaultState = {
  byId: {},
  byCategory: {},
  all: [],
  owned: [],
  isFetching: false
};

const groupByCategory = (state, category, id) => ({
  ...state,
  byCategory: {
    ...state.byCategory,
    [category]: [
      ...new Set(state.byCategory[category] ? [...state.byCategory[category], id] : [id])
    ]
  }
});

const addCampaign = (state, campaign) => ({
  ...state,
  byId: {
    ...state.byId,
    ...campaign
  },
  all: [
    ...new Set(state.all.concat(Object.keys(campaign)))
  ]
})

const addCampaigns = (state, campaigns) => {
  let newState = state

  for(let [id, campaign] of Object.entries(campaigns)) {
    newState = addCampaign(newState, { [id]: campaign })
    newState = groupByCategory(newState, campaign.category, id)
  }

  return newState;
}

const addOwnedCampaigns = (state, campaignIds) => ({
  ...state,
  owned: [...new Set(state.owned.concat(campaignIds))]
});

const updateCampaignAppliedUID = (state, campaignUID, influencerUID, appliedUID) => ({
  ...state,
  byId: {
    ...state.byId,
    [campaignUID]: {
      ...state.byId[campaignUID],
      appliedUID: {
        ...state.byId[campaignUID].appliedUID,
        [influencerUID]: {
          ...state.byId[campaignUID].appliedUID[influencerUID],
          ...appliedUID
        }
      }
    }
  }
});

export default (state = defaultState, action) => {
  switch(action.type) {
    case RECEIVE_CAMPAIGNS:
      return { ...addCampaigns(state, action.payload), isFetching: false }

    case RECEIVE_CAMPAIGN:
      return { ...addCampaign(state, action.payload) }

    case ADD_CAMPAIGN:
      let addNewState = addCampaign(state, action.payload)
      addNewState = addOwnedCampaigns(addNewState, Object.keys(action.payload))
      return { ...addNewState, newCampaign: action.payload }

    case UPDATE_CAMPAIGN:
      return {
        ...state,
        byId: { ...state.byId, ...action.payload },
        newCampaign: action.payload
      }

    case UPDATE_CAMPAIGN_APPLIED_UID:
      return updateCampaignAppliedUID(state, action.campaignUID, action.influencerUID, action.appliedUID);

    case CAMPAIGN_FETCH_REQUEST:
      return fetchRequest(state);

    case CAMPAIGN_RECEIVE_REQUEST:
      return receiveRequest(state);

    default:
      return state;
  }
}
