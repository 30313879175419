import axios from 'axios'
import { firebase } from 'lib/Firebase';
import Routes from 'api/routes';
import {
  RECEIVE_DELIVERY_DETAILS,
  REQUEST_DELIVERY_DETAILS,
  SHOW_SHIPPING_MODAL_FORM,
  HIDE_SHIPPING_MODAL_FORM,
  SET_SHIPPING_MODAL_FORM,
  SUBMIT_SHIPPING_FORM,
  SUBMITTED_SHIPPING_FORM,
  UPDATE_DELIVER_DETAILS,
} from 'reducers/ui/shippingFormModal';
import { POST_STATUS } from 'constants/posts';
import { updateInfluencer } from 'actions/influencers';

export const showShippingFormModal = (influencerId, productId) => ({
  type: SHOW_SHIPPING_MODAL_FORM,
  payload: {
    influencerId,
    productId
  }
})

export const hideShippingFormModal = () => ({
  type: HIDE_SHIPPING_MODAL_FORM
})

export const setShippingFormModal = (payload = {}) => ({
  type: SET_SHIPPING_MODAL_FORM,
  payload
})

export const submitShippingForm = () => ({
  type: SUBMIT_SHIPPING_FORM
})

export const submittedShippingForm = () => ({
  type: SUBMITTED_SHIPPING_FORM
})

export const updateShippingDetails = (influencerId, productId, parameters = {}) => (
  (dispatch, getState) => {
    dispatch(submitShippingForm());
    const { entities: { influencers } } = getState();

    const currentInfluencer = influencers.byId[influencerId];
    let params = parameters;

    if (currentInfluencer.partnerships.products[productId].status === 'needsShipping') {
      if (params.nonShippable) {
        delete (params.carrierCode);
        delete (params.trackingNumber);
        delete (params.nonShippable);
        params = { ...params, status: POST_STATUS.active };
        currentInfluencer.partnerships.products[productId].status = POST_STATUS.active;
      } else {
        params = { ...params, status: POST_STATUS.shipped };
        currentInfluencer.partnerships.products[productId].status = POST_STATUS.shipped;
      }
    }

    firebase.influencerProduct(influencerId, productId).update(params, (error) => {
      if (!error) {
        dispatch(submittedShippingForm());
        dispatch(updateInfluencer(influencerId, currentInfluencer));
      }
    });
  }
);

export const updateDeliveryDetails = (payload = {}) => ({
  type: UPDATE_DELIVER_DETAILS,
  payload
})

export const receiveDeliveryDetails = () => ({
  type: RECEIVE_DELIVERY_DETAILS
})

export const requestDeliveryDetails = (carrierCode, trackingNumber) => (dispatch) => {
  dispatch({ type: REQUEST_DELIVERY_DETAILS });

  firebase.auth.currentUser.getIdToken(true).then(idToken => (
    axios.get(Routes.shipengine, {
      params: { carrierCode, trackingNumber },
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }).then((response) => {
      const { data } = response;

      dispatch(updateDeliveryDetails({
        actualDeliveryDate: data.actual_delivery_date,
        shipDate: data.ship_date,
        estimatedDeliveryDate: data.estimated_delivery_date,
        events: data.events,
      }));
    }).catch(err => (
      console.log(err)
    ))
  )).catch(err => (
    console.log('Unauthorize!', err)
  )).finally(() => (
    dispatch(receiveDeliveryDetails())
  ));
};
