const API_URL = process.env.REACT_APP_FUNCTION_API_URL;

const baseUrl = path => (`${API_URL}/api/${path}`);

const Routes = {
  spendCredits: baseUrl('spendCredits'),
  shipengine: baseUrl('shipengine'),
  paySubscription: baseUrl('paySubscription'),
  plans: baseUrl('plans'),
  currentSubscription: baseUrl('subscription'),
  updatePlan: baseUrl('updatePlan'),
  postReportCsv: baseUrl('download/postReportCsv'),
  addCredits: baseUrl('addCredits'),
};

export default Routes;
