import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Layout,
  Row,
  Spin,
} from 'antd';
import { fetchCampaigns } from 'actions/campaigns';
import { getCategories } from 'actions/categories';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import CampaignListItem from './CampaignListItem';
import CampaignFilters from './CampaignFilters';

const { Content } = Layout;

class CampaignList extends Component {
  constructor(props) {
    super(props);
    this.props.getCategories();
    this.props.fetchCampaigns();
  }

  render() {
    let { campaigns, brands, category } = this.props;
    let dataSource = category ? campaigns.byCategory[category] : campaigns.all.filter( (id) => campaigns.byId[id].isListed === true )
    let ListItems = dataSource.map( (item) => {
      let campaign = campaigns.byId[item];
      let brand = brands.byId[campaign.brandUID];

      return (
        <div key={item}>
          <Link to={routes.toCampaignSummaryPath(item)} >
            <CampaignListItem key={item}  campaign={campaign} brand={brand} />
          </Link>
        </div>
      )
    } )

    return (
      <Content style={{ background: '#fff', minHeight: 600 }} className='content-padding'>
        <Row type="flex" justify="space-around">
          <div className="min-layout-content">
            <h1 className='m-0'>All Listings</h1>
            <CampaignFilters/>
            <Spin spinning={campaigns.isFetching}>
              <div className="mt-3">{ ListItems }</div>
            </Spin>
          </div>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.entities.campaigns,
  brands: state.entities.brands,
  category: state.ui.campaignList.category
});

const mapDispatchToProps = (dispatch) => ({
  fetchCampaigns: () => dispatch(fetchCampaigns()),
  getCategories: () => dispatch(getCategories())
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
