import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import {
  Row,
  Col,
} from 'antd';
import { connect } from 'react-redux';
import EmailReportButton from 'components/CampaignDashboard/Banner/EmailReportButton';
import _ from 'lodash';

class CampaignBanner extends Component {

  render() {
    const { currentCampaign } = this.props

    return (
      <Row className={css(styles.bannerWrapper)}>
        <Col span={12} className="pb-5">
          <h1 className={css(styles.campaignTitle)} className="mb-0">
            { this.props.campaignName }
            { !_.isEmpty(currentCampaign) && !currentCampaign.isListed && <span className="type-ebold size-8px uppercase ml-2" style={{position: 'relative', top: '-8px', color: '#ff5a5a'}}> (Removed From Store) </span> }
          </h1>
        </Col>


        <Col span={6} offset={6} className={css(styles.userAvatar)}>
          <div className={css(styles.notifIcon)}>
            <EmailReportButton />
          </div>
        </Col>
      </Row>
    );
  }
}

const styles = StyleSheet.create({
  campaignTitle: {
    fontSize: '26',
    lineHeight: 1.7
  },
  userAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  userInfo: {
    marginLeft: '20px'
  },
  notifIcon: {
    marginLeft: '40px'
  }
});

const mapStateToProps = (state) => ({
  currentCampaign: state.ui.campaignDashboard.currentCampaign
})

export default connect(mapStateToProps)(CampaignBanner)
