import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import SignOutButton from 'components/Signout';
import { AuthUserContext } from 'lib/Session';
import { compose } from 'recompose';
import CampaignSubMenu from 'components/Layouts/SideNav/CampaignSubMenu';
import { connect } from 'react-redux';
import { setOwnedCampaigns } from 'actions/ui/sideNav';
import { ReactComponent as IconPosts } from 'images/icon-all-posts.svg';
import { ReactComponent as IconListings } from 'images/icon-all-listings.svg';
import { ReactComponent as IconSettings } from 'images/icon-settings.svg';
import { unListed } from 'selectors/campaigns';
import * as routes from 'constants/routes';

class CampaignMenus extends Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.authUserData && !this.props.authUserData ) {
      this.props.setOwnedCampaigns(nextProps.authUserData.uid)
    }
  }

  render() {
    const { archivedCampaigns } = this.props;
    const path = window.location.pathname.split("/")
    let id = path[1] + "/" + (path[2] || '')

    return (
      <div>
        <div style={{  marginBottom: '11px', paddingTop: '11px', paddingBottom: '18px', borderTop: '1px solid #3c3c3c', borderBottom: '1px solid #3c3c3c'}}>
          <CampaignSubMenu archivedCampaigns={archivedCampaigns} />
          <Menu
            theme="dark" mode="inline"
            selectedKeys={[id]}
            defaultOpenKeys={['campaigns']}
            className={['trend-menu-inline']}
            style={{ marginTop: '2px' }}
          >
            <Menu.Item key="campaigns/new">
              <Link to="/campaigns/new">
                <Icon type="plus" style={{color: '#888', padding: '9px', background: '#161616', width: '36px', height: '36px', border: '1px solid #3C3C3C'}} />
                <span style={{fontSize: '10px'}}>Create New Campaign</span>
              </Link>
            </Menu.Item>
          </Menu>
        </div>

        <Menu theme="dark" mode="inline"
            selectedKeys={[id]}
            defaultOpenKeys={['campaigns']}
            className={['trend-menu-inline secondary']}
          >
          <Menu.Item key="posts/">
            <Link to={"/posts"}>
              <IconPosts/>
              <span>All Posts</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="products/">
            <Link to={"/products"}>
              <IconListings/>
              <span>All Listings</span>
            </Link>
          </Menu.Item>
        </Menu>

        <Menu
          theme="dark" mode="inline"
          selectedKeys={[id]}
          defaultOpenKeys={['campaigns']}
          className={['trend-menu-inline settings']}
        >
          <Menu.Item key="store/edit">
            <Link
              to={routes.ACCOUNT_SETTINGS_PATH}
            >
              <IconSettings style={{width: '20px', height: '19px'}} />
              <span>Account Settings</span>
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    );
  }

}
const mapStateToProps = (state) => ({
  campaigns: state.entities.campaigns,
  unlistedCampaigns: unListed(state.ui.sideNav.ownedCampaigns, state.entities.campaigns),
  archivedCampaigns: state.ui.sideNav.ownedArchivedCampaigns,
  authUserData: state.entities.session.authUserData
})

const mapDispatchToProps = (dispatch) => ({
  setOwnedCampaigns: (brandUID) => dispatch(setOwnedCampaigns(brandUID))
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CampaignMenus);
