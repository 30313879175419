import moment from 'moment';
import { firebase } from 'lib/Firebase';
import { updateBrandDetails } from 'actions/brands';
import { POST_STATUS } from 'constants/posts';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import {
  DATE_TIMEZONE_FORMAT,
  MESSAGE_TYPE,
} from 'constants/common';
import { updateInfluencer } from 'actions/influencers';
import { updateCampaignAppliedUID } from 'actions/campaigns';
import { removeInfluencerInList } from 'actions/ui/influencerList';
import { fetchCampaignNavLinkStatusCounts } from 'actions/ui/campaignDashboard';
import { sendNewMessage } from './messages';

export const rejectInfluencer = ({
  rejectReason,
  influencerUID,
  influencer,
  campaignUID,
  campaign,
  brandUID,
}, callback = null) => {
  return (dispatch) => {
    const brandDetailsRef = firebase.brand(brandUID).child(`details`);

    brandDetailsRef.once('value', (snapshot) => {
      const brandDetails = snapshot.val();

      const details = {
        credits: brandDetails.credits,
        standardCredits: brandDetails.standardCredits
      };

      if (influencer.isVIP) {
        details.credits += 1;
      } else {
        details.standardCredits += 1;
      }

      brandDetailsRef.update({ ...details }).then(() => {
        dispatch(updateBrandDetails(brandUID, { ...details }));

        const influencerCampaignRef = firebase.influencer(influencerUID).child(`partnerships/products/${campaignUID}`)

        influencerCampaignRef.once('value', (campaignSnapshot) => {
          if (!campaignSnapshot) {
            callback(false)
            return;
          }

          influencerCampaignRef.update({ status: POST_STATUS.rejected });

          // remove all posts, base on mobile code
          influencerCampaignRef.update({ posts: null });

          const appliedUID = {
            status: CAMPAIGN_APPLIED_UID_STATUS.rejected,
            isUnread: true,
            timestamp: moment().format(DATE_TIMEZONE_FORMAT)
          };

          firebase.activeProduct(campaignUID).child(`appliedUID/${influencerUID}`).update(appliedUID).then(() => {
            dispatch(updateCampaignAppliedUID(campaignUID, influencerUID, appliedUID));
            dispatch(updateInfluencer(influencerUID, { appliedUIDStatus: appliedUID.status }));
          })

          const text = `Your approval for ${brandDetails.name} has been rescinded. Message from the brand: ${rejectReason}`;

          const sender = {
            uid: brandUID,
            name: brandDetails.name,
            type: MESSAGE_TYPE.brand
          };

          const recipient = [{
            uid:  influencerUID,
            name: influencer.username,
            type: MESSAGE_TYPE.influencer
          }];

          const partnership = {
            uid: campaignUID,
            name: campaign.name,
            type: MESSAGE_TYPE.product
          };

          dispatch(sendNewMessage(
            text,
            sender,
            recipient,
            partnership,
            true
          ));

          // TODO:
          //  do push notification here

          dispatch(removeInfluencerInList(influencerUID));
          dispatch(fetchCampaignNavLinkStatusCounts(campaignUID));
          callback(true);
        });
      }).catch((e) => {
        callback(false);
        console.log(`Error on updating credits/standardCredits: `, e);
      });
    }).catch((e) => {
      callback(false);
      console.log(`Error on getting brandDetailsRef: `, e);
    });
  }
}
