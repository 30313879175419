import {
  fetchRequest,
  receiveRequest,
} from './common/request';

export const POST_FETCH_REQUEST = 'POST_FETCH_REQUEST';

export const POST_RECEIVE_REQUEST = 'POST_RECEIVE_REQUEST';

export const ADD_POST = 'ADD_POST';

export const ADD_POSTS = 'ADD_POSTS';

export const UPDATE_POST = 'UPDATE_POST';

export const UPDATE_POSTS = 'UPDATE_POSTS';

const defaultState = {
  byId: {
    // 1: {
    // ... attr
    // }
  },
  all: [
    // ids
    // 1,2,3
  ],
  byCampaignId: {},
  isFetching: false,
};

export const addPosts = (state, posts) => ({
  ...state,
  byId: {
    ...state.byId,
    ...posts,
  },
  all: [
    ...new Set(state.all.concat(Object.keys(posts))),
  ],
});

export const addPost = (state, post) => ({
  ...state,
  byId: {
    ...state.byId,
    ...post,
  },
  all: [
    ...new Set(state.all.concat(Object.keys(post))),
  ],
});

const updatePost = (state, postUID, post) => ({
  ...state,
  byId: {
    ...state.byId,
    [postUID]: {
      ...state.byId[postUID],
      details: {
        ...state.byId[postUID].details,
        ...post,
      },
    },
  },
});

const updatePosts = (state, posts) => {
  let newState = state;

  Object.keys(posts).forEach((postUID) => {
    newState = updatePost(
      newState,
      postUID,
      posts[postUID].details,
    );
  });

  return newState;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ADD_POST:
      return addPost(state, action.post);

    case ADD_POSTS:
      return addPosts(state, action.posts);

    case UPDATE_POST:
      return updatePost(state, action.postUID, action.post);

    case UPDATE_POSTS:
      return updatePosts(state, action.posts);

    case POST_FETCH_REQUEST:
      return fetchRequest(state);

    case POST_RECEIVE_REQUEST:
      return receiveRequest(state);

    default:
      return state;
  }
};
