import React, { Component } from 'react'

const MY_SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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'
const slack = require('slack-notify')(MY_SLACK_WEBHOOK_URL);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    const shortStack = 'Staging:' + error.stack.split("\n").slice(0,5).join("\n")
    if (process.env.NODE_ENV === 'staging') {
      slack.send({
        channel: '#random',
        text: shortStack
      });
    } else {
      console.log(process.env.NODE_ENV + ': ' + shortStack)
    }

  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
