/* eslint no-console: ["error", { allow: ["log"] }] */
import axios from 'axios';
import qs from 'qs';
import UUIDv4 from 'uuid/v4';
import { firebase } from 'lib/Firebase';

// TODO:
//  oauthUrl & payoutUrl should in .env per environment
// const Client = {
//
// }

class Paypal {
  constructor() {
    // TODO:
    //  this should base on what environment,
    //  if not production else all is link should point to sandbox
    this.oauthUrl = 'https://api.sandbox.paypal.com/v1/oauth2/token';
    this.payoutUrl = 'https://api.sandbox.paypal.com/v1/payments/payouts';
  }

  getCredentials = (callback) => {
    firebase.paypalRef().once('value', (snapshot) => {
      const paypalCreds = snapshot.val();

      if (paypalCreds) {
        callback(true, {
          username: paypalCreds.client,
          password: paypalCreds.secret,
        });
      } else {
        callback(false, { error: 'Couldn\'t find paypal creds' });
      }
    }).catch((error) => {
      callback(false, { error });
    });
  }

  paypalAccessToken = (callback) => {
    this.getCredentials((isSuccess, { username, password, error }) => {
      if (!isSuccess) {
        callback(false, error);
        return;
      }

      const headers = {
        Accept: 'application/json',
        'Accept-Language': 'en_US',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8;',
      };

      const data = qs.stringify({ grant_type: 'client_credentials' });

      axios({
        method: 'post',
        url: this.oauthUrl,
        data,
        headers,
        auth: {
          username,
          password,
        },
      }).then((response) => {
        if (response.statusText === 'OK') {
          callback(true, { accessToken: response.data.access_token });
        } else {
          callback(false, { error: 'Got no accessToken' });
        }
      }).catch((e) => {
        callback(false, { error: e });
      });
    });
  }

  pay = (
    influencerUID,
    recipientEmail,
    campaignUID,
    amount,
    callback,
  ) => {
    this.paypalAccessToken((isSuccess, { accessToken, error }) => {
      if (!isSuccess) {
        callback(false, error);
        return;
      }

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        sender_batch_header: {
          email_subject: 'Trend Payment Confirmation',
          email_message: 'Thank you for your outstanding work. This is an automated email confirming that you\'ve been paid for your recent work with Trend.',
        },
        items: [{
          amount: {
            value: amount,
            currency: 'USD',
          },
          receiver: recipientEmail,
          recipient_type: 'EMAIL',
          note: 'Thanks for your patronage!',
          sender_item_id: UUIDv4(),
        }],
      };

      axios.post(this.payoutUrl, data, { headers }).then((response) => {
        if (response.data.batch_header) {
          const payoutBatchId = response.data.batch_header.payout_batch_id;

          firebase.payoutsRef().child(payoutBatchId).update({
            recipientEmail,
            influencer: influencerUID,
            product: campaignUID,
            timestamp: new Date().getTime(),
          });

          callback(true, { payoutBatchId });
        } else {
          callback(false, 'Couln\'t found batch_header');
        }
      }).catch((e) => {
        callback(false, { error: e });
      });
    });
  }
}

export default Paypal;
