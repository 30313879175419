import { firebase } from 'lib/Firebase';
import {
  CURRENT_CAMPAIGN,
  SET_APPROVED_INFLUENCER_COUNT,
  SET_APPLIED_INFLUENCER_COUNT,
  RESET_CAMPAIGN_DASHBOARD,
  SET_NEW_MESSAGES_COUNT,
} from 'reducers/ui/campaignDashboard';

import { fetchCampaignAppliedInfluencerUIDs, fetchCampaignApprovedInfuencerUIDs } from 'api/influencers';
import fetchNewMessages from 'api/messages';

export const setCurrentCampaign = campaign => ({
  type: CURRENT_CAMPAIGN,
  campaign,
});

export const setApprovedInfluencerCount = influencers => ({
  type: SET_APPROVED_INFLUENCER_COUNT,
  influencers,
});

export const setAppliedInfluencerCount = influencers => ({
  type: SET_APPLIED_INFLUENCER_COUNT,
  influencers,
});

export const setNewMessagesCount = messages => ({
  type: SET_NEW_MESSAGES_COUNT,
  messages,
});

export const resetCampaignDashboard = () => ({
  type: RESET_CAMPAIGN_DASHBOARD,
});

export const addCurrentCampaign = (campaignUID, callback = null) => dispatch => (
  firebase.activeProduct(campaignUID).once('value', (snapshot) => {
    let campaign = {};

    if (!snapshot.exists()) {
      firebase.completedProduct(campaignUID).once('value', (completedSnapshot) => {
        campaign = { campaignUID, ...completedSnapshot.val() };
        dispatch(setCurrentCampaign(campaign));
        if (callback) { callback(campaign); }
      });
    } else {
      campaign = { campaignUID, ...snapshot.val() };
      dispatch(setCurrentCampaign(campaign));
      if (callback) { callback(campaign); }
    }
  })
);

export const fetchCampaignNavLinkStatusCounts = campaignUID => (dispatch) => {
  fetchCampaignAppliedInfluencerUIDs(campaignUID, (influencers) => {
    dispatch(setAppliedInfluencerCount(influencers));
  });

  fetchCampaignApprovedInfuencerUIDs(campaignUID, (influencers) => {
    dispatch(setApprovedInfluencerCount(influencers));
  });

  fetchNewMessages(campaignUID, (messages) => {
    dispatch(setNewMessagesCount(messages));
  });
};
