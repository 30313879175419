/* eslint no-console: ["error", { allow: ["log"] }] */
import { firebase } from 'lib/Firebase';
import { MESSAGE_TYPE } from 'constants/common';
import {
  ADD_MESSAGES,
  MESSAGE_FETCH_REQUEST,
  MESSAGE_RECEIVE_REQUEST,
} from 'reducers/entities/messages';
import { congratulationMsg } from './ui/applicationsAction';
import { fetchInfluencer } from './influencers';

export const fetchRequest = () => ({ type: MESSAGE_FETCH_REQUEST });

export const receiveRequest = () => ({ type: MESSAGE_RECEIVE_REQUEST });

export const addMessages = messages => ({
  type: ADD_MESSAGES,
  messages,
});

export const fetchMessage = messageUID => (dispatch) => {
  dispatch(fetchRequest());

  firebase.message(messageUID).on('value', (snapshot) => {
    const message = snapshot.val();

    if (!message) {
      dispatch(receiveRequest());
      return;
    }

    const { users = {} } = message;

    Object.keys(users).forEach((userUID) => {
      const user = users[userUID];
      if (user.type === MESSAGE_TYPE.influencer) {
        dispatch(fetchInfluencer(userUID));
        dispatch(addMessages({
          [messageUID]: {
            messageUID,
            ...message,
          },
        }));
      }
    });
  }, (error) => {
    console.log('Error on fetching a thread: ', error);
    dispatch(receiveRequest());
  });
};

export const markThreadAsRead = messageID => (dispatch) => {
  dispatch(fetchRequest());
  firebase
    .message(messageID)
    .child('users')
    .orderByChild('type')
    .equalTo('brand')
    .once('value', (snapshot) => {
      if (!snapshot.val()) {
        dispatch(receiveRequest());
        return;
      }

      firebase.message(messageID).child(`users/${Object.keys(snapshot.val())[0]}`).update({ hasUnread: false }).then(() => {
        dispatch(receiveRequest());
      });
    }, (error) => {
      console.log('Error on fetching a thread: ', error);
      dispatch(receiveRequest());
    });
};

export const fetchMessages = (campaignUID, type) => (dispatch) => {
  dispatch(fetchRequest());

  firebase.messages().orderByChild('details/partnershipUID').equalTo(campaignUID).once('value', (snapshot) => {
    const messages = snapshot.val();

    if (!messages) {
      dispatch(receiveRequest());
      return;
    }

    Object.keys(messages).forEach((messageUID) => {
      const message = messages[messageUID];
      const {
        details,
        users,
        messages: messageThreads,
      } = message;

      if (users && details.partnershipType === type) {
        let isCongratsMsg = true;
        if (Object.keys(messageThreads).length === 1) {
          const [messageDetails] = Object.values(messageThreads);
          isCongratsMsg = messageDetails.text.slice(0, 16) === congratulationMsg().slice(0, 16);
        }

        if (Object.keys(messageThreads).length > 1 || isCongratsMsg) {
          dispatch(addMessages({
            [messageUID]: {
              messageUID,
              ...message,
            },
          }));
        }
      }
    });

    dispatch(receiveRequest());
  }, (error) => {
    console.log('Error on fetching brand: ', error);
    dispatch(receiveRequest());
  });
};
