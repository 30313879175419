export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_USERS = 'SET_USERS';

export const SET_USER = 'SET_USER';

const defaultState = {};

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_USER_DATA:
      return [...state, action.name];
    case SET_USERS:
      return [...state, action.users];
    case SET_USER:
      return {
        ...state,
        byId: {...state.byId, ...action.user},
        all: [ ...state.all, ...Object.keys(action.user) ]
      };
    default:
      return state;
  }
};
