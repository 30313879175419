import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const HeaderText = ({ bold = false, children} = {}) => (
  <h3 className={bold ? css(styles.bold) : css(styles.normal)}>
    {children}
  </h3>
);

const styles = StyleSheet.create({
  normal: {
    fontWeight: 'normal'
  },
  bold: {
    fontWeight: '600px'
  }
});

export default HeaderText;
