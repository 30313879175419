export const SET_ALL_INFLUENCERS_IN_BRAND = 'SET_ALL_INFLUENCERS_IN_BRAND';

const defaultState = {
  allInfluencersInBrand: []
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_ALL_INFLUENCERS_IN_BRAND:
      return {
        ...state,
        allInfluencersInBrand: action.influencerUIDs
      }

    default:
      return state;
  }
}
