import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const UpperCaseLabel = ({ bold = true, children} = {}) => (
  <div className="caps-heading ">
    {children}
  </div>
);

const styles = StyleSheet.create({
  label: {
    color: '#5b6572',
    fontSize: '8px',
    letterSpacing: '0.5px'
  }
});


export default UpperCaseLabel;
