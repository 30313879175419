import React from 'react';

const logo = 'https://static.wixstatic.com/media/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.png/v1/fill/w_218,h_40,al_c,q_80,usm_1.20_1.00_0.01/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.webp';
const Logo = () => (
  <div style={{ padding: '20px 0px', 'textAlign': 'center', position: 'absolute', left: '0', right: '0', zIndex: '1', backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))'}}>
    <img src={logo} alt="logo" className="logo" width='70px'/>
  </div>
);

export default Logo;
