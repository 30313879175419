export const SET_MESSAGE_LIST = 'SET_MESSAGE_LIST';

const defaultState = [];

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_MESSAGE_LIST:
      return [
        ...new Set(Object.keys(action.payload.messages))
      ]
    default:
      return state;
  }
}
