import {
  fetchRequest,
  receiveRequest
} from './common/request';

export const RECEIVE_CHAT_MESSAGES = 'RECEIVE_CHAT_MESSAGES';

export const CHAT_MESSAGE_FETCH_REQUEST = 'CHAT_MESSAGE_FETCH_REQUEST';

export const CHAT_MESSAGE_RECEIVE_REQUEST = 'CHAT_MESSAGE_RECEIVE_REQUEST';

let defaultState = {
  all: {},
  isFetching: false
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case RECEIVE_CHAT_MESSAGES:
      return {
        ...state,
        all: action.chat_messages,
        isFetching: false
      }
    case CHAT_MESSAGE_FETCH_REQUEST:
      return fetchRequest(state);
    case CHAT_MESSAGE_RECEIVE_REQUEST:
      return receiveRequest(state);

    default:
      return state;
  }
}
