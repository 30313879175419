import React, { Component } from 'react';
import { Row } from 'antd';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Overview from 'components/CampaignDashboard/Overviews';
import Applications from 'components/CampaignDashboard/Applications';
import Influencers from 'components/CampaignDashboard/Influencers';
import Messages from 'components/CampaignDashboard/Messages';
import Posts from 'components/CampaignDashboard/Posts';
import TabLinks from 'components/CampaignDashboard/TabContent/TabLinks';
import CampaignBanner from 'components/CampaignDashboard/Banner/CampaignBanner';
import { withFirebase } from 'lib/Firebase';
import { AuthUserContext } from 'lib/Session';
import * as routes from 'constants/routes';
import {
  addCurrentCampaign,
  fetchCampaignNavLinkStatusCounts,
  resetCampaignDashboard,
} from 'actions/ui/campaignDashboard';
import MinLayout from 'components/Common/MinLayout';

class CampaignContent extends Component {
  componentWillMount() {
    const campaignUID = this.props.match.params.campaignId;

    this.props.addCurrentCampaign(campaignUID);
    this.props.fetchCampaignNavLinkStatusCounts(campaignUID);
  }

  componentWillReceiveProps({ match }) {
    if (match.params.campaignId !== this.props.match.params.campaignId) {
      this.props.addCurrentCampaign(match.params.campaignId)
    }
  }

  componentWillUnmount() {
    this.props.resetCampaignDashboard();
  }

  defaultSelectedKeys = () => {
    const defaultKey = 'summary';
    const arrPathname = this.props.location.pathname.split('/');

    return [arrPathname[3] || defaultKey];
  }

  render() {
    const {
      match,
      approvedInfluencerCount,
      appliedInfluencerCount,
      newMessagesCount,
      currentCampaign,
    } = this.props;
    const campaign = currentCampaign || {};

    return (
      <div>
        <div style={{ minHeight: '175px', paddingTop: '38px' }}>
          <Row type="flex" justify="space-around">
            <MinLayout>
              <CampaignBanner
                campaignName={campaign.name}
              />
            </MinLayout>
          </Row>
          <div style={{ borderBottom: '1px solid #dbdfe8' }}>
            <Row type="flex" justify="space-around">
              <MinLayout>
                <AuthUserContext.Consumer>
                  {authUser => (
                    (authUser && authUser.uid === campaign.brandUID)
                      && (
                        <TabLinks
                          matchUrl={match.url}
                          defaultSelectedKeys={this.defaultSelectedKeys()}
                          approveCount={approvedInfluencerCount}
                          appliedCount={appliedInfluencerCount}
                          newMessagesCount={newMessagesCount}
                        />
                      )
                  )}
                </AuthUserContext.Consumer>
              </MinLayout>
            </Row>
          </div>
        </div>
        <Row>
          <Route path={routes.CAMPAIGN_SUMMARY_PATH} component={Overview} />
          <Route path="/campaigns/:campaignId/applications" component={Applications} />
          <Route path="/campaigns/:campaignId/my_influencers" component={Influencers} />
          <Route path="/campaigns/:campaignId/posts" component={Posts} />
          <Route path="/campaigns/:campaignId/messages" component={Messages} />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentCampaign: state.ui.campaignDashboard.currentCampaign,
  approvedInfluencerCount: state.ui.campaignDashboard.approvedInfluencerCount,
  appliedInfluencerCount: state.ui.campaignDashboard.appliedInfluencerCount,
  newMessagesCount: state.ui.campaignDashboard.newMessagesCount,
});

const mapDispatchToProps = dispatch => ({
  addCurrentCampaign: (id) => { dispatch(addCurrentCampaign(id)); },
  fetchCampaignNavLinkStatusCounts: (id) => { dispatch(fetchCampaignNavLinkStatusCounts(id)); },
  resetCampaignDashboard: () => { dispatch(resetCampaignDashboard()); },
});

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
)(CampaignContent);
