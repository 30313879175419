import React, { Component } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { connect } from 'react-redux';
import { selectCampaignById } from 'selectors/campaigns';
import MinLayout from 'components/Common/MinLayout';
import { fetchCampaignPosts, resetCampaignPosts } from 'actions/ui/campaignPosts';
import CampaignSummary from './CampaignSummary';
import RightSideInfo from './RightSideInfo';
import RecentPost from './RecentPost';
import PostGuideLines from './PostGuideLines';
import Description from './Description';
import CampaignImage from './CampaignImage';
import CampaignSettings from './CampaignSettings';

class Overview extends Component {
  constructor(props) {
    super(props);
    const { fetchPosts, match: { params } } = this.props;

    fetchPosts(params.campaignId);
  }

  componentDidUpdate() {
    const { fetchPosts, match: { params } } = this.props;

    fetchPosts(params.campaignId);
  }

  componentWillUnmount() {
    const { resetPosts } = this.props;
    resetPosts();
  }

  render() {
    const { campaign, match: { params } } = this.props;

    return (
      <div style={{ paddingBottom: '79px' }}>
        <div className="ant-row-flex ant-row-flex-space-around" style={{ margin: '30px 0' }}>
          <MinLayout>
            <Row gutter={64}>
              <Col span={16} style={{ background: '#ffffff' }}>
                <Skeleton title={false} loading={!campaign} paragraph={{ rows: 4 }} active>
                  <CampaignImage
                    image={campaign.image}
                    campaignId={params.campaignId}
                    brandUID={campaign.brandUID}
                  />
                  <CampaignSummary
                    campaignId={params.campaignId}
                    brandUID={campaign.brandUID}
                  />
                  <PostGuideLines guidelines={campaign.guidelines} />
                </Skeleton>
              </Col>
              <Col span={8}>
                <RecentPost campaignId={params.campaignId} />
                <RightSideInfo
                  website={campaign.url}
                  postPerInfluencer={campaign.numberOfPosts}
                  productValue={campaign.value}
                  category={campaign.category}
                  hashtags={campaign.hashtags}
                  brandtags={campaign.brandtags}
                  states={campaign.states}
                />
              </Col>
            </Row>
          </MinLayout>
        </div>
        <CampaignSettings
          campaignUID={params.campaignId}
          brandUID={campaign.brandUID}
          isListed={campaign.isListed}
          isCompleted={campaign.isCompleted}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPosts: campaignId => dispatch(fetchCampaignPosts(campaignId)),
  resetPosts: () => dispatch(resetCampaignPosts()),
});

const mapStateToProps = (state, ownProps) => ({
  campaign: selectCampaignById(state, ownProps.match.params.campaignId),
});

Overview.defaultProps = {
  campaign: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Overview);
