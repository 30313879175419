import React, { Component } from 'react';
import {
  Row,
  Col,
} from 'antd';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import { AuthUserContext } from 'lib/Session';
import {
  resetPostsAnalytics,
  getPerCampaignPosts,
} from 'actions/ui/postsAnalytics';
import { updatePostDetails } from 'actions/posts';
import { totalPostSummary } from 'selectors/postsAnalytics';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import MinLayout from 'components/Common/MinLayout';
import ViewOption from './ViewOption';
import GridLayout from './GridLayout';
import ListLayout from './ListLayout';

class Posts extends Component {
  constructor(props) {
    super(props);

    const {
      getPerCampaignPosts: campaignPosts,
      match,
    } = this.props;

    campaignPosts({
      status: CAMPAIGN_APPLIED_UID_STATUS.approved,
      campaignId: match.params.campaignId,
    });
  }

  componentWillUnmount() {
    const {
      resetPosts: reset,
      firebase,
    } = this.props;

    reset();
    firebase.activeProducts().off();
    firebase.influencers().off();
  }

  onClickFavorite = ({ influencerUID, postUID, isFavorite } = {}) => {
    const {
      updatePostDetails: updatePost,
    } = this.props;

    updatePost(influencerUID, postUID, { isFavorite });
  }


  render() {
    const {
      totalPostSummary: {
        posts,
        likes,
        comments,
        impressions,
      },
      view,
    } = this.props;

    return (
      <div>
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          style={{ margin: '30px 0' }}
        >
          <MinLayout>
            <Col span={4}>
              <div
                style={{
                  fontFamily: 'truenoextrabold',
                  fontSize: '17px',
                  fontStyle: 'italic',
                  color: '#000',
                  lineHeight: '1.4',
                }}
              >
                {posts.toLocaleString()}
              </div>
              <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>LIVE POSTS</div>
            </Col>
            <Col span={4}>
              <div
                style={{
                  fontFamily: 'truenoextrabold',
                  fontSize: '17px',
                  fontStyle: 'italic',
                  color: '#000',
                  lineHeight: '1.4',
                }}
              >
                {likes.toLocaleString()}
              </div>
              <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>LIKES</div>
            </Col>
            <Col span={4}>
              <div
                style={{
                  fontFamily: 'truenoextrabold',
                  fontSize: '17px',
                  fontStyle: 'italic',
                  color: '#000',
                  lineHeight: '1.4',
                }}
              >
                {comments.toLocaleString()}
              </div>
              <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>COMMENTS</div>
            </Col>
            <Col span={4}>
              <div
                style={{
                  fontFamily: 'truenoextrabold',
                  fontSize: '17px',
                  fontStyle: 'italic',
                  color: '#000',
                  lineHeight: '1.4',
                }}
              >
                {impressions.toLocaleString()}
              </div>
              <div style={{ fontSize: '8px', color: '#5b6572', letterSpacing: '0.5px' }}>IMPRESSIONS</div>
            </Col>
          </MinLayout>
        </Row>
        <Row type="flex" justify="space-around">
          <MinLayout>
            <div style={{ marginTop: '10px' }}>
              <AuthUserContext.Consumer>
                {authUser => (authUser && <ViewOption authUser={authUser} />)}
              </AuthUserContext.Consumer>
              <div style={{ marginTop: '10px' }}>
                {
                  view === 'grid'
                    ? <GridLayout onClickFavorite={this.onClickFavorite} />
                    : <ListLayout onClickFavorite={this.onClickFavorite} />
                }
              </div>
            </div>
          </MinLayout>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  view: state.ui.modeView.view,
  totalPostSummary: totalPostSummary(state.entities.posts.byId, state.ui.postsAnalytics),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updatePostDetails: (influencerUID, postUID, post) => dispatch(updatePostDetails(
    influencerUID,
    ownProps.match.params.campaignId,
    postUID,
    post,
  )),
  getPerCampaignPosts: args => dispatch(getPerCampaignPosts(args)),
  resetPosts: () => dispatch(resetPostsAnalytics()),
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
)(Posts);
