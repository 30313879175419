import { combineReducers } from 'redux';
import campaignList from './campaignList';
import modeView from './creativeAssets';
import postList from './postList';
import influencerList from './influencerList';
import postsAnalytics from './postsAnalytics';
import sideNav from './sideNav';
import recentPosts from './recentPosts';
import campaignSummary from './campaignSummary';
import applicationsAction from './applicationsAction';
import shippingFormModal from './shippingFormModal';
import campaignHistory from './campaignHistory';
import campaignDashboard from './campaignDashboard';
import campaignPosts from './campaignPosts';
import storeAnalytics from './storeAnalytics';

export default combineReducers({
  campaignList,
  modeView,
  postList,
  influencerList,
  postsAnalytics,
  sideNav,
  recentPosts,
  campaignSummary,
  campaignHistory,
  applicationsAction,
  shippingFormModal,
  campaignDashboard,
  campaignPosts,
  storeAnalytics
});
