import React, { Component } from 'react';
import { Layout, Button, message } from 'antd';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { Link } from 'react-router-dom';
import 'styles/base/antd-custom.less'

import trendLogo from 'images/trend@2x.png'

import { withFirebase } from 'lib/Firebase';

const { Content } = Layout;

const flStyle = {
  marginTop: '2em',
  fontSize: '9px',
  letterSpacing: '0.2em',
  textTransform: 'uppercase',
  color: '#fff'
}

const PasswordForgetPage = () => (
  <div className="bg-black">
    <div style={{textAlign: 'center', padding: '4rem 0'}}>
      <img alt='Trend' src={trendLogo} width="162" height="31" style={{ display: 'inline-block'}}/>
    </div>
    <h1 style={{textAlign: 'center', color: '#fff'}}>Forgot Password</h1>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSuccess = () => {
    message.success('An instruction is sent to your email to recover your password.');
  };


  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.onSuccess();
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <div>
        <Layout>
          <Content style={{ padding: 24, background: '#000'}}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={6}>
                <Form layout="vertical" onSubmit={this.onSubmit}>
                  <Form.Item>
                    <label className="trend-label">Email</label>
                    <Input type="text" size="large" name="email" value={this.state.email} onChange={this.onChange} className="trend-input" />
                  </Form.Item>
                  <Button block size="large" type="submit" disabled={isInvalid} className="trend-btn" onClick={this.onSubmit}>Reset My Password</Button>
                  {error && <p>{error.message}</p>}
                  <p style={flStyle} align="middle">
                    Have an account? 
                    &nbsp;
                    <Link to="/login">Login</Link>
                  </p>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to="/forgot_password">Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
