import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux'
import {
  Layout,
  Button,
  Form,
  Input,
  Row,
  Col
} from 'antd';
import { withFirebase } from 'lib/Firebase';
import trendLogo from 'images/trend@2x.png'
import { PasswordForgetLink } from 'components/PasswordForget';
import { doSignInWithEmailAndPassword } from 'actions/ui/login';

const flStyle = {
  marginTop: '2em',
  fontSize: '9px',
  letterSpacing: '0.2em',
  textTransform: 'uppercase',
  color: '#fff'
};

const { Content } = Layout;

const defaultState = {
  email: '',
  password: '',
  error: null,
  loading: false
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = defaultState;
    this._isMounted = false;

    this.listener = this.props.firebase.auth.onAuthStateChanged(function(user) {
      if (user) {
        props.history.push('/posts');
      }
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState(() => ({ loading: true }));

    const {
      email,
      password
    } = this.state;

    this._isMounted = true;
    this.props.doSignInWithEmailAndPassword(email, password, (isSuccess, error) => {
      if (isSuccess) {
        if (this._isMounted) {
          this.setState(() => ({ ...defaultState }));
        }

        this.props.history.push('/products');
      } else if (this._isMounted) {
        this.setState({ error });
      }

      if (this._isMounted) {
        this.setState({ loading: false });
      }
    });
  }

  onChange = (e) => {
    const target = e.target;
    this.setState({ [target.name]: target.value });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      email,
      password,
      error
    } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <div className="bg-black">
        <div style={{textAlign: 'center', padding: '4rem 0'}}>
          <img alt='Logo' src={trendLogo} width="162" height="31" style={{ display: 'inline-block'}}/>
        </div>
        <h1 style={{textAlign: 'center', color: '#fff'}}>Log In To Your Account</h1>
        <Layout>
          <Content style={{ padding: 24, background: '#000'}}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={6}>
                <Form layout="vertical" onSubmit={this.onSubmit}>
                  <Form.Item>
                    <label className="trend-label">Email</label>
                    <Input type="text" onPressEnter={this.onSubmit} size="large" name="email" value={email} onChange={this.onChange} placeholder="email@email.com" className="trend-input"/>
                  </Form.Item>
                  <Form.Item style={{ padding: 0, margin: '0 0 0.5em 0'}}>
                    <label className="trend-label">Password</label>
                    <Input type="password" onPressEnter={this.onSubmit} size="large" name="password" value={password} onChange={this.onChange} placeholder="******"  className="trend-input"/>
                  </Form.Item>
                  <PasswordForgetLink/>
                  <Button loading={this.state.loading} block size="large" type="submit" disabled={isInvalid} className="trend-btn" onClick={this.onSubmit}>Log In</Button>
                  {error && <p style={{ color: 'white', textAlign: 'center', marginTop: '10px'}}>{error.message}</p>}
                  <p style={flStyle} align="middle">
                    Don't have an account? <Link to={`/signup`}>Sign Up</Link>
                  </p>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch, ownProps) => ({
  doSignInWithEmailAndPassword: (email, password, callback) => dispatch(doSignInWithEmailAndPassword(email, password, callback))
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
