import * as Promise from 'bluebird';
import { firebase } from 'lib/Firebase';
import {
  RESET_CAMPAIGN_POSTS,
  SET_CAMPAIGN_POSTS,
} from 'reducers/ui/campaignPosts';
import { addPosts } from 'actions/posts';
import { setInfluencer } from 'actions/influencers';

export const resetCampaignPosts = () => ({
  type: RESET_CAMPAIGN_POSTS,
});

export const setCampaignPosts = postIds => ({
  type: SET_CAMPAIGN_POSTS,
  payload: postIds,
});

export const fetchCampaignPosts = campaignUID => (dispatch) => {
  const campaignRef = firebase.activeProduct(campaignUID).child('appliedUID').orderByChild('status').equalTo('approved');

  campaignRef.once('value', (snapshot) => {
    const influencers = snapshot.val();

    if (!influencers) {
      return;
    }

    Promise.map(Object.keys(influencers), (influencerUID) => {
      const influencerCampaignRef = firebase.influencer(influencerUID);

      influencerCampaignRef.once('value', (childSnapshot) => {
        const influencer = childSnapshot.val();

        if (childSnapshot.exists() && influencer.partnerships && influencer.partnerships.products) {
          const campaignPosts = influencer.partnerships.products[campaignUID];
          let allPost = [];
          Object.keys(campaignPosts.posts || {}).forEach((postUID) => {
            const post = {
              [postUID]: {
                postUID,
                productUID: campaignUID,
                influencerUID,
                ...campaignPosts.posts[postUID],
              },
            };
            allPost = [postUID, ...allPost];
            dispatch(addPosts(post));
          });

          dispatch(setCampaignPosts(allPost));
          dispatch(setInfluencer({ [influencerUID]: influencer }));
        }
      });
    });
  });
};
