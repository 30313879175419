import { firebase } from 'lib/Firebase';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import { POST_STATUS } from 'constants/posts';

export const fetchBrandCampaignsApi = (brandId) => {
  const campaignBrandRef = firebase.activeProducts().orderByChild('brandUID').equalTo(brandId);
  return campaignBrandRef.once('value').then((snapshot) => {
    let campaigns = {};

    if (snapshot.exists()) {
      campaigns = snapshot.val();
    }

    return campaigns;
  });
};

export const fetchBrandCompletedCampaignsApi = (brandUID, onComplete) => {
  const campaignBrandRef = firebase.completedProducts().orderByChild('brandUID').equalTo(brandUID);
  return campaignBrandRef.once('value').then((snapshot) => {
    let campaigns = {};

    if (snapshot.exists()) {
      campaigns = snapshot.val();
    }

    onComplete(campaigns);
  });
};

export const fetchInfluencerAppliedCampaignApi = (influencerUID, campaignUID) => {
  const influencerCampaignRef = firebase.influencer(influencerUID).child('partnerships').child(`products/${campaignUID}`);
  return influencerCampaignRef.once('value').then((snapshot) => {
    let appliedCampaign = {};

    if (snapshot.exists()) {
      appliedCampaign = snapshot.val();
    }

    return appliedCampaign;
  });
};

export const checkIfAllApprovedAreCompleted = (campaignUID, onComplete = null) => {
  firebase.activeProduct(campaignUID).once('value').then((snapshot) => {
    const appliedUIDs = [];
    const jobStatuses = [];
    const campaign = snapshot.val();

    Object.entries(campaign.appliedUID || {}).forEach(([id, val]) => {
      if (val.status === CAMPAIGN_APPLIED_UID_STATUS.approved) {
        appliedUIDs.push(id);
      }
    });

    if (appliedUIDs.length > 0) {
      appliedUIDs.forEach((i) => {
        fetchInfluencerAppliedCampaignApi(i, campaignUID).then((data) => {
          jobStatuses.push(data.status === POST_STATUS.completed);

          if (jobStatuses.length === appliedUIDs.length) {
            const isAllComplete = jobStatuses.indexOf(false) < 0;

            onComplete(isAllComplete);
          }
        });
      });
    } else {
      onComplete(true);
    }
  });
};

export const fetchCampaignActiveOrComplete = (campaignUID, callback) => {
  firebase.activeProduct(campaignUID).once('value', (activeSnapshot) => {
    const activeCampaign = activeSnapshot.val();
    if (activeCampaign) {
      callback(true, { campaign: activeCampaign });
    } else {
      firebase.completedProduct(campaignUID).once('value', (completeSnapshot) => {
        callback(true, {
          campaign: completeSnapshot.val(),
        });
      }).catch((error) => {
        callback(false, { error });
      });
    }
  }).catch((error) => {
    callback(false, { error });
  });
};
