import moment from 'moment';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';

const toDate = timestamp => moment(timestamp, DATE_TIMEZONE_FORMAT).toDate();

export default (messageUIDs, messages, campaignUID, userUID) => (
  messageUIDs.filter((messageUID) => {
    const { details: { partnershipUID } } = messages[messageUID];
    return partnershipUID === campaignUID;
  }).sort((messageUIDA, messageUIDB) => {
    const { messages: messageA } = messages[messageUIDA];
    const {
      messages: messageB,
      users,
    } = messages[messageUIDB];

    const indexOfLastMessageA = Object.keys(messageA).length;
    const indexOfLastMessageB = Object.keys(messageB).length;

    const timestampA = Object.values(messageA)[indexOfLastMessageA - 1].timestamp;
    const timestampB = Object.values(messageB)[indexOfLastMessageB - 1].timestamp;

    const ascending = toDate(timestampA) < toDate(timestampB);

    if (users[userUID].hasUnread && ascending) {
      return 1;
    }

    if (!ascending) {
      return -1;
    }

    return 0;
  })
);
