import { fetchRequest, receiveRequest } from 'reducers/entities/common/request'

export const RECEIVE_CAMPAIGN_HISTORIES = 'RECEIVE_CAMPAIGN_HISTORIES'
export const HISTORY_FETCH_REQUEST = 'HISTORY_FETCH_REQUEST'
export const HISTORY_RECEIVE_REQUEST = 'HISTORY_RECEIVE_REQUEST'

let initialState = {
  archivedCampaigns: [],
  isFetchting: false
}

const campaignHistory = (state = initialState, action) => {
  switch(action.type) {
    case RECEIVE_CAMPAIGN_HISTORIES:
      return { ...state, archivedCampaigns: Object.keys(action.payload) }
    case HISTORY_FETCH_REQUEST:
      return fetchRequest(state);
    case HISTORY_RECEIVE_REQUEST:
      return receiveRequest(state)

    default:
      return state
  }
}

export default campaignHistory
