import _ from 'lodash';
import * as Promise from 'bluebird';
import { firebase } from 'lib/Firebase';
import { addPosts, fetchRequest, receiveRequest } from 'actions/posts';
import { fetchBrand, receiveBrand } from 'actions/brands';
import { POST_DETAIL_STATUS } from 'constants/posts';
import { setInfluencer } from 'actions/influencers';
import { fetchCampaignById } from 'actions/campaigns';
import { SET_POSTS_FEED, CLEAR_POSTS_FEED } from 'reducers/ui/postList';

export const setPostsFeed = posts => ({ type: SET_POSTS_FEED, payload: posts });
export const clearPostsFeed = () => ({ type: CLEAR_POSTS_FEED });

// 1. Get accepted influencers in a product
// 2. Get pots from each influencer
export const getAllApprovedPosts = () => {
  return (dispatch) => {
    dispatch(fetchRequest());
    getPosts(dispatch).then((allPosts) => {
      const approvedPosts = {};
      allPosts.forEach((post) => {
        const key = Object.keys(post)[0];
        if (key) { approvedPosts[key] = post[key]; }
      });
      dispatch(addPosts(approvedPosts));
      dispatch(setPostsFeed(Object.keys(approvedPosts)));
    }).finally(() => {
      dispatch(receiveRequest());
    });
  };
};

export const clearAllApprovedPosts = () => (dispatch) => {
  dispatch(clearPostsFeed());
};

export const getPosts = async (dispatch) => {
  const products = await getProducts();
  const posts = [];
  if (Object.keys(products).length > 0) {
    for (const [key, product] of Object.entries(products)) {
      product.uid = key;
      for (const uid of getAcceptedInfluencerUIDS(product)) {
        const infPost = await getInfluencerPosts(uid, product, dispatch);
        posts.push(infPost);
      }
      getBrand(product.brandUID, dispatch);
    }
  }
  return posts;
};

const getProducts = () => {
  const promise = firebase.activeProducts().limitToLast(30).once('value').then((snapshot) => {
    let products = {};
    if (snapshot.exists()) {
      products = snapshot.val();
    }
    return products;
  });
  return promise;
};

const getAcceptedInfluencerUIDS = (product) => {
  let approvedUIDS = [];
  const approvedApplications = {};

  if (product.appliedUID) {
    Object.entries(product.appliedUID).forEach(([key, application]) => {
      if (application.status === POST_DETAIL_STATUS.approved) {
        approvedApplications[key] = application;
      }
    });
    approvedUIDS = Object.keys(approvedApplications);
  }
  return approvedUIDS;
};

const getInfluencerPosts = (influencerUID, product, dispatch) => {
  const promise = firebase.influencer(influencerUID).once('value').then((snapshot) => {
    const posts = {};
    const filtered = {};
    if (snapshot.exists()) {
      const influencer = { [influencerUID]: snapshot.val() };
      const infPosts = ((snapshot.val().partnerships || {}).products[product.uid] || {})
        .posts || [];

      dispatch(setInfluencer(influencer));

      Object.entries(infPosts).forEach(([key, post]) => {
        if (post.details.status === POST_DETAIL_STATUS.approved) {
          filtered[key] = post;
        }
      });

      Object.entries(filtered).forEach(([key, post]) => {
        posts[key] = {
          postUID: key,
          ...post,
          influencerUID,
          brandUID: product.brandUID,
          productUID: product.uid,
        };
      });
    }
    return posts;
  });

  return promise;
};

const getBrand = (brandUID, dispatch) => {
  firebase.brand(brandUID).once('value', (snapshot) => {
    if (snapshot.exists()) {
      dispatch(receiveBrand({ [brandUID]: snapshot.val() }));
    }
  });
};
