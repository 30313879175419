export const SET_INFLUENCER_LIST = 'SET_INFLUENCER_LIST';

export const REMOVE_INFLUENCER_IN_LIST = 'REMOVE_INFLUENCER_IN_LIST';

const defaultState = [];

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_INFLUENCER_LIST:
      return [
        ...new Set(Object.keys(action.influencers))
      ];

    case REMOVE_INFLUENCER_IN_LIST:
      return [
        ...state.filter((uid) => uid !== action.influencerUID)
      ]
    default:
      return state;
  }
}
