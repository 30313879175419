import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Table,
  Button,
  Avatar,
  Icon,
  notification,
} from 'antd';
import { IMPRESSIONS } from 'constants/posts';
import { filterFavoritePosts } from 'selectors/postsAnalytics';
import PostImages from 'lib/Download';

const style = StyleSheet.create({
  favorite: {
    color: 'red',
  },
});

const Influencer = influencer => (
  <div>
    <Avatar src={influencer.image} size={50} style={{ float: 'left' }} />
    <div style={{ float: 'left', marginLeft: '5px' }}>
      <div style={{ fontWeight: 'bold' }}>
        @
        {influencer.username}
      </div>
      <div>
        {influencer.state}
      </div>
    </div>
  </div>
);

const Post = post => (
  <a href={post.link} target="_blank" rel="noopener noreferrer">
    <Avatar src={post.standardResolutionImage} size={50} shape="square" />
  </a>
);

const PostAction = ({
  post,
  postUID,
  influencerUID,
  onClickFavorite,
} = {}) => (
  <div>
    <Button
      onClick={() => onClickFavorite(influencerUID, postUID, !post.isFavorite)}
      shape="circle"
    >
      <Icon
        type="heart"
        theme={post.isFavorite ? 'filled' : 'outlined'}
        className={css(post.isFavorite && style.favorite)}
      />
    </Button>
    <Button
      onClick={() => PostImages.downloadImage(post.fullResImage, post.code, (alert) => {
        notification.warning({
          message: 'Image',
          description: alert,
          duration: 2,
        });
      })}
      icon="download"
      shape="circle"
      style={{ margin: '0 5px 0 5px' }}
    />
    <a href={post.link} target="_blank" rel="noopener noreferrer">
      <Button icon="link" shape="circle" />
    </a>
  </div>
);

const columns = [
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    render: Influencer,
  },
  {
    title: 'POST',
    dataIndex: 'post',
    key: 'post',
    render: Post,
  },
  {
    title: 'LIKES',
    dataIndex: 'likes',
    key: 'likes',
  },
  {
    title: 'COMMENTS',
    dataIndex: 'comments',
    key: 'comments',
  },
  {
    title: 'IMPRESSIONS',
    dataIndex: 'impressions',
    key: 'impressions',
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    render: PostAction,
  },
];

class ListLayout extends Component {
  onClickFavorite = (influencerUID, postUID, isFavorite) => {
    const { onClickFavorite: favorite } = this.props;

    favorite({
      influencerUID,
      postUID,
      isFavorite,
    });
  }

  render() {
    const {
      postIds,
      posts,
      influencers,
      isFetching,
    } = this.props;

    const postDataSource = postIds.map((postUID, i) => {
      const { influencerUID, details: post } = posts[postUID];
      const influencer = influencers[influencerUID].details;

      return {
        key: `${postUID}-${i}`,
        influencer,
        post,
        likes: (post.likes || 0).toLocaleString(),
        comments: (post.comments || 0).toLocaleString(),
        impressions: ((post.likes || 0) * IMPRESSIONS).toLocaleString(),
        action: {
          influencerUID,
          postUID,
          post,
          onClickFavorite: this.onClickFavorite,
        },
      };
    });

    return (
      <div>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={postDataSource}
          pagination={false}
          className="trend-table"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  posts: state.entities.posts.byId,
  postIds: filterFavoritePosts(
    state.ui.modeView.isFavorite,
    state.entities.posts.byId,
    state.ui.postsAnalytics,
  ),
  influencers: state.entities.influencers.byId,
  isFetching: state.entities.posts.isFetching,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(ListLayout);
