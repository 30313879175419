import { firebase } from 'lib/Firebase';

export const doSignInWithEmailAndPassword = (email, password, callback) => {
  return (dispatch) => {
    firebase.doSignInWithEmailAndPassword(email, password).then(() => {
      callback(true);
    }).catch((e) => {
      callback(false, e);
    });
  }
}
