import * as Promise from 'bluebird';
import { firebase } from 'lib/Firebase';
import {
  RECEIVE_CAMPAIGNS,
  RECEIVE_CAMPAIGN,
  ADD_CAMPAIGN,
  UPDATE_CAMPAIGN,
  SET_CAMPAIGN_LIST_CATEGORY,
  CAMPAIGN_FETCH_REQUEST,
  CAMPAIGN_RECEIVE_REQUEST,
  UPDATE_CAMPAIGN_APPLIED_UID,
} from 'reducers/entities/campaigns';
import { BRAND_PRODUCT_STATUS } from 'constants/brands';
import { setCurrentCampaign } from 'actions/ui/campaignDashboard';
import { moveSnapApi } from 'api/common';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import moment from 'moment';
import { receiveBrand, receiveBrands } from './brands';

export const fetchRequest = () => ({ type: CAMPAIGN_FETCH_REQUEST });

export const receiveRequest = () => ({ type: CAMPAIGN_RECEIVE_REQUEST });

// FETCHING ALL CAMPAIGNS REGARDLESS OF WHAT BRAND
export const receiveCampaigns = campaigns => ({
  type: RECEIVE_CAMPAIGNS,
  payload: campaigns,
});

// FETCHING A SIGNLE CAMPAIGN
export const receivedCampaign = campaign => ({
  type: RECEIVE_CAMPAIGN,
  payload: campaign,
});

export const addCampaign = campaign => ({
  type: ADD_CAMPAIGN,
  payload: campaign,
});

export const updateCampaign = (campaign, id) => ({
  type: UPDATE_CAMPAIGN,
  payload: campaign,
});

export const setCampaignListCategory = category => ({
  type: SET_CAMPAIGN_LIST_CATEGORY,
  payload: category,
});

export const updateCampaignAppliedUID = (
  campaignUID,
  influencerUID,
  appliedUID,
) => ({
  type: UPDATE_CAMPAIGN_APPLIED_UID,
  campaignUID,
  influencerUID,
  appliedUID,
});

export const fetchCampaigns = () => (dispatch) => {
  dispatch(fetchRequest());

  firebase.activeProducts().once('value', (snapshot) => {
    const campaigns = {};
    if (!snapshot.exists()) {
      return;
    }

    Object.entries(snapshot.val()).forEach(([key, val]) => {
      if (val.isListed === true) {
        campaigns[key] = val;
      }
    });

    const brandIds = Object.values(campaigns).map(obj => obj.brandUID);

    const brands = {};
    Promise.map(
      brandIds,
      id => firebase.brand(id).once('value', (brand) => {
        brands[id] = brand.val();
      }),
      {
        concurrency: 5,
      },
    )
      .then(() => {
        dispatch(receiveBrands(brands));
        dispatch(receiveCampaigns(snapshot.val()));
      })
      .catch((e) => {
        console.log('Error on Getting brands: ', e);
      });
  });
};

export const fetchCampaignById = id => dispatch => (
  firebase.activeProduct(id).once('value', (snapshot) => {
    const campaign = snapshot.val();
    if (!campaign) {
      return;
    }
    let brand = {};
    firebase
      .brand(campaign.brandUID)
      .once('value', (s) => {
        brand = s.val();
      })
      .then(() => {
        dispatch(receivedCampaign({ [id]: campaign }));
        dispatch(receiveBrand({ [campaign.brandUID]: brand }));
      });
  })
);

// FETCHING CAMPAIGNS OF A SPECIFIC BRAND
export const fetchBrandCampaigns = brandId => dispatch => (
  firebase
    .activeProducts()
    .orderByChild('brandUID')
    .equalTo(brandId)
    .on('value', (snapshot) => {
      const campaigns = snapshot.val();
      if (campaigns) {
        dispatch(receiveCampaigns(campaigns));
      }
    })
);

export const createCampaign = campaign => (dispatch) => {
  firebase
    .activeProducts()
    .push(campaign)
    .then((ref) => {
      dispatch(addCampaign({ [ref.key]: campaign }));
      return ref;
    })
    .then((ref) => {
      firebase
        .brand(campaign.brandUID)
        .child('products')
        .child(ref.key)
        .set('active');
      return ref;
    })
    .catch((e) => {
      console.log('Error on creating Campaign: ', e);
    });
};

export const editCampaign = (campaign, id) => (dispatch) => {
  firebase.activeProduct(id).update(campaign, (error) => {
    if (error) {
      console.log('Error on updating Campaign: ', error);
    } else {
      dispatch(updateCampaign({ [id]: campaign }));
      firebase
        .brand(campaign.brandUID)
        .child('products')
        .update({ [id]: BRAND_PRODUCT_STATUS.active });
    }
  });
};

export const removeCampaignFromStore = (
  campaignUID,
  onComplete = null,
) => (dispatch) => {
  firebase
    .activeProduct(campaignUID)
    .update({ isListed: false })
    .then((error) => {
      if (!error) {
        firebase.activeProduct(campaignUID).once('value', (snapshot) => {
          dispatch(updateCampaign({ [campaignUID]: snapshot.val() }));
          dispatch(setCurrentCampaign({ campaignUID, ...snapshot.val() }));

          if (onComplete) {
            onComplete();
          }
        });
      }
    });
};
export const addCampaignBackToStore = (
  campaignUID,
  onComplete = null,
) => (dispatch) => {
  firebase
    .activeProduct(campaignUID)
    .update({ isListed: true })
    .then((error) => {
      if (!error) {
        firebase.activeProduct(campaignUID).once('value', (snapshot) => {
          dispatch(updateCampaign({ [campaignUID]: snapshot.val() }));
          dispatch(setCurrentCampaign({ campaignUID, ...snapshot.val() }));

          if (onComplete) {
            onComplete();
          }
        });
      }
    });
};

export const archiveCampaign = (
  campaignUID,
  brandUID,
  onComplete = null,
) => (dispatch) => {
  const activeRef = firebase.activeProduct(campaignUID);
  const completedRef = firebase.completedProduct(campaignUID);
  const completeCallback = onComplete;

  moveSnapApi({ fromRef: activeRef, toRef: completedRef }, (error) => {
    firebase
      .brand(brandUID)
      .child('products')
      .child(campaignUID)
      .set(BRAND_PRODUCT_STATUS.completed);
    firebase
      .completedProduct(campaignUID)
      .update({ timestampCompleted: moment().format(DATE_TIMEZONE_FORMAT) });
    if (completeCallback) {
      completeCallback();
    }
  });
};

export const revertArchiveCampaign = (
  campaignUID,
  brandUID,
  onComplete = null,
) => (dispatch) => {
  const activeRef = firebase.activeProduct(campaignUID);
  const completedRef = firebase.completedProduct(campaignUID);
  const completeCallback = onComplete;

  moveSnapApi({ fromRef: completedRef, toRef: activeRef }, (error) => {
    firebase
      .activeProduct(campaignUID)
      .child('timestampCompleted')
      .remove();
    firebase
      .brand(brandUID)
      .child('products')
      .child(campaignUID)
      .set(BRAND_PRODUCT_STATUS.active);
    if (completeCallback) {
      completeCallback();
    }
  });
};
