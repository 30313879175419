import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { getCategories } from 'actions/categories';
import { setCampaignListCategory } from 'actions/campaigns';

const Option = Select.Option;

class CategoriesSelect extends Component {
  componentWillMount() {
    this.props.getCategories();
  }

  render() {
    let { categories } = this.props;

    let options = categories.map((category, index) => (
      <Option key={index} value={category}>{category}</Option>
    ))

    return (
      <Select
        style={{ width: '100%' }}
        placeholder="Category : All"
        value={this.props.category}
        onSelect={(value) => this.props.setCampaignListCategory(value)}
      >
        <Option value="">All</Option>
        { options }
      </Select>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.entities.categories.categories,
  category: state.ui.campaignList.category
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: () => dispatch(getCategories()),
  setCampaignListCategory: (category) => dispatch(setCampaignListCategory(category))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSelect);
