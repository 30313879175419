import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Table,
  Row,
  Button,
  Icon,
  Avatar,
  notification,
  Modal,
} from 'antd';
import validUrl from 'valid-url';
import CustomEmpty from 'components/Common/CustomEmpty';
import { withFirebase } from 'lib/Firebase';
import { POST_DETAIL_STATUS } from 'constants/posts';
import { filterPosts } from 'selectors/postApproval';
import postDetailStatusChange from 'actions/ui/postApproval';
import MinLayout from 'components/Common/MinLayout';
import { ReactComponent as IconMessages } from 'images/icon-messages.svg';
import InfluencerAvatar from 'components/Common/InfluencerAvatar';

const postProfile = post => (
  <div>
    {!post.standardResolutionImage && <Avatar src="" size={50} shape="square" />}
    {
      post.standardResolutionImage && (
        <a href={post.link} target="_blank" rel="noopener noreferrer">
          <Avatar src={post.standardResolutionImage} size={50} shape="square" />
        </a>
      )
    }
  </div>
);

const postAction = ({
  post,
  isFetchingMessageUID,
  clickMsgPostUID,
  showModal,
  onClickToMessageThread,
}) => {
  const loadingMsg = isFetchingMessageUID && clickMsgPostUID === post.postUID;
  const messageAction = (
    <Button
      loading={loadingMsg}
      onClick={() => onClickToMessageThread(post.influencerUID, post.postUID)}
      shape="circle"
      style={{
        background: '#ffb917',
        border: '0',
        marginRight: '5px',
        width: '35px',
        height: '35px',
      }}
    >
      {
        loadingMsg && (
          <Icon
            type="loading"
            style={{
              width: '15px',
              height: '15px',
              position: 'relative',
            }}
            spin
          />
        )
      }
      {
        !loadingMsg && (
          <IconMessages
            style={{
              position: 'relative',
              top: '2px',
              width: '15px',
              height: '14px',
            }}
          />
        )
      }
    </Button>
  );

  const approvedAction = (
    <div>
      {messageAction}
      <a href={post.standardResolutionImage} target="_blank" rel="noopener noreferrer" download>
        <Button shape="circle" style={{ marginLeft: '5px' }}>
          <Icon type="download" />
        </Button>
      </a>
    </div>
  );

  const pendingAction = (
    <div>
      <Button
        onClick={() => showModal(
          POST_DETAIL_STATUS.approved,
          post.influencerUID,
          post.postUID,
          post.code,
          post.link,
        )}
        style={{ marginRight: '5px', backgroundColor: '#ffb917' }}
      >
        APPROVE
      </Button>
      <Button
        onClick={() => showModal(
          POST_DETAIL_STATUS.rejected,
          post.influencerUID,
          post.postUID,
          post.link,
        )}
        style={{ backgroundColor: '#f5f5f5' }}
      >
        REJECT
      </Button>
    </div>
  );

  const isAwaiting = ![
    POST_DETAIL_STATUS.approved,
    POST_DETAIL_STATUS.submitted,
  ].includes(post.status);

  return (
    <div>
      {post.status === POST_DETAIL_STATUS.approved && approvedAction}
      {post.status === POST_DETAIL_STATUS.submitted && pendingAction}
      {isAwaiting && messageAction}
    </div>
  );
};

const columns = [
  {
    title: 'INFLUENCER',
    dataIndex: 'influencer',
    key: 'influencer',
    className: 'influencer-width',
    render: InfluencerAvatar,
  },
  {
    title: 'POST',
    dataIndex: 'post',
    key: 'post',
    className: 'post-width',
    render: postProfile,
  },
  {
    title: 'COMMENTS',
    dataIndex: 'comments',
    className: 'comments',
    key: 'comments',
    render: comments => (comments || '--'),
  },
  {
    title: 'LIKES',
    dataIndex: 'likes',
    key: 'likes',
    className: 'likes',
    render: likes => (likes || '--'),
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    render: postAction,
  },
];

const defaultState = {
  status: undefined,
  influencerUID: undefined,
  postUID: undefined,
  visibleModal: false,
  submitModal: false,
  code: undefined,
};

class PostApproval extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    this.state = {
      campaignUID: match.params.campaignId,
      ...defaultState,
    };
  }

  showModal = (status, influencerUID, postUID, code, link) => {
    if (status === POST_DETAIL_STATUS.approved && !validUrl.isUri(link)) {
      Modal.warning({
        title: 'Invalid Link',
        content: 'There seems to be an issue with this link. Try again later.',
      });
    } else {
      this.setState(() => ({
        status,
        influencerUID,
        postUID,
        code,
        visibleModal: true,
      }));
    }
  }

  changeStatus = () => {
    const {
      campaignUID,
      influencerUID,
      postUID,
      status,
      code,
    } = this.state;

    const {
      influencers,
      postDetailStatusChange: statusChange,
      campaign,
    } = this.props;

    const influencer = influencers[influencerUID].details;
    const args = {
      campaignUID,
      campaign,
      influencerUID,
      influencer,
      postUID,
      postCode: code,
      status,
    };

    this.setState(() => ({ submitModal: true }));

    statusChange(args, (isSuccess, { payoutBatchId } = {}) => {
      this.setState(() => ({ ...defaultState }));

      if (isSuccess) {
        notification.success({
          message: `${args.status.charAt(0).toUpperCase()}${args.status.slice(1)}`,
          description: `Post successfully ${args.status}!`,
          duration: 2,
        });

        if (args.status === POST_DETAIL_STATUS.approved && payoutBatchId) {
          notification.success({
            message: 'Payment',
            description: `Post required is completed. Payment has been sent to ${influencer.paypalEmail}`,
            duration: 0,
          });
        }

        // TODO:
        //   do push notificdation for reject/approved here
      } else {
        notification.error({
          message: 'Error',
          description: `Somethings wrong on ${args.status} post. Please contact us.`,
          duration: 2,
        });
      }
    });
  }

  onClickToMessageThread = (influencerUID, postUID) => {
    this.setState(() => ({
      clickMsgPostUID: postUID,
    }));

    const { onClickToMessageThread: toMessageThread } = this.props;

    toMessageThread(influencerUID);
  }

  render() {
    const filterCampaignPosts = {
      awaiting: [],
      needsApproval: [],
      approved: [],
    };

    const {
      filterPosts: postsFilter,
      influencers,
      isFetchingMessageUID,
      isFetching,
      isUpdating,
    } = this.props;

    const {
      clickMsgPostUID,
      visibleModal,
      submitModal,
      status,
    } = this.state;

    Object.keys(postsFilter).forEach((postType) => {
      const posts = postsFilter[postType];

      Object.keys(posts).forEach((postUID) => {
        let post = posts[postUID];
        const influencer = influencers[post.influencerUID].details;

        // override reject, don't show images
        // and number of likes, posts.
        // treat as like pending
        if (post.status === POST_DETAIL_STATUS.rejected) {
          post = {
            postUID,
            influencerUID: post.influencerUID,
            status: post.status,
          };
        }

        filterCampaignPosts[postType] = [...filterCampaignPosts[postType], {
          key: `${postUID}-${postType}`,
          influencer,
          post,
          likes: post.likes,
          comments: post.comments,
          action: {
            post,
            clickMsgPostUID,
            isFetchingMessageUID,
            showModal: this.showModal,
            onClickToMessageThread: this.onClickToMessageThread,
          },
        }];
      });
    });

    return (
      <div>
        <Row type="flex" justify="space-around">
          <MinLayout>
            {
              !filterCampaignPosts.needsApproval.length
                && !filterCampaignPosts.awaiting.length
                && !filterCampaignPosts.approved.length
                && <CustomEmpty description="No Post Yet!" style={{ marginTop: '40px' }} />
            }
            {
              filterCampaignPosts.needsApproval.length > 0 && (
                <div>
                  <div style={{ marginBottom: '15px', marginTop: '23px' }}>
                    <div
                      style={{
                        fontFamily: 'truenoextrabold',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        color: '#000',
                      }}
                    >
                      Needs Approval -
                      {filterCampaignPosts.needsApproval.length}
                    </div>
                    <div style={{ fontSize: '10px', color: '#000', lineHeight: '1.8' }}>
                      Influencers work hard to create unique work for your brand. Make sure to
                      <br />
                      only reject a post if they&apos;ve missed something from the post
                      requirements.
                    </div>
                  </div>
                  <Table
                    loading={isFetching || isUpdating}
                    columns={columns}
                    dataSource={filterCampaignPosts.needsApproval}
                    className="trend-table"
                  />
                </div>
              )
            }
            {
              filterCampaignPosts.awaiting.length > 0 && (
                <div style={{ marginTop: '40px' }}>
                  <div style={{ marginBottom: '15px' }}>
                    <div
                      style={{
                        fontFamily: 'truenoextrabold',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        color: '#000',
                      }}
                    >
                      Awaiting Post -
                      {filterCampaignPosts.awaiting.length}
                    </div>
                    <div style={{ fontSize: '10px', color: '#000', lineHeight: '1.8' }}>
                      These influencers have yet to post. If you are looking for status on when
                      <br />
                      they will post, feel free to message them at any point.
                    </div>
                  </div>
                  <Table
                    loading={isFetching || isUpdating}
                    columns={columns}
                    dataSource={filterCampaignPosts.awaiting}
                    className="trend-table"
                  />
                </div>
              )
            }
            {
              filterCampaignPosts.approved.length > 0 && (
                <div style={{ marginTop: '40px' }}>
                  <div
                    style={{
                      fontFamily: 'truenoextrabold',
                      fontWeight: 'bold',
                      marginBottom: '10px',
                      color: '#000',
                    }}
                  >
                    Approved Posts -
                    {filterCampaignPosts.approved.length}
                  </div>
                  <Table
                    loading={isFetching || isUpdating}
                    columns={columns}
                    dataSource={filterCampaignPosts.approved}
                    className="trend-table"
                  />
                </div>
              )
            }
          </MinLayout>
        </Row>
        <Modal
          title={status === POST_DETAIL_STATUS.approved ? 'Approve Post' : 'Reject Post'}
          visible={visibleModal}
          width={300}
          maskCloseable
          closable={false}
          footer={[
            <Button key="cancel" disabled={submitModal} onClick={() => this.setState(() => ({ visibleModal: false }))}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={submitModal} onClick={this.changeStatus}>
              {status === POST_DETAIL_STATUS.approved ? 'Approve' : 'Reject'}
            </Button>,
          ]}
        >
          {
            status === POST_DETAIL_STATUS.approved
              ? 'Are you sure you want to approve this post?'
              : 'Are you sure you want to reject this post?'
          }
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  campaign: state.ui.campaignDashboard.currentCampaign,
  filterPosts: filterPosts(
    ownProps.match.params.campaignId,
    state.ui.campaignDashboard.currentCampaign,
    state.entities.influencers.byId,
    state.ui.influencerList,
  ),
  influencers: state.entities.influencers.byId,
  influencerIds: state.ui.influencerList,
  isFetching: state.entities.influencers.isFetching,
  isFetchingMessageUID: state.entities.messages.isFetching,
  isPostRequest: state.entities.posts.isFetching,
});

const mapDispatchToProps = dispatch => ({
  postDetailStatusChange: (args, callback) => dispatch(postDetailStatusChange(args, callback)),
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
)(PostApproval);
