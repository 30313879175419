import React, { Component } from 'react';
import { Layout } from 'antd';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import 'styles/base/antd-custom.less'
import { Link } from 'react-router-dom';

import trendLogo from 'images/trend@2x.png'
import ribbonCut from 'images/cutTheRibbon@2x.png'

const { Content } = Layout;

class GrandOpeningPage extends Component {
  render() {
    return (
      <div>
        <Layout>
          <Content style={{ padding: 24, background: '#000'}}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={6}>
                <div style={{textAlign: 'center', padding: '4rem 0'}}>
                  <img alt='Logo' src={trendLogo} width="162" height="31" style={{ display: 'inline-block'}}/>
                </div>
                <h1 style={{textAlign: 'center', color: '#fff'}}>Grand Opening</h1>
                <p className="grey2" align="middle">
                  Cut the Ribbon to launch your store 
                  <br />
                  into the marketplace!
                </p>
                <Link to={ `/posts` } style={{textAlign : 'center', display: 'block'}}>
                  <img alt='Ribbon' src={ribbonCut} width="375" height="167" style={{ display: 'inline-block'}}/>
                </Link>
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default GrandOpeningPage;
