import React, { Component } from 'react';
import posed from 'react-pose';
import { StripeProvider, Elements } from 'react-stripe-elements';
import {
  Row,
  Col,
  Radio,
  Button,
  Spin,
  notification,
  Icon,
} from 'antd';
import { fetchPlans, fetchCurrentSubscription, updatePlan } from 'api/subscriptions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { fetchBrand } from 'actions/brands';
import trendLogo from 'images/trend-short.png';
import CheckoutForm from './CheckoutForm';

// TODO:
//   should be declared in .env
const stripePublishKey = 'pk_test_UmEuw6bdx2d9LbuPlM3Lydty';
const RadioGroup = Radio.Group;
const StepContainer = posed.div({
  visible: {
    applyAtEnd: { display: 'block' },
    opacity: 1,
  },
  hidden: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
  },
});
const ButtonsSlider = posed.div({
  hidden: {
    opacity: 0,
    y: 0,
  },
  visible: {
    opacity: 1,
    y: 10,
  },
});

class PaymentForm extends Component {
  state = {
    plans: {},
    selectedPlan: null,
    visibleStep: 'plan',
    existingPlan: null,
    updatingPlan: false,
  };

  constructor(props) {
    super(props);
    this.isFetching = false;
    this.setupPlans();
  }

  componentWillUnmount() {
    this.isFetching = false;
  }

  setupPlans() {
    const {
      authUser: user,
      fetchBrand: getBrand,
    } = this.props;

    if (!this.isFetching) {
      this.isFetching = true;
      getBrand(user.uid, (brand) => {
        if (brand.details.stripeActive) {
          fetchCurrentSubscription(user.uid, (response) => {
            this.setState(() => ({
              selectedPlan: response.plan.id,
              existingPlan: response.plan.id,
            }));
            this.getPlans();
          });
        } else {
          this.getPlans();
        }
      });
    }
  }

  getPlans() {
    fetchPlans((response) => {
      this.setState(() => ({ plans: response.body }));
    });
  }

  onChange = (e) => {
    const { plans } = this.state;
    const { amount } = plans[e.target.value];

    this.setState(() => ({
      selectedPlan: e.target.value,
      planAmount: amount,
    }));
  }

  goStep = (step) => {
    this.setState({ visibleStep: step });
  }

  cancelUpdate = () => {
    const { existingPlan } = this.state;
    this.setState({ selectedPlan: existingPlan });
  }

  onUpdatePlan = () => {
    const { authUser } = this.props;
    const { selectedPlan } = this.state;

    this.setState({ updatingPlan: true });
    updatePlan(authUser.uid, selectedPlan, (subscription, error) => {
      this.setState({ updatingPlan: false });
      if (error) {
        notification.error({ message: error.message });
      } else {
        this.setState({ existingPlan: subscription.plan.id, selectedPlan: subscription.plan.id });
        notification.success({ message: 'Successfully updated your plan.' });
      }
    });
  }

  renderUpdateButton(existingPlan, selectedPlan) {
    const { updatingPlan } = this.state;
    const postState = existingPlan !== selectedPlan ? 'visible' : 'hidden';
    let buttons = null;

    buttons = (
      <ButtonsSlider pose={postState} initialPose="hidden">
        <Row gutter={12}>
          <Col span={12}>
            <Button block size="large" onClick={this.cancelUpdate} className="trend-btn gray huge-btn">CANCEL UPDATE</Button>
          </Col>
          <Col span={12}>
            <Button block size="large" onClick={this.onUpdatePlan} className="trend-btn huge-btn" loading={updatingPlan}>Update Plan</Button>
          </Col>
        </Row>
      </ButtonsSlider>
    );
    return buttons;
  }

  renderProceedPaymentButton() {
    const { selectedPlan, plans } = this.state;
    let button = null;
    if (Object.keys(plans).length !== 0) {
      button = (
        <Col span={24} style={{ textAlign: 'center' }}>
          <Button block className="trend-btn huge-btn" size="large" span={12} onClick={() => { this.goStep('checkout'); }} disabled={!selectedPlan}>Proceed to Payment.</Button>
        </Col>
      );
    }
    return button;
  }

  render() {
    const {
      plans,
      selectedPlan,
      visibleStep,
      existingPlan,
      planAmount,
    } = this.state;
    const isEmpty = Object.keys(plans).length === 0;

    const Plans = Object.entries(plans).map(([id, plan]) => (
      <Radio value={id} key={id} style={{ display: 'block', minHeight: '120px' }} className="plan-radio pt-3 pb-2 mt-5 mb-5">
        <Col span={4} style={{ textAlign: 'center' }} className="pt-5">
          <img src={trendLogo} alt="Plan logo" />
        </Col>
        <Col span={14}>
          { plan.popular && <label className="text-orange type-sbold size-10px">MOST POPULAR</label> }
          <h4 className="text-white">
            { plan.nickname }
            &nbsp; - { plan.credits } Posts/Month
            { existingPlan === id && <small className="text-gray-darkest">  (CURRENT)</small> }
          </h4>
          <p style={{ wordWrap: 'break-word' }} className="text-gray-dark">{ plan.description }</p>
        </Col>
        <Col span={6} className="pl-7">
          <strong className="text-white">{`$${plan.amount}`}</strong>
          <br />
          <span className="text-gray-dark size-11px">Month</span>
        </Col>
      </Radio>
    ));

    return (
      <div className="pt-3">
        <Row type="flex" justify="center">
          <Col span={12}>
            <Col span={24} style={{ color: 'text-white', padding: '0px 40px 80px 40px' }}>
              <StepContainer pose={visibleStep === 'plan' ? 'visible' : 'hidden'}>
                <Spin spinning={isEmpty} wrapperClassName="dark-theme">
                  <p className="text-gray-dark">
                    Your monthly plan is listed bellow.
                    You can change your plan at any point in time.
                    After making a new selection we'll confirm your new plan and show you the change in price.
                  </p>
                  <p className="text-gray-dark">
                    Lookin for even more? Send us an email to
                    <a href="mailto:Support@trend.io" target="_top"> Support@trend.io </a>
                    to help us understand your needs.
                  </p>
                  <RadioGroup onChange={this.onChange} value={selectedPlan}>
                    { Plans }
                  </RadioGroup>
                  {
                    existingPlan
                      ? this.renderUpdateButton(existingPlan, selectedPlan)
                      : this.renderProceedPaymentButton()
                  }
                </Spin>
              </StepContainer>

              <StepContainer pose={visibleStep === 'checkout' ? 'visible' : 'hidden'}>
                <a href="#" onClick={() => { this.goStep('plan'); }} className="text-gray d-block type-sbold bsize-4">
                  <Icon type="left" className="size-20px" />
                </a>

                <h1 className="text-white mb-8" style={{ textAlign: 'center' }}> Payment </h1>
                <StripeProvider apiKey={stripePublishKey}>
                  <Elements>
                    <CheckoutForm plan={selectedPlan} amount={planAmount} />
                  </Elements>
                </StripeProvider>
              </StepContainer>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = () => ({

});

const mapDispatchToProps = dispatch => ({
  fetchBrand: (brandUID, callback) => dispatch(fetchBrand(brandUID, callback)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PaymentForm);
