import React from 'react';
import { Link } from 'react-router-dom'
import { StyleSheet, css } from 'aphrodite';

const CampaignImage = ({ image, campaignId, brandUID, ...other }) => {
  return (
    <div className="campaign-image">
      <img src={image} className={css(styles.campaignImg)}/>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative'
  },
  campaignImg: {
    width: '100%'
  }
})

export default CampaignImage
