import * as api from 'api/brands';
import { firebase } from 'lib/Firebase';
import { updateBrandDetails } from 'actions/brands';

export const addCredits = (uid, selectedStandardCredits, selectedVipCredits, onComplete = null) => (dispatch) => {
  api.addCredits(uid, selectedStandardCredits, selectedVipCredits, (response, error) => {
    if (error) {
      onComplete(response, error);
    } else {
      firebase.brand(uid).once('value', (snapshot) => {
        const brand = snapshot.val();
        updateBrandDetails(uid, brand.details);
      });
      onComplete(response, null);
    }
  });
};
