import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

const PostItem = ({ post, username }) => (
    <Row style={{ marginBottom: '15px' }}>
      <Col span={16}>
        <Row gutter={16}>
          <Col span={8}>
            <img src={ post.lowResolutionImage } alt="" style={{width: '100%'}} />
          </Col>
          <Col span={16}>
            <strong>@{ username }</strong>
            <br />
            <small>{moment(post.createdTime).format('M/D/YYYY')}</small>
          </Col>
        </Row>
      </Col>
      <Col span={4} style={{ textAlign: 'right', fontSize: '18pt' }}><h6>{ post.comments }</h6></Col>
      <Col span={4} style={{ textAlign: 'right', fontSize: '18pt' }}><h6>{ post.likes }</h6></Col>
    </Row>
)

export default PostItem;
