import { firebase } from 'lib/Firebase';
import { paySubscription } from 'api/subscriptions';
import { updateBrand } from 'actions/brands';

export const createSubscription = (token, plan, uid, onComplete = null) => (dispatch) => {
  paySubscription(token, plan, uid, (response, error = null) => {
    if (error) {
      onComplete(null, error);
    } else {
      firebase.brand(uid).once('value', (snapshot) => {
        const brand = snapshot.val();
        dispatch(updateBrand(uid, brand.details));
        onComplete(brand);
      });
    }
  });
};
