export const moveSnapApi = ({ fromRef, toRef }, onComplete = null) => {
  fromRef.once('value', (snapshot) => {
    if (!snapshot.exists()) {
      onComplete();
      return;
    }

    toRef.set(snapshot.val(), (error) => {
      if (!error) {
        fromRef.remove((error) => {
          if (!error) {
            onComplete();
          } else {
            console.log(error);
          }
        });
      } else {
        console.log(error);
      }
    });
  });
};
