import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  Row,
  Menu,
  notification
} from 'antd';
import {
  Route,
  Link,
  Switch,
  Redirect
} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'lib/Firebase';
import Influencers from './Influencers';
import ProductShipments from './ProductShipments';
import PostApproval from './PostApproval';
import MinLayout from 'components/Common/MinLayout';
import { ReactComponent as IconInfluencerList } from 'images/icon-influencer-list.svg';
import { ReactComponent as IconShipmentsMade } from 'images/icon-shipments-made.svg';
import { ReactComponent as IconPostApproval } from 'images/icon-post-approval.svg';
import { CAMPAIGN_APPLIED_UID_STATUS } from 'constants/campaigns';
import { addCurrentCampaign } from 'actions/ui/campaignDashboard';
import { filterPosts } from 'selectors/postApproval';
import { getInfluencerMessageUID } from 'actions/ui/messages';
import {
  setInfluencerList,
  getInfluencerList,
} from 'actions/ui/influencerList';

class InfluencersSubMenu extends Component {
  constructor(props) {
    super(props);

    const campaignUID = this.props.match.params.campaignId;

    this.state = {
      campaignUID
    };

    this.props.addCurrentCampaign(campaignUID);
    this.props.getInfluencerList({
      statuses: [CAMPAIGN_APPLIED_UID_STATUS.approved],
      campaignId: campaignUID
    });
  }

  componentWillUnmount() {
    this.props.resetInfluencerList();
    this.props.firebase.activeProducts().off();
    this.props.firebase.influencers().off();
  }

  onClickToMessageThread = (influencerUID) => {
    this.props.getInfluencerMessageUID(this.props.campaign.brandUID, influencerUID, (isSuccess, messageUID) => {
      if (isSuccess) {
        this.props.history.push(`/messages/${messageUID}`);
      } else {
        notification.info({
          message: 'Message Thread',
          description: `Something went wrong. Message thread not found in this influencer.`,
          duration: 3
        });
      }
    });
  }


  defaultSelectedKeys = () => {
    const defaultKeys = 'my_influencers';
    const arrPathname = this.props.location.pathname.split('/');

    return [arrPathname[4] || defaultKeys];
  }

  totalPostsSummary = () => {
    let {
      awaiting,
      approved,
      needsApproval
    } = this.props.filterPosts;

    awaiting = Object.keys(awaiting).length;
    approved = Object.keys(approved).length;
    needsApproval = Object.keys(needsApproval).length;

    return `${approved}/${(awaiting + approved + needsApproval)}`;
  }

  render() {

    return (
      <div>
        <div style={{background: '#fcfcfc', borderBottom: '1px solid #e8e8e8'}}>
          <Row type="flex" justify="space-around">
            <MinLayout>
              <Menu mode="horizontal" defaultSelectedKeys={this.defaultSelectedKeys()} className="trend-menu-sub trend-menu-horizontal">
                <Menu.Item key="my_influencers" style={{width: '33.33%'}}>
                  <Link to={`/campaigns/${this.props.match.params.campaignId}/my_influencers`}>
                    <span className="ant-menu-icon">
                      <IconInfluencerList />
                    </span>
                    <span style={{display: 'inline-block', lineHeight: '1.2', marginTop: '10px'}}>
                      <span className="tabs-value">{this.props.influencerIds.length}</span>
                      <span>INFLUENCER LIST</span>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="product_shipments" style={{width: '33.33%'}}>
                  <Link to={`/campaigns/${this.props.match.params.campaignId}/my_influencers/product_shipments`}>
                    <span className="ant-menu-icon">
                      <IconShipmentsMade />
                    </span>
                    <span style={{display: 'inline-block', lineHeight: '1.2', marginTop: '10px'}}>
                      <span className="tabs-value">{this.props.influencerIds.length}</span>
                      <span>SHIPMENTS MADE</span>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="post_approval" style={{width: '33.33%'}}>
                  <Link to={`/campaigns/${this.props.match.params.campaignId}/my_influencers/post_approval`}>
                    <span className="ant-menu-icon">
                      <IconPostApproval />
                    </span>
                    <span style={{display: 'inline-block', lineHeight: '1.2', marginTop: '10px'}}>
                      <span className="tabs-value">{this.totalPostsSummary()}</span>
                      <span>POST APPROVAL</span>
                    </span>
                  </Link>
                </Menu.Item>
              </Menu>
            </MinLayout>
          </Row>
        </div>
        <Row>
          <Switch>
            <Route
              exact
              path={`/campaigns/:campaignId/my_influencers`}
              render={() => <Influencers onClickToMessageThread={this.onClickToMessageThread} />}
            />

            <Route path={`/campaigns/:campaignId/my_influencers/product_shipments`} component={ProductShipments} />
            <Route
              path="/campaigns/:campaignId/my_influencers/post_approval"
              render={() => <PostApproval onClickToMessageThread={this.onClickToMessageThread} />}
            />
            <Redirect from={this.props.match.url} to={`/campaigns/${this.props.match.params.campaignId}/my_influencers`} />
          </Switch>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  influencers: state.entities.influencers.byId,
  influencerIds: state.ui.influencerList,
  campaign: state.ui.campaignDashboard.currentCampaign,
  filterPosts: filterPosts(
    ownProps.match.params.campaignId,
    state.ui.campaignDashboard.currentCampaign,
    state.entities.influencers.byId,
    state.ui.influencerList
  ),
});

const mapDispatchToProps = (dispatch) => ({
  resetInfluencerList: () => dispatch(setInfluencerList({})),
  getInfluencerList: (args) => dispatch(getInfluencerList(args)),
  addCurrentCampaign: (campaignUID) => dispatch(addCurrentCampaign(campaignUID)),
  getInfluencerMessageUID: (brandUID, influencerUID, callback) => dispatch(getInfluencerMessageUID(brandUID, influencerUID, callback))
});

export default compose(
  withRouter,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
)(InfluencersSubMenu);
