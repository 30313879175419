import React from 'react';
import {
  Row,
  Col,
} from 'antd';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';

const ContentCredit = ({ standardCredits, credits }) => (
  <div className="content-credit-text" style={{ padding: '10px 15px 17px 15px' }}>
    <Row>
      <Col span={16}>
        <div
          style={{
            paddingTop: '12px',
            lineHeight: '0',
            color: '#3c3c3c',
          }}
          className="type-ebold size-12px"
        >
          STARTER
        </div>
        <div
          style={{
            paddingTop: '2px',
            marginBottom: '7px',
            color: '#3c3c3c',
          }}
          className="type-ebold size-20px"
        >
          PACKAGE
        </div>
      </Col>
      <Col span={8} className="p-relative" style={{ top: '13px' }}>
        <Link
          to={routes.SUBSCRIPTION_SETTINGS_PATH}
          className="size-9px uppercase"
          style={{
            height: '24px',
            padding: '6px 6px 5px 6px',
            background: '#151515',
            borderRadius: '2px',
            color: '#b0b0b0',
            lineHeight: '0.8px',
            border: '1px solid #3c3c3c',
          }}
        >
            ADD/EDIT
        </Link>
      </Col>
    </Row>
    <Row>
      <Col span={14} className="size-12px text-white" style={{ paddingTop: '1px' }}>
        <span style={{ display: 'block', lineHeight: '0.5' }}>{standardCredits}</span>
        <span className="ht-5 text-gray-darker spacing-05px size-8px">REGULAR CREDITS</span>
      </Col>
      <Col span={10} className="size-12px text-white" style={{ paddingTop: '1px' }}>
        {
          credits > 0 && (
            <div>
              <span style={{ display: 'block', lineHeight: '0.5' }}>{credits}</span>
              <span className="ht-5 text-gray-darker spacing-05px size-8px">VIP CREDITS</span>
            </div>
          )
        }
      </Col>
    </Row>
  </div>
);

export default ContentCredit;
