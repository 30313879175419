import React, { Component } from 'react';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { firebase } from 'lib/Firebase';
import { setInfluencerList } from 'actions/ui/influencerList';
import MinLayout from 'components/Common/MinLayout';
import ApplicationList from './ApplicationList';

class Applications extends Component {
  componentWillUnmount() {
    const { resetInfluencerList } = this.props;

    resetInfluencerList();
    firebase.activeProducts().off();
    firebase.influencers().off();
  }

  render() {
    return (
      <div>
        <ApplicationList />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  resetInfluencerList: () => dispatch(setInfluencerList({})),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Applications);
