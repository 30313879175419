import React, { Component } from 'react';
import { Menu, Row, Col } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import CampaignMenu from './CampaignMenu';

class CampaignSubMenu extends Component {
  render() {
    const path = window.location.pathname.split('/');
    const id = `${path[1]}/${path[2] || ''}`;

    const { campaigns, ownedCampaigns, archivedCampaigns } = this.props;
    const { byId } = campaigns;
    const campaignMenus = ownedCampaigns.map(campaignId => (
      <CampaignMenu
        key={`campaigns/${campaignId}`}
        image={byId[campaignId].image}
        title={byId[campaignId].name}
        campaignId={campaignId}
      />
    ));

    return (
      !!campaignMenus.length && (
        <div>
          <Row>
            <Col span={12} className="pl-3">
              <span
                style={{
                  color: '#969696',
                  letterSpacing: '0.5px',
                  fontSize: '8px',
                  fontFamily: 'truenosemibold',
                }}
              >
                MY CAMPAIGNS
              </span>
            </Col>
            <Col span={12} className="pr-6">
              {archivedCampaigns.length && (
                <Link
                  to="/campaigns/history"
                  style={{
                    float: 'right',
                    color: '#969696',
                    letterSpacing: '0.5px',
                    fontSize: '8px',
                    fontFamily: 'truenosemibold',
                    lineHeight: '300%',
                  }}
                >
                  <span>HISTORY</span>
                </Link>
              )}
            </Col>
          </Row>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[id]}
            defaultOpenKeys={['campaigns']}
            className={['trend-menu-inline campaign-menu']}
          >
            {campaignMenus}
          </Menu>
        </div>
      )
    );
  }
}

export const mapStateToProps = state => ({
  campaigns: state.entities.campaigns,
  ownedCampaigns: state.ui.sideNav.ownedCampaigns,
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {},
  ),
)(CampaignSubMenu);
