import React from 'react';
import {
  Route,
  Switch,
  Link,
  Redirect,
} from 'react-router-dom';
import { Menu, Row } from 'antd';
import StoreEdit from 'components/BrandAccount/StoreEdit';
import TermsOfService from 'components/BrandAccount/TermsOfService';
import PrivacyPolicy from 'components/BrandAccount/PrivacyPolicy';
import PaymentForm from 'components/Common/PaymentForm';
import * as routes from 'constants/routes';
import { AuthUserContext } from 'lib/Session';
import SignOutButton from 'components/Signout';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';
import AddCredits from 'components/BrandAccount/AddCredits';

const logo = 'https://static.wixstatic.com/media/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.png/v1/fill/w_218,h_40,al_c,q_80,usm_1.20_1.00_0.01/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.webp';

const BrandAccount = (props) => {
  const { authUser: user } = props;
  const path = window.location.pathname.split('account/');
  const selectedKeys = path[1];

  return (
    <div>
      <div
        className="pt-7 mb-8"
        style={{
          background: '#0f0f0f',
          borderBottom: '1px solid #3c3c3c',
        }}
      >
        <div className="action-link clickable" align="center">
          <div className="back">
            <Link to="/">
              <IconBack />
              <span className="white d-block type-sbold bsize-4">
                Back
              </span>
            </Link>
          </div>
          <div className="sign-out">
            <AuthUserContext.Consumer>
              {authUser => (authUser && <SignOutButton />)}
            </AuthUserContext.Consumer>
          </div>
        </div>
        <div align="center">
          <img src={logo} alt="logo" className="logo d-iblock" width="70px" />
          <h1 className="ht-6 pt-4 text-white">{ user.email }</h1>
          <div className="pt-2">
            <Link to="/" className="action-btn mr-2">
              Change Email
            </Link>
            <Link to="/forgot_password" className="action-btn">
              Reset Password
            </Link>
          </div>
        </div>
        <div className="pt-7" align="center">
          <Row>
            <Menu
              mode="horizontal"
              className="account-menu"
              selectedKeys={[selectedKeys]}
              defaultOpenKeys={['store/edit']}
            >
              <Menu.Item key="store/edit">
                <Link to={routes.BRAND_EDIT_PATH}> Company </Link>
              </Menu.Item>
              <Menu.Item key="subscription">
                <Link to={routes.SUBSCRIPTION_SETTINGS_PATH}> Subscription </Link>
              </Menu.Item>
              <Menu.Item key="privacy">
                <Link to={routes.PRIVACY_PATH}> Privacy Policy </Link>
              </Menu.Item>
              <Menu.Item key="tos">
                <Link to={routes.TOS_PATH}> Terms of Service </Link>
              </Menu.Item>
              <Menu.Item key="addCredits">
                <Link to={routes.ADD_CREDITS_PATH}> Add Credits </Link>
              </Menu.Item>
            </Menu>
          </Row>
        </div>
      </div>
      <Row>
        <Switch>
          <Route path={routes.BRAND_EDIT_PATH} component={StoreEdit} />
          <Route
            path={routes.SUBSCRIPTION_SETTINGS_PATH}
            render={() => <PaymentForm authUser={user} />}
          />
          <Route
            path={routes.ADD_CREDITS_PATH}
            render={() => <AddCredits authUser={user} />}
          />
          <Route path={routes.TOS_PATH} component={TermsOfService} />
          <Route path={routes.PRIVACY_PATH} component={PrivacyPolicy} />
          <Redirect to={routes.BRAND_EDIT_PATH} />
        </Switch>
      </Row>
    </div>
  );
};

export default BrandAccount;
