import { firebase } from 'lib/Firebase';
import {
  RECEIVE_BRAND,
  RECEIVE_BRANDS,
  UPDATE_BRAND,
  UPDATE_BRAND_DETAILS,
} from 'reducers/entities/brands';

export const receiveBrands = brands => ({
  type: RECEIVE_BRANDS,
  brands,
});

export const receiveBrand = brand => ({
  type: RECEIVE_BRAND,
  brand,
});

export const updateBrand = (brandUID, brand) => ({
  type: UPDATE_BRAND,
  brandUID,
  brand,
});

export const updateBrandDetails = (brandUID, brandDetails) => ({
  type: UPDATE_BRAND_DETAILS,
  brandUID,
  brandDetails,
});

export const fetchBrand = (brandUID, callback = null) => (dispatch) => {
  firebase.brand(brandUID).once('value', (snapshot) => {
    let brand = snapshot.val();
    if (brand) {
      brand = {
        brandUID,
        ...snapshot.val(),
      };

      dispatch(receiveBrand({
        [brandUID]: brand,
      }));

      if (callback) {
        callback(brand);
      }
    }
  });
};
