import React from 'react';
import { Card, Col } from 'antd';

function CampaignListItem({ campaign, brand } = {}) {
  return (
    brand ?
    <Col xs={{span: 24}} sm={{span: 12}} md={{span: 12}} lg={{span: 8}} xl={{span: 6}} className="item-listing">
      <Card
        hoverable
        style={{borderColor: '#f4f4f4', borderTop: '0'}}
        cover={<div style={{height: '128px', position: 'relative', overflow: 'hidden'}}><img alt="example" src={campaign.image} style={{position: 'absolute', left: '0', right: '0', top: '0', bottom: '0', margin: 'auto', width: '100%'}}/> </div>}
      >
        <div>
          <h3 style={{ marginBottom: '35px', color: '#000', lineHeight: '1.23', fontSize: '13px', fontFamily: 'truenoextrabold' }}>{ brand.details.name }</h3>
          <h5 style={{ color: '#5b6572', letterSpacing: '0.5px', fontSize: '8px', fontFamily: 'truenosemibold', textTransform: 'uppercase' }}>{ campaign.name }</h5>
          <p className="m-0" style={{color: '#000000', fontSize: '12px', fontFamily: 'truenoextrabold'}}>
            <span style={{position: 'relative', zIndex: '2'}}>${ campaign.value } Value</span>
            <span style={{position: 'absolute', zIndex: '1', bottom: '22px', left: '25px', width: '53px', height: '11px', background: '#ffe9b7'}}></span>
          </p>
        </div>
    </Card></Col>  : ''
  );
}

export default CampaignListItem;
