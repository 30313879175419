import { firebase } from 'lib/Firebase';
import { SET_CATEGORIES } from 'reducers/entities/categories';

export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  categories
});

export const getCategories = () => {
  return (dispatch) => {
    return firebase.adminCategories().once('value').then((snapshot) => {
      dispatch(setCategories(Object.values(snapshot.val())));
    });
  };
}
